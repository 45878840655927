import React from "react";
import { Avatar } from "antd";
import { Loading, TextEditor } from "../../../components";
import { isEmptyObject } from "../../../utils";
import { ProjectActions } from "../../projects/projects-list-container";
import { ActiveCheckIcon } from "../../../assets/icons/ActiveCheckIcon";
import {UserPlaceholder} from "../../../assets/images";

interface Props {
  project: IProject;
}

const ProjectSummary = ({ project }: Props) => {
  return (
    <>
      {isEmptyObject(project) ? (
        <Loading />
      ) : (
        <>
          {/* <p className="prj-dtl-heading m-b m-t">{project.name}</p> */}
          <div className="summary-grid">
            <div className="ant-card">
              <div className="flex jcsb aic border-bottom p-15">
                <div className="flex aic gp">
                  <h3>Status</h3>
                  <p className="flex gp-10 aic active-check">
                    <ActiveCheckIcon /> {project.isActive ? "Active" : "Inactive"}
                  </p>
                  {<ProjectActions record={project} />}
                </div>
                <div className="flex aic gp">
                  <h3>Last Accessed</h3>
                  <p>{project.updatedAt}</p>
                </div>
              </div>
              <div className="p-20">
                <h3 className="m-b">Description</h3>
                <TextEditor text={project.description} isDisabled />
              </div>
            </div>
            <div className="collabarators">
              <h3>Collabarators</h3>
              <div className="collabarators-list p-20">
                {project.collabarators.map((collabarator, index) => (
                  <div className="flex aic gp m-b" key={`collabarator-${index}`}>
                    <Avatar src={UserPlaceholder} />
                    <div>
                      <h5>{collabarator.name}</h5>
                      <p className="block">abc@gmail.com</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ProjectSummary;
