import { Collapse } from "antd";
import React from "react";
import { PaginationLeftArrowIcon, PaginationRightArrowIcon } from "../../assets/icons";
import { TASK_COLLAPSIBLE_COMPONENT_HEADING } from "../../utils";

const { Panel } = Collapse;

interface Props {
  modelData: IModelDetail;
  selectedModel: string;
}

export const TaskDescription = ({ modelData, selectedModel }: Props) => {
  return (
    <div className="m-t task-info-collapse">
      <Collapse
        accordion
        defaultActiveKey={[TASK_COLLAPSIBLE_COMPONENT_HEADING.TASK_DESCRIPTION]}
        expandIconPosition="end"
        expandIcon={(e) =>
          e.isActive ? <PaginationLeftArrowIcon /> : <PaginationRightArrowIcon />
        }
      >
        <Panel
          header={TASK_COLLAPSIBLE_COMPONENT_HEADING.TASK_DESCRIPTION}
          key={TASK_COLLAPSIBLE_COMPONENT_HEADING.TASK_DESCRIPTION}
        >
          <h4>What is this model used for?</h4>
          <p className="m-b">{modelData.task.description}</p>
          <h4 className="m-t">Trained Datasets</h4>
          <ul>
            {modelData.models
              .find((model) => model.name === selectedModel)
              ?.trained_datasets.map((dataset, index) => (
                <li key={index}>{dataset}</li>
              ))}
          </ul>
          <h4 className="m-t">Example</h4>
          <>
            {modelData.example?.map((exam, index) => (
              <div className="task-desc-bg" key={index}>
                <h5></h5>
                <p className="m-10">{exam.curl}</p>
                <h5>Inputs</h5>
                <div>
                  {Object.keys(exam.inputs).map((key, i) => (
                    <div key={`input-${i}`}>
                      <p className="task-desc-bg-heading">{key}:</p>
                      <p>{exam.inputs[key]}</p>
                    </div>
                  ))}
                </div>
                <h5>Outputs</h5>
                <div>
                  {Object.keys(exam.outputs).map((key, i) => (
                    <div className="m-10" key={`output-${i}`}>
                      <p className="task-desc-bg-heading">{key}:</p>
                      <p>{exam.outputs[key]}</p>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </>
        </Panel>
      </Collapse>
    </div>
  );
};
