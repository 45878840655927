import React from "react";
import { useSelector } from "react-redux";
import { CreateFirstProjectContainer, PageWrapper, ProjectsListContainer } from "../../containers";
import { useProject } from "../../hooks";
import { RootState } from "../../store";

const Projects = () => {
  const { isProjectsLoading } = useProject();
  const { length: projectsLength } = useSelector((state: RootState) => state.project.list);

  return (
    <PageWrapper
      loading={isProjectsLoading}
      pageContainerChildren={
        projectsLength > 0 ? <ProjectsListContainer /> : <CreateFirstProjectContainer />
      }
    />
  );
};

export default Projects;
