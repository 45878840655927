import { Card } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { COPILOTS_NAME, IApplication } from "../../../utils";
import "../applications-container.scss";

export const ApplicationCard = ({ title, description, link, icon }: IApplication) => {
  const navigate = useNavigate();

  return (
    <Card
      className="cursor-pointer application-card"
      onClick={() => {
        navigate(link);
      }}
    >
      <div className="flex aic">{icon}</div>
      <div>
        <p className="application-card-title">{title}</p>
        <p>{description}</p>
      </div>
    </Card>
  );
};
