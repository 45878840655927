import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { modelDetailService } from "../api/model";
import { PageWrapper } from "../containers";
import { openNotificationWithIcon } from "../utils";
import { PHIRedactionContainer } from "../containers/clinical-data";
import { PHIRedactionAside } from "../containers/clinical-data";

const PHIRedaction = () => {

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [modelData, setModelData] = useState({} as IModelDetail);
  const [selectedModel, setSelectedModel] = useState("");

  useEffect(() => {
    const fetchModelsInfo = async () => {
      setIsLoading(true);
      try {
        const { data } = await modelDetailService.getPHIRedactionModelsInfo();
        setModelData(data);
        setSelectedModel(data.models[0].name);
      } catch (e: any) {
        openNotificationWithIcon("", e.response.data.message, "error");
        navigate(-1);
      } finally {
        setIsLoading(false);
      }
    };
    fetchModelsInfo();
  }, [navigate]);

  return (
    <PageWrapper
      pageLeftAsideChildren={
        <PHIRedactionAside {...{ selectedModel, setSelectedModel, modelData }} />
      }
      pageContainerChildren={<PHIRedactionContainer />}
    />
  );
};

export default PHIRedaction;
