import { apiClient } from "./apiClient";
import axios from "axios";

export interface GetUploadSignedUrl {
    data: {
      signedUrl: string;
    };
    status: string;
  }

const getUploadSignedUrl = async (projectId: number, path?: string) => {
  const res = await apiClient.get(`signedUrl/upload/${projectId}?path=${path}`);
  return res?.data;
};

const uploadFiles = async (url: string, file: any) => {
  const form = new FormData();
  form.append("file", file);
  const res = await axios({
    method: "put",
    url,
    data: form,
    headers: { "Content-Type": `multipart/form-data;`, "x-ms-blob-type": "BlockBlob" },
  });
  return res;
};

const getFiles = async(documentUuid: string) => {
  const res = await apiClient.get(`signedUrl/read/${documentUuid}`)
  return res.data;
}

const startProcessing = async (projectId: number, path?: string) => {
  const res = await apiClient.post(`processFile/start`, {
    containerId: projectId,
    filepaths: [path],
  });
  return res;
};

const getSplitSummaryDetails = async(documentUUID: string) => {
  const res = await apiClient.get(`processFile/split-summary/${documentUUID}`);
  return res;
}

const getProjectId = async() => {
  const res = await apiClient.get(`project/overview`)
  return res;
}

export const fileUploadServices = {
    getUploadSignedUrl,
    startProcessing,
    uploadFiles,
    getSplitSummaryDetails,
    getProjectId,
    getFiles
};
