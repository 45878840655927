import React, { useCallback, useState } from "react";
import { TASK_TABS } from "../../utils";
import { Button, message } from "antd";
import { FileUpload, PdfViewer } from "../../components";
import { TaskTabs } from "../../components/tasks/TaskAllTabs";
import { TaskErrorState } from "../../components/tasks/TaskErrorState";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import DocumentUpload from "../../components/document-upload/DocumentUpload";
import { MyData } from "../../pages/DocumentInsights";

const allowedTypes = [".txt", ".pdf", ".doc"];

interface Props {
  copilotInfo?: MyData
}

export const DocumentInsightsContainer = (props: Props) => {
  return (
    <>
      <div className="m-b">
        <>
          <DocumentUpload {...props}/>
        </>
      </div>
    </>
  );
};
