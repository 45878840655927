import { PAGE_URL } from "./routes";

export const CLINICAL_RESEARCH_TASKS : ITask[] = [
  {
    title: "Clinical Protocol Patient Summary",
    description:
      "Summarizes the Clinical trial protocol document into a layman's terms",
      link: `${PAGE_URL.CLINICAL_RESEARCH_TASKS}${PAGE_URL.CLINICAL_PROTOCOL_SUMMARY}`,
  },
  {
    title: "Clinical Protocol IE Criteria Simplification",
    description: "Takes Inclusion and Exclusion criteria and simplifies the IC-EC into a patient-friendly summary",
    link: `${PAGE_URL.CLINICAL_RESEARCH_TASKS}${PAGE_URL.CLINICAL_PROTOCOL_ICEC_SUMMARY}`,
  },
  {
    title: "Clinical Note Classification",
    description: "Accepts clinical text and returns back classification into one of five (5) broad categories: disease characteristics, mediations, medical history, not enough information and patient characteristics.",
    link: `${PAGE_URL.CLINICAL_RESEARCH_TASKS}${PAGE_URL.CLNICAL_NOTE_CLASSIFICATION}`,
  },
];
