// export const REACT_APP_GENESIS_API_BASE_URL: string = process.env.REACT_APP_GENESIS_API_BASE_URL as string;
// export const REACT_APP_OPENAI_MODEL_BASE_URL: string = process.env.REACT_APP_OPENAI_MODEL_BASE_URL as string;
// export const REACT_APP_RUN_IN_STRICT_MODE: string = process.env.REACT_APP_RUN_IN_STRICT_MODE as string;
// export const REACT_APP_AWS_ACCESS_KEY_ID: string = process.env.REACT_APP_AWS_ACCESS_KEY_ID as string;
// export const REACT_APP_AWS_SECRET_ACCESS_KEY: string = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY as string;
// export const REACT_APP_S3_BUCKET_NAME: string = process.env.REACT_APP_S3_BUCKET_NAME as string;
// export const REACT_APP_AWS_REGION: string = process.env.REACT_APP_AWS_REGION as string;

export const REACT_APP_GENESIS_API_BASE_URL: string = "https://genesis.demo-be.autonomize.ai" as string;
export const REACT_APP_OPENAI_MODEL_BASE_URL: string = "https://genesis-qna.demo.autonomize.ai" as string;
export const REACT_APP_RUN_IN_STRICT_MODE: string = "false" as string;
export const REACT_APP_AWS_ACCESS_KEY_ID: string = "<redacted>" as string;
export const REACT_APP_AWS_SECRET_ACCESS_KEY: string = "<redacted>" as string;
export const REACT_APP_S3_BUCKET_NAME: string = "autonomize-processing-sprint" as string;
export const REACT_APP_AWS_REGION: string = "us-east-1" as string;
