export const splitData = {
  doc_page_count: 10,
  page_info: [
    {
      category: "category0",
      confidence_score: 0.99,
      section: "Counter Offer",
      page_start: 1,
      page_end: 1,
      meta_info: {
        buyer_name: "Sherry Li Xiao",
        seller_name: "",
        property_address: {
          address: "",
          city: "",
          state: "",
          zip: "",
        },
      },
    },
    {
      category: "category1",
      confidence_score: 0.99,
      section: "Buyer Advisory",
      page_start: 2,
      page_end: 14,
      meta_info: {
        buyer_name: "Joe Xavier",
        seller_name: "Coldwell Banker Realty",
        property_address: {
          address: "",
          city: "",
          state: "",
          zip: "",
        },
      },
    },
    {
      category: "category2",
      confidence_score: 0.99,
      section: "Disclosure and Election",
      page_start: 15,
      page_end: 15,
      meta_info: {
        buyer_name: "",
        seller_name: "",
        property_address: {
          address: "Test",
          city: "Bangalore",
          state: "Karnataka",
          zip: "560038",
        },
      },
    },
    {
      category: "category2",
      confidence_score: 0.99,
      section: "Buyer Attachment",
      page_start: 16,
      page_end: 16,
      meta_info: {
        buyer_name: "",
        seller_name: "",
        property_address: {
          address: "",
          city: "",
          state: "",
          zip: "",
        },
      },
    },
    {
      category: "category2",
      confidence_score: 0.99,
      section: "Purchase Contract",
      page_start: 17,
      page_end: 26,
      meta_info: {
        buyer_name: "",
        seller_name: "",
        property_address: {
          address: "",
          city: "",
          state: "",
          zip: "",
        },
      },
    },
    {
      category: "category2",
      confidence_score: 0.99,
      section: "Solar Addendum",
      page_start: 27,
      page_end: 29,
      meta_info: {
        buyer_name: "",
        seller_name: "",
        property_address: {
          address: "",
          city: "",
          state: "",
          zip: "",
        },
      },
    },
    {
      category: "category2",
      confidence_score: 0.99,
      section: "Additional Clause Addendum",
      page_start: 30,
      page_end: 32,
      meta_info: {
        buyer_name: "",
        seller_name: "",
        property_address: {
          address: "",
          city: "Trivandrum",
          state: "",
          zip: "",
        },
      },
    },
    {
      category: "category2",
      confidence_score: 0.99,
      section: "Market Conditions Advisory",
      page_start: 33,
      page_end: 33,
      meta_info: {
        buyer_name: "",
        seller_name: "",
        property_address: {
          address: "",
          city: "",
          state: "",
          zip: "",
        },
      },
    },
    {
      category: "category2",
      confidence_score: 0.99,
      section: "Wire Scam Alert",
      page_start: 34,
      page_end: 34,
      meta_info: {
        buyer_name: "",
        seller_name: "",
        property_address: {
          address: "",
          city: "",
          state: "",
          zip: "",
        },
      },
    },
    {
      category: "category2",
      confidence_score: 0.99,
      section: "Disclosure Statement",
      page_start: 35,
      page_end: 37,
      meta_info: {
        buyer_name: "",
        seller_name: "",
        property_address: {
          address: "",
          city: "",
          state: "",
          zip: "",
        },
      },
    },
    {
      category: "category2",
      confidence_score: 0.99,
      section: "Other",
      page_start: 38,
      page_end: 43,
      meta_info: {
        buyer_name: "",
        seller_name: "",
        property_address: {
          address: "",
          city: "",
          state: "",
          zip: "",
        },
      },
    },
  ],
};

export const TABS = {
  SPLIT_SUMMARY: "Split Summary",
  ASK_QA: "Ask Auto",
};
