import React from "react";
import { Modal } from "antd";
import { AddEditProjectProps } from ".";
import { CloseOutlined } from "@ant-design/icons";
import { AddEditProjectForm } from "./AddEditProjectForm";

const AddEditProject = (props: AddEditProjectProps) => {
  const { visibility, isEdit, editObj, handleClose } = props;

  return (
    <Modal
      className="autonomizeModal addClient edit-project"
      centered
      width={587}
      title="Add Client"
      visible={visibility}
      footer={null}
      closeIcon={<CloseOutlined onClick={handleClose} />}
    >
      <div className="addClient-title">
        <h1>{isEdit ? "Edit Project" : "Add Project"}</h1>
      </div>
      <div className="addClient-content">
        <AddEditProjectForm editObj={editObj} isEdit={isEdit} onClose={handleClose} />
      </div>
    </Modal>
  );
};

export default AddEditProject;
