import React, { useEffect } from "react";
import { PageWrapper, DatasetsContainer } from "../containers";
import { useDatasets } from "../hooks/useDatasets";

const Datasets = () => {
  const { datasets, getDatasets, loading } = useDatasets();

  useEffect(() => {
    getDatasets();
  }, [getDatasets]);

  return (
    <PageWrapper
      loading={loading}
      pageContainerChildren={<DatasetsContainer datasets={datasets} />}
    />
  );
};

export default Datasets;
