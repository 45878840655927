import React, { useState, useRef, useEffect, useCallback } from "react";
import { Button, message, Collapse, Table, UploadProps, Tabs } from "antd";
import "antd/dist/antd.css";
import {
  UploadOutlined,
  DownloadOutlined,
  CloudUploadOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import "./document-upload.scss";
import { PaginationLeftArrowIcon, PaginationRightArrowIcon } from "../../assets/icons";
import { ColumnsType } from "antd/lib/table";
import { PDFDocument } from "pdf-lib";
import Dragger from "antd/lib/upload/Dragger";
import { fileUploadServices } from "../../api/fileUpload";
import { TABS } from "./constants";
import { splitData } from "./constants";
import { ChatContainer } from "./components/ChatContainer";
import { ISources } from "../../api";
import { PdfViewer } from "./components/PdfViewer";
import { MyData } from "../../pages/DocumentInsights";
import { DocumentInsightsView } from "./components/DocumentInsightsView";
import { Loading } from "../Loading";
import { ChatSource } from "./components/ChatSource";

const HEADINGS = {
  DOCUMENT_INSIGHTS: "Document Insights",
  DOCUMENT_SPLIT_SUMMARY: "Document Split Summary",
};
const { TabPane } = Tabs;

const acceptedFormats = [".pdf"];

interface DataType {
  key: number;
  section: string;
  page_range: string;
  category: string;
}

interface DocumentUploadProps {
  copilotInfo?: MyData;
}

const DocumentUpload: React.FC<DocumentUploadProps> = ({ copilotInfo }) => {
  const [internalFile, setInternalFile] = useState<File | null>(null);
  const [internalNumPages, setInternalNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [startPage, setStartPage] = useState<number>(0);
  const [endPage, setEndPage] = useState<number>(0);
  const [splitSummaryTableData, setSplitSummaryTableData] = useState<DataType[]>([]);
  const [splitSummaryData, setSplitSummaryData] = useState({});
  const [showDetails, setShowDetails] = useState(false);
  const [selectedTab, setSelectedTab] = useState(TABS.SPLIT_SUMMARY);
  const [hideChatHistory, setHideChatHistory] = useState(false);
  const [chatSource, setChatSource] = useState({} as ISources);
  const [isQuesAnsLoading, setIsQuesAnsLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);
  // const [hideChatHistoryPanel, setHideChatHistoryPanel] = useState(isPriorAuthProject ? true : false)
  const [loading, setLoading] = useState(false);
  const [projectId, setProjectId] = useState(0);
  const [documentUuid, setDocumentUuid] = useState("");
  const [fileData, setFileData] = useState<Blob | null>(null);

  const handleUpload = async (file: File | null) => {
    setShowDetails(true);
    try {
      setStartPage(0);
      setEndPage(0);
      setPageNumber(1);
      setLoading(true);
      if (projectId) {
        const { data } = await fileUploadServices.getUploadSignedUrl(projectId, file?.name);
        await fileUploadServices.uploadFiles(data.data.signedUrl, file);
        const documentProcessDetails = await fileUploadServices.startProcessing(
          projectId,
          file?.name
        );
        const splitSummaryDataResponse = await fileUploadServices.getSplitSummaryDetails(
          documentProcessDetails.data.data
        );
        const page_info = JSON.parse(splitSummaryDataResponse.data.data.splitSummary);
        setDocumentUuid(documentProcessDetails.data.data);
        setSplitSummaryData(page_info);
        const getData: DataType[] = page_info.map((item: any, index: any) => ({
          key: index,
          section: item?.section,
          page_range: `${item.page_start} - ${item.page_end}`,
          category: item?.category,
        }));
        setSplitSummaryTableData(getData);
      }
      setLoading(false);
    } catch {
      console.log("unable to upload");
      setLoading(false);
    }
  };

  const uploadProps: UploadProps = {
    name: "file",
    multiple: false,
    accept: acceptedFormats.join(","),
    beforeUpload: (file) => {
      const isImage = ["image/jpeg", "image/png", "image/jpg"].includes(file.type);
      if (isImage) message.error("Image upload not supported!");
      else {
        setInternalFile(file);
        handleUpload(file);
      }
      return isImage;
    },
    maxCount: 1,
  };

  const onDocumentLoadSuccess = useCallback(({ numPages }: { numPages: number }) => {
    setInternalNumPages(numPages);
  }, []);

  const getProjectId = async () => {
    try {
      const { data } = await fileUploadServices.getProjectId();
      setProjectId(data.data.data[0].id);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getProjectId();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setInternalNumPages(null);
    };

    if (containerRef.current) {
      handleResize();
      window.addEventListener("resize", handleResize);
    }

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [containerRef]);

  // useEffect(() => {
  //   if (showDetails) {
  //     const { page_range } = splitData;

  //     const getData: DataType[] = page_range.map((item, index) => ({
  //       key: index,
  //       section: item?.section,
  //       page_range: `${item.page_start} - ${item.page_end}`,
  //       // category: item?.category,
  //     }));
  //     setSplitSummaryTableData(getData);
  //   }
  // }, [showDetails]);

  const downloadSelectedPages = async (record: DataType) => {
    const [start, end] = record.page_range.split(" - ").map(Number);
    try {
      const pdfBytes = await fetch(URL.createObjectURL(internalFile as Blob)).then((res) =>
        res.arrayBuffer()
      );

      const pdfDoc = await PDFDocument.load(pdfBytes);

      const selectedPdfDoc = await PDFDocument.create();

      for (let i = start; i <= end; i++) {
        const [copiedPage] = await selectedPdfDoc.copyPages(pdfDoc, [i - 1]);
        selectedPdfDoc.addPage(copiedPage);
      }

      const selectedPdfBytes = await selectedPdfDoc.save();

      const blob = new Blob([selectedPdfBytes], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `SelectedPages_${start}-${end}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading pages:", error);
    }
  };

  const getColumns = (): ColumnsType<DataType> => [
    {
      title: "Category",
      dataIndex: "category",
      align: "center",
    },
    {
      title: "Section",
      dataIndex: "section",
      align: "center",
    },
    {
      title: "Page Range",
      dataIndex: "page_range",
      align: "center",
    },
    {
      title: "Action",
      dataIndex: "action",
      align: "center",
      render: (value: string, record: DataType) => (
        <DownloadOutlined onClick={() => downloadSelectedPages(record)} />
      ),
    },
  ];

  const onRowClick = (record: DataType) => {
    const [start, end] = record.page_range.split(" - ").map(Number);
    setStartPage(start);
    setEndPage(end);
    setPageNumber(start);
    setInternalNumPages(end);
  };

  const onTabChange = (key: string) => {
    setSelectedTab(key);
  };

  return (
    <div className="tabs m-b">
      <div className="project-heading">
        <Tabs className="tabs" onChange={onTabChange} activeKey={selectedTab}>
          <TabPane tab={TABS.SPLIT_SUMMARY} key={TABS.SPLIT_SUMMARY} />
          <TabPane tab={TABS.ASK_QA} key={TABS.ASK_QA} disabled={!showDetails} />
        </Tabs>{" "}
      </div>
      <div
        className="document-container"
        style={{ gridTemplateColumns: hideChatHistory ? "1fr 1fr 1fr" : "1fr 1fr" }}
      >
        {selectedTab === TABS.SPLIT_SUMMARY ? (
          <div className="view">
            <div>
              <Dragger {...uploadProps}>
                <div className="attachment">
                  <CloudUploadOutlined />
                  <p>
                    Drag & drop file here or <span>Browse File</span>
                  </p>
                  {acceptedFormats.length > 0 && (
                    <p className="italic">You can upload only {acceptedFormats.join(",")} files</p>
                  )}
                </div>
              </Dragger>

              {/* <div className="upload-name">
                {internalFile && (
                  <Button className="outline" icon={<UploadOutlined />} onClick={handleUpload}>
                    Upload
                  </Button>
                )}{" "}
              </div> */}
            </div>
            <Collapse
              accordion
              expandIconPosition="end"
              expandIcon={(e) =>
                e.isActive ? <PaginationLeftArrowIcon /> : <PaginationRightArrowIcon />
              }
              defaultActiveKey={HEADINGS.DOCUMENT_INSIGHTS}
            >
              <Collapse.Panel header={HEADINGS.DOCUMENT_INSIGHTS} key={HEADINGS.DOCUMENT_INSIGHTS}>
                {Object.keys(splitSummaryData).length !== 0 && (
                  <DocumentInsightsView splitSummary={splitSummaryData} />
                )}
              </Collapse.Panel>
            </Collapse>
            <Collapse
              accordion
              expandIconPosition="end"
              expandIcon={(e) =>
                e.isActive ? <PaginationLeftArrowIcon /> : <PaginationRightArrowIcon />
              }
              defaultActiveKey={HEADINGS.DOCUMENT_SPLIT_SUMMARY}
            >
              <Collapse.Panel
                header={HEADINGS.DOCUMENT_SPLIT_SUMMARY}
                key={HEADINGS.DOCUMENT_SPLIT_SUMMARY}
              >
                <Table
                  columns={getColumns()}
                  className="table-new"
                  dataSource={splitSummaryTableData}
                  pagination={false}
                  onRow={(record) => ({ onClick: () => onRowClick(record) })}
                />
              </Collapse.Panel>
            </Collapse>
          </div>
        ) : (
          <ChatContainer
            selectedProjectId={projectId}
            projectName={"Document Insights"}
            file={internalFile}
            documentUuid={documentUuid}
            {...{
              setChatSource,
              isQuesAnsLoading,
              setIsQuesAnsLoading,
              setHideChatHistory,
              expanded,
              setExpanded,
              hideChatHistory,
              chatSource,
            }}
          />
        )}
        {internalFile && showDetails && !loading && (
          <PdfViewer
            {...{
              internalFile,
              internalNumPages,
              containerRef,
              startPage,
              endPage,
              setPageNumber,
              onDocumentLoadSuccess,
              pageNumber,
            }}
          />
        )}
        {loading && <Loading />}
        {hideChatHistory && (
          <div className="source">
            <div className="flex gp aic jcsb m-b">
              <h4>Source</h4>
              <CloseOutlined
                onClick={() => {
                  setExpanded(false);
                  setHideChatHistory(false);
                }}
              />
            </div>
            <ChatSource {...{ chatSource, setHideChatHistory, expanded, setExpanded }} />
          </div>
        )}
      </div>
    </div>
  );
};

export default DocumentUpload;
