import React from "react";
import { IconsProps } from "./Icons";

export const TaskDetailsIcon = ({ className, fill, width, height, viewBox, onClick }: IconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox={viewBox ? viewBox : "0 0 16 16"}
      width={width ? width : "16"}
      height={height ? height : "16"}
      fill={fill ? fill : "none"}
      onClick={onClick}
    >
<path xmlns="http://www.w3.org/2000/svg" d="M15.2 0H0.8C0.587827 0 0.384344 0.0842854 0.234315 0.234315C0.0842854 0.384344 0 0.587827 0 0.8V15.2C0 15.4122 0.0842854 15.6157 0.234315 15.7657C0.384344 15.9157 0.587827 16 0.8 16H15.2C15.4122 16 15.6157 15.9157 15.7657 15.7657C15.9157 15.6157 16 15.4122 16 15.2V0.8C16 0.587827 15.9157 0.384344 15.7657 0.234315C15.6157 0.0842854 15.4122 0 15.2 0ZM9.6 14.4H1.6V8.8H9.6V14.4ZM9.6 7.2H1.6V1.6H9.6V7.2ZM14.4 14.4H11.2V1.6H14.4V14.4Z" fill="currentColor"/></svg>
  );
};
