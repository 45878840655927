import React, { useEffect, useMemo, useState } from "react";
import { ISources, uploadFileFolderService } from "../../../api";
import { Loading } from "../../../components";
import { useDispatch } from "react-redux";
import { openModal, setCurrentSource } from "../../../store";
import { MODAL_TYPE, STATUS_CODE, openNotificationWithIcon } from "../../../utils";
import "./chat-ques-ans.scss";
import { useAuthorization } from "../../../hooks";

interface Props {
  chatSource: ISources;
  setHideChatHistory: React.Dispatch<React.SetStateAction<boolean>>;
  expanded: boolean;
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ChatSource = ({ chatSource }: Props) => {
  const dispatch = useDispatch();
  const { documentUUID, pages } = chatSource;
  const [currentPage, setCurrentPage] = useState(pages[0]);
  const [currentSourceContent, setCurrentSourceContent] = useState("");
  const [isSourceContentLaoding, setIsSourceContentLoading] = useState(false);
  const [isApiCall, setIsApiCall] = useState(true);
  const { setIsAuthorized } = useAuthorization();

  useEffect(() => {
    setCurrentPage(pages[0]);
    setIsApiCall(true);
  }, [pages]);

  const highlightedSourceContent = useMemo(() => {
    let content = currentSourceContent;
    const terms = currentPage.offsets.map((offset) =>
      currentSourceContent.substring(offset.begin, offset.end)
    );
    terms.forEach((term) => {
      const html = `<span class="bg-yellow">${term}</span>`;
      content = content.replace(term, html);
    });
    content = content.replaceAll(`\n`, `<br/>`);
    return content;
  }, [currentPage.offsets, currentSourceContent]);

  useEffect(() => {
    const fetchSourceContent = async () => {
      setIsSourceContentLoading(true);
      try {
        const { data } = await uploadFileFolderService.getFileOCR({
          fileUuid: documentUUID,
          pageNum: currentPage.pageNo,
        });
        setCurrentSourceContent(data.data.ocr_text || "No OCR text found");
      } catch (err: any) {
        if (err.message) {
          setIsAuthorized(false);
        } else {
          openNotificationWithIcon("", err.response.data.message, "error");
        }
      } finally {
        setIsSourceContentLoading(false);
      }
    };
    if (isApiCall) {
      fetchSourceContent();
      setIsApiCall(false);
    }
  }, [currentPage.pageNo, documentUUID, isApiCall, setIsAuthorized]);

  return (
    <>
      <div className="m-b">
        <h5>Results in: </h5>
        <ul className="source-list grid grid-cols-3">
          {pages.map((page, index) => (
            <li
              key={index}
              className={`cursor-pointer ${currentPage.pageNo === page.pageNo ? "active" : ""}`}
              onClick={() => {
                setCurrentPage(page);
                setIsApiCall(true);
              }}
            >
              {`Page ${page.pageNo}`}
            </li>
          ))}
        </ul>
      </div>
      <div className="source-content">
        <h5>Source Content</h5>
        {isSourceContentLaoding ? (
          <Loading />
        ) : (
          <div dangerouslySetInnerHTML={{ __html: highlightedSourceContent }}></div>
        )}
        <a
          onClick={() => {
            dispatch(setCurrentSource(chatSource));
            dispatch(openModal(MODAL_TYPE.VIEW_FULL_SOURCE));
          }}
        >
          View Original Source
        </a>
      </div>
    </>
  );
};
