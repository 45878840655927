import React, { useEffect, useRef } from "react";
import { MenuIcon, ThumbsDown, ThumbsUp } from "../../assets/icons";
import { Button, Input } from "antd";
import { Loading } from "../Loading";
import { SendOutlined } from "@ant-design/icons";
import { autonomizeLogo } from "../../assets/images";
const { Search } = Input;

interface Props {
  classname?: string;
  handleSearchSubmit: (e: string) => Promise<void>;
  quesAns: IQuesAns[];
  inputValue: string;
  isLoading: boolean;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
}

export const AskAQuestion = ({
  handleSearchSubmit,
  quesAns,
  inputValue,
  setInputValue,
  isLoading,
  classname,
}: Props) => {
  const questionAnswerListRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (questionAnswerListRef.current) {
      questionAnswerListRef.current.scrollTo({
        top: questionAnswerListRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [quesAns]);

  return (
    <>
      <h1 className="m-b">Results</h1>
      <div
        className={`ask-question ask-question-document ${
          quesAns.length === 0 ? "ask-question-help-text" : ""
        } ${classname ? classname : ""}`}
      >
        {quesAns.length === 0 && <div>Please enter text to get response from the model.</div>}
        <div className="ask-question-left-conatiner">
          <div className="ask-question-scroll-holder" ref={questionAnswerListRef}>
            {quesAns.map((item, index) => (
              <div className="ask-question-result" key={index}>
                <div className="ask-question-result-ques">
                  <MenuIcon className="question-icon" />
                  <h4 className="mb-0">{item.query}</h4>
                </div>
                <div className="ask-question-result-answer">
                  {/* <div className="flex aic gp"> */}
                  <img className="ask-question-result-answer-img" src={autonomizeLogo} />
                  <div dangerouslySetInnerHTML={{ __html: item.answer }}>
                    {/* <p>{item.answer}</p> */}
                  </div>
                  <div className="flex gp aic">
                    <div className="cursor-pointer flex">
                      <ThumbsUp />
                    </div>
                    <div className="cursor-pointer flex">
                      <ThumbsDown />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="ask-question-search-bar">
            <Search
              className="record-search m-b"
              placeholder="Enter your question"
              enterButton={isLoading ? <Loading tip="" /> : <Button icon={<SendOutlined />} />}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onSearch={(e) => handleSearchSubmit(e)}
              disabled={isLoading}
            />
            <p>
              This is an interactive way to ask any question regarding the datasets in question.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
