import axios from "axios";
import { apiClient } from "./apiClient";
import { IRepsonseWrapper } from ".";

export interface GetUploadSignedUrl {
  data: {
    signedUrl: string;
  };
  status: string;
}
export interface FileFolder {
  createdAt: string;
  updatedAt: string;
  isFolder: boolean;
  name: string;
  status: string;
}

export interface IGetFileOCRRequest {
  pageNum: number;
  fileUuid: string;
}

export interface IGetFileOCRResponse {
  data: {
    fileId: string;
    index_filter: string;
    ocr_text: string;
    pageNum: string;
  }
}

export interface IGetFileOCRResponseWrapper {
  data: IGetFileOCRResponse;
  status: string;
}

// export interface IGetFilesResponse {
//   message: string;
//   data: { files: FileFolder[] };
// }

export interface GetFilesAndFolderResponse {
  data: FileFolder[];
  status: string;
  pagination: {
    // continuationToken: string;
    limit: number;
    offset: number;
    total: number;
  };
}

export interface IGetSignedURLForFullSourceResponse {
  data: {
    signedUrl: string;
  };
  status: string;
}

export interface IGetMetadataResponse {
  data: { content: string };
  status: string;
}

export interface IUpdateFileFolderMetadataResponse {
  data: { message: string };
  status: string;
}

export interface IDeleteFileFolderResponse {
  data: {
    message: string;
  };
  status: string;
}

export interface IStartProcessingPreUploadedFilesResponse {
  data: {
    message: string;
  };
  status: string;
}

export interface IGetUnprocessedContainersResponse {
  data: string[];
  status: number;
}

export interface IGetFileStatusResponse {
  code: number;
  createdAt: string;
  createdBy: string;
  id: number;
  isDeleted: boolean;
  name: string;
  updatedAt: string;
  updatedBy: string;
}

// const getFiles = async (projectId: number, path?: string) => {
//   const url = path ? `/storage/files/${projectId}?path=${path}` : `/storage/files/${projectId}`;
//   const res = await apiClient.get<IGetFilesResponse>(url);
//   return res;
// };

const getUploadSignedUrl = async (projectId: number, path: string) => {
  const res = await apiClient.get<GetUploadSignedUrl>(
    `/storage/signed-url/upload/${projectId}?path=${path}`
  );
  return res;
};

const uploadFiles = async (url: string, file: any) => {
  const form = new FormData();
  form.append("file", file);
  const res = await axios({
    method: "put",
    url,
    data: form,
    headers: { "Content-Type": `multipart/form-data;`, "x-ms-blob-type": "BlockBlob" },
  });
  return res;
};
const startFileProcessing = async (containerId: number, filepath: string) => {
  const res = apiClient.post("/process-file/start", { containerId, filepaths: [filepath] });
  return res;
};

const getFilesAndFolder = async (
  projectId: number,
  path = "",
  limit: number,
  offset: number,
  status: number | string
) => {
  const res = apiClient.get<GetFilesAndFolderResponse>(
    `/file/container/${projectId}?path=${path}&offset=${offset}&limit=${limit}&statusId=${status}`
  );
  return res;
};

const createFolder = async (projectId: number, folderName: string, metadata: string) => {
  const res = apiClient.post(`/storage/folder/${projectId}`, { folderName, metadata });
  return res;
};

const getSignedURLForFullSource = async (fileUuid: string) => {
  const res = apiClient.get<IGetSignedURLForFullSourceResponse>(
    `/signedUrl/read/${fileUuid}`
  );
  return res;
};

const getSignedUrlByFilepath = async (projectId: number, path: string) => {
  const res = apiClient.post<IGetSignedURLForFullSourceResponse>(
    `/storage/signed-url/read/project/${projectId}?path=${path}`
  );
  return res;
};

const getFileFolderMetadata = async (projectId: number, path: string) => {
  const res = await apiClient.get<IGetMetadataResponse>(
    `/storage/meta-data/${projectId}?path=${path}`
  );
  return res;
};

const updateFileFolderMetadata = async (projectId: number, path: string, content: string) => {
  const res = await apiClient.put<IUpdateFileFolderMetadataResponse>(
    `/storage/meta-data/${projectId}`,
    {
      path,
      content,
    }
  );
  return res;
};

const deleteFileFolder = async (projectId: number, path: string) => {
  const res = await apiClient.delete<IDeleteFileFolderResponse>(
    `/storage/blob/${projectId}?path=${path}`
  );
  return res;
};

const getFileOCR = async (reqObj: IGetFileOCRRequest) => {
  const res = await apiClient.post<IGetFileOCRResponseWrapper>(`/file/get-ocr`, reqObj);
  return res.data;
};

const startProcessingPreUploadedFiles = async (containerId: number) => {
  const res = await apiClient.get<IStartProcessingPreUploadedFilesResponse>(
    `/process-file/preuploaded-files/add/${containerId}`
  );
  return res;
};

const getUnprocessedContainers = async () => {
  const res = await apiClient.get<IGetUnprocessedContainersResponse>(
    "/storage/container/non-processed"
  );
  return res;
};

const getFileStatus = async () => {
  const res = await apiClient.get<IRepsonseWrapper<IGetFileStatusResponse[]>>(
    "/process-file/status"
  );
  return res;
};

export const uploadFileFolderService = {
  getUploadSignedUrl,
  uploadFiles,
  // getFiles,
  getFilesAndFolder,
  startFileProcessing,
  createFolder,
  getSignedURLForFullSource,
  getSignedUrlByFilepath,
  getFileFolderMetadata,
  updateFileFolderMetadata,
  deleteFileFolder,
  getFileOCR,
  startProcessingPreUploadedFiles,
  getUnprocessedContainers,
  getFileStatus,
};
