import { Button, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { ViewFullSourceProps } from ".";
import { CloseOutlined } from "@ant-design/icons";
import { uploadFileFolderService } from "../../../api";
import { STATUS_CODE, downloadPDF, openNotificationWithIcon } from "../../../utils";
import {
  DocViewer,
  Loading,
  NotAccessibleFallback,
  PdfViewer,
  TextViewer,
} from "../../../components";

export const ViewCompleteSource = ({ handleClose, source, visibility }: ViewFullSourceProps) => {
  const { documentUUID, pages } = source;
  const [fileUrl, setFileUrl] = useState("");
  const [isTextFile, setIsTextFile] = useState(false);
  const [isDocFile, setIsDocFile] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(true);

  useEffect(() => {
    const getSignedurl = async () => {
      setIsLoading(true);
      try {
        const { data } = await uploadFileFolderService.getSignedURLForFullSource(documentUUID);
        const filepath = data.data.signedUrl.split("?")[0];
        setIsTextFile(filepath.includes(".txt"));
        setIsDocFile(filepath.includes(".doc" || ".docx"));
        setFileUrl(data.data.signedUrl);
      } catch (err: any) {
        if (err.response.status === STATUS_CODE.FORBIDDEN) {
          setIsAuthorized(false);
        } else {
          openNotificationWithIcon("", err.response.data.message, "error");
          handleClose();
        }
      } finally {
        setIsLoading(false);
      }
    };
    getSignedurl();
  }, [documentUUID, handleClose]);

  const handleFileDownload = async () => {
    await downloadPDF(fileUrl, `source_${new Date().getTime()}.${isTextFile ? "txt" : "pdf"}`);
  };

  return (
    <Modal
      className="autonomizeModal"
      centered
      width={1000}
      visible={visibility}
      footer={null}
      title={"Source"}
      closeIcon={<CloseOutlined onClick={handleClose} />}
    >
      <div className="addClient-title no-bd-bt" style={{display: 'flex'}}>
        <h1 className="mb-0">Source</h1>
        {/* <CloseOutlined onClick={handleClose} style={{color: '#fff', marginLeft: 'auto'}}/> */}
      </div>
      <>
        {isLoading ? (
          <Loading />
        ) : !isAuthorized ? (
          <NotAccessibleFallback />
        ) : (
          <div className="flex">
            <div className="addClient-content w-75 no-pd-tp">
              <div>
                {isTextFile ? (
                  <TextViewer fileUrl={fileUrl} />
                ) : isDocFile ? (
                  <DocViewer fileUrl={fileUrl} />
                ) : (
                  <PdfViewer
                    pdf={fileUrl}
                    displayPagination
                    setCurrPage={setCurrentPage}
                    currPage={currentPage}
                  />
                )}
              </div>
            </div>
            <div className="addClient-content-aside flex flex-column">
              <div className="m-b">
                <h4>Results in</h4>
                <ul className="source-list">
                  {pages.map((page, index) => (
                    <li
                      className={`cursor-pointer ${page.pageNo === currentPage ? "active" : ""}`}
                      key={index}
                      onClick={() => setCurrentPage(page.pageNo)}
                    >{`Page Number ${page.pageNo}`}</li>
                  ))}
                </ul>
              </div>
              <div className="mt-auto">
                <Button className="fill" onClick={handleFileDownload} disabled={fileUrl === ""}>
                  Download
                </Button>
              </div>
            </div>
          </div>
        )}
      </>
    </Modal>
  );
};
