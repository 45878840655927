import axios, { AxiosResponse } from 'axios';

const CLINICAL_ENTITY_EXTRACTION_TASK = `https://5ko4fa4z6twicnftxvqjoj6dvu0ezbgj.lambda-url.us-east-1.on.aws`;
const CLINICAL_ENTITY_NORMALIZATION_TASK = `https://hoixus3a6ec5zkssvn2x3oaaia0ambpe.lambda-url.us-east-1.on.aws`;//`https://hoixus3a6ec5zkssvn2x3oaaia0ambpe.lambda-url.us-east-1.on.aws`;
const CLINICAL_NOTE_CLASSIFICATION_TASK = `https://ucbca57m6qe74dmvjkhurkzaoe0bhclf.lambda-url.us-east-1.on.aws`;
const CLINICAL_PROTOCOL_SUMMARY_TASK = `https://k32al3g6ij635iencybch3ge3q0mzqvx.lambda-url.us-east-1.on.aws`;
const CLINICAL_PROTOCOL_ICEC_SUMMARY_TASK = `https://pxen6qnl5ndqsgmhd4uo5xtsb40aifmj.lambda-url.us-east-1.on.aws`;
const CLINICAL_PHI_REDACTION_TASK = `https://boscc5ii5ooszwrt7yxq53f3tm0gqmjz.lambda-url.us-east-1.on.aws`;
const LABORATORY_RESULT_TASK = `https://b4e4nyjiqpkzqv3j5uhnbqrj6a0hoixc.lambda-url.us-east-1.on.aws`;
const CMS_2728_FORM_TASK = `https://4qevdaibc5rezww4xhjgbgljpu0xcxjk.lambda-url.us-east-1.on.aws`;


const defaultHeaders = {
  'Content-Type': 'text/plain',
  Accept: 'application/json',
};

class TasksApi {
  
  getResponse = async (name: string, input: string): Promise<AxiosResponse> => {
    const headers = { ...defaultHeaders };
    const payload = {
      input
    };
    let taskEndpoint = CLINICAL_PROTOCOL_SUMMARY_TASK;
    if (name == 'NOTE_CLASSIFIER') {
      taskEndpoint = CLINICAL_NOTE_CLASSIFICATION_TASK;
    }
    return axios.post(taskEndpoint, payload, { headers });
  };

  classifyClinicalNote = async (input: string): Promise<AxiosResponse> => {
    const headers = { ...defaultHeaders };
    const payload = {
      input
    };
    return axios.post(CLINICAL_NOTE_CLASSIFICATION_TASK, payload, { headers });
  };

  entityExtraction = async (input: any): Promise<AxiosResponse> => {
    const headers = { ...defaultHeaders };
    const payload = {
      input
    };
    return axios.post(CLINICAL_ENTITY_EXTRACTION_TASK, payload, { headers });
  };

  entityNormalization = async (input: any): Promise<AxiosResponse> => {
    const headers = { ...defaultHeaders };
    const payload = {
      input
    };
    return axios.post(CLINICAL_ENTITY_NORMALIZATION_TASK, payload, { headers });
  };

  protocolSummarizer = async (input: string): Promise<AxiosResponse> => {
    const headers = { ...defaultHeaders };
    const payload = {
      input
    };
    return axios.post(CLINICAL_PROTOCOL_SUMMARY_TASK, payload, { headers });
  };

  protocolICECSummarizer = async (input: string): Promise<AxiosResponse> => {
    const headers = { ...defaultHeaders };
    const payload = {
      input
    };
    return axios.post(CLINICAL_PROTOCOL_ICEC_SUMMARY_TASK, payload, { headers });
  };

  phiRedaction = async (input: any): Promise<AxiosResponse> => {
    const headers = { ...defaultHeaders };
    const payload = {
      input
    };
    return axios.post(CLINICAL_PHI_REDACTION_TASK, payload, { headers });
  };
  
  laboratoryResult = async (input: any): Promise<AxiosResponse> => {
    const headers = { ...defaultHeaders };
    const payload = {
      input
    };
    return axios.post(LABORATORY_RESULT_TASK, payload, { headers }); //need to change here
  };

  cms2728Form = async (input: any): Promise<AxiosResponse> => {
    const headers = { ...defaultHeaders };
    const payload = {
      input
    };
    return axios.post(CMS_2728_FORM_TASK, payload, { headers });
  };

}

export default TasksApi;
