import React from "react";

interface Props {
  children?: JSX.Element;
  style?: React.CSSProperties;
}

export const PageAsideLeftWrapper = ({ children, style }: Props) => {
  return <div className="main-aside" style={style}>{children}</div>;
};
