import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { modelDetailService } from "../api/model";
import { openNotificationWithIcon } from "../utils";
import {
  HemophiliaQuestionAnsweringAside,
  HemophiliaQuestionAnsweringContainer,
  PageWrapper,
} from "../containers";

const HemophiliaQuestionAnswering = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [modelData, setModelData] = useState({} as IModelDetail);
  const [selectedModel, setSelectedModel] = useState("");

  useEffect(() => {
    const fetchHemophiliaQAModelsInfo = async () => {
      setIsLoading(true);
      try {
        const { data } = await modelDetailService.getHemophiliaQAModelsInfo();
        setModelData(data.data);
        setSelectedModel(data.data.models[0].name);
      } catch (e: any) {
        openNotificationWithIcon("", e.response.data.message, "error");
        navigate(-1);
      } finally {
        setIsLoading(false);
      }
    };
    fetchHemophiliaQAModelsInfo();
  }, [navigate]);
  return (
    <PageWrapper
      loading={isLoading}
      pageLeftAsideChildren={
        <HemophiliaQuestionAnsweringAside {...{ selectedModel, setSelectedModel, modelData }} />
      }
      pageContainerChildren={
        <HemophiliaQuestionAnsweringContainer {...{ modelData, selectedModel }} />
      }
    />
  );
};

export default HemophiliaQuestionAnswering;
