import { PAGE_URL } from "./routes";

export const SEACRH_AND_DATA_MINING_TASKS: ITask[] = [
  {
    title: "Multi-document search",
    description: "Do multi-document keyword search on PDFs, Tabular data, Txt and images",
    link: "",
  },
  {
    title: "Document Question Answering ",
    description: "Do contextual question answering on private data sources, documents",
    link: `${PAGE_URL.SEARCH_AND_DATA_MINING_TASKS}${PAGE_URL.DOCUMENT_QUESTION_ANSWERING}`,
  },
  {
    title: "Custom Classification",
    description: "Fine-tune and classify custom text at token or sentence level",
    link: "",
  },
  {
    title: "Deep Neural Search",
    description:
      "Perform deep learning powered search understanding user intent on data repositories",
    link: "",
  },
];
