import React from "react";
import { PageWrapper } from "../containers";
import ProjectDetailsContainer from "../containers/projectDetails/ProjectDetailsContainer";

const ProjectDetail = () => {
  return <PageWrapper       
      containerClassName={"no-padding"}
      pageContainerChildren={<ProjectDetailsContainer />} 
  />;
};

export default ProjectDetail;
