import React, { useState } from "react";
import { TASK_TABS } from "../../../utils";
import { TaskInputTabs } from "../../../components/task-input/TaskInputTabs";

export const HemophiliaQuestionAnsweringInput = () => {
  const [selectedTab, setSelectedTab] = useState(TASK_TABS.RUNNING);

  const onTabChange = (key: string) => {
    setSelectedTab(key);
  };
  return (
    <>
      <div className="m-b">
        <TaskInputTabs {...{ onTabChange, selectedTab }} />
      </div>
    </>
  );
};
