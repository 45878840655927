import { useCallback, useState } from "react";
import { datasetsService } from "../api";

export const useDatasets = () => {
  const [loading, setLoading] = useState(false);
  const [datasets, setDatasets] = useState<IDataset[]>([]);

  const getDatasets = useCallback(async () => {
    setLoading(true);
    const { data } = await datasetsService.getAll();
    setDatasets(data);
    setLoading(false);
  }, []);

  return { getDatasets, loading, datasets };
};
