import React, { useState } from "react";
import { TaskInput } from "../../components";
import { isEmptyObject } from "../../utils";
import { ClinicalNoteClassificationOutput } from "./clinical-data-container";

interface Props {
  modelData: IModelDetail;
  selectedModel: string;
}

export const ClinicalNoteClassificationContainer = ({ modelData, selectedModel }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [query, setQuery] = useState("");

  return !isEmptyObject(modelData) ? (
    <div>
      <TaskInput
        {...{
          isLoading,
          setIsLoading,
          setQuery,
          query,
        }}
      />
      <ClinicalNoteClassificationOutput {...{ isLoading, setIsLoading, query, modelData, selectedModel }} />
    </div>
  ) : (
    <></>
  );
};
