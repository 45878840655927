import { isEmptyObject } from "./objectOperations";

export const filteredArray = (
  array: Array<Record<string, unknown>>,
  valueToRemove: unknown,
  filterByKey: string
) => array.filter((item) => item[filterByKey] !== valueToRemove);

export const getExistingSearchParams = (searchParams: URLSearchParams) => {
  const params = [];
  for (const entry of searchParams.entries()) {
    params.push(entry);
  }
  const res: { [key: string]: string } = {};
  params.map(([key, value]) => {
    res[key] = value;
  });
  return res;
};

export const hexToRGBColor = (hex: string, alpha = 1) => {
  const match = hex.match(/\w\w/g);
  if (match && match.length) {
    const [r, g, b] = match.map((x) => parseInt(x, 16));
    return `rgba(${r},${g},${b},${alpha})`;
  }
  return `rgba(255,255,255,${alpha})`;
};

export const roundToNearestNumber = (value: number, roundToNumber: number) =>
  Math.ceil(value / roundToNumber) * roundToNumber;

export const mergeQueryStrings = (qsArray: string[]): string => {
  if (!qsArray.length) return "";
  return qsArray.join("&");
};

export const getQueryStringFromPaginationObj = (obj: IPagination): string => {
  let qs = "";
  if (isEmptyObject(obj)) return qs;
  const qsArray = Object.entries(obj).map(([key, value]) => `${key}=${value}`);
  qs = mergeQueryStrings(qsArray);
  return qs;
};

export const getOffsetFromPageAndPageSize = (page: number, pageSize: number) =>
  (page - 1) * pageSize;

export const getThresholdConfidenceScore = (score: string[]) => {
    const threshold = score && score.length > 0 ? Number(score[0]) : 80;
    return threshold;
};
