import React from "react";
import { Button, Table } from "antd";
import { store } from "../../../App";
import { openModal, setUserForApproval } from "../../../store";
import { MODAL_TYPE } from "../../../utils";
import { ColumnsType } from "antd/es/table";
import { IUser } from "../../../interfaces/user";

interface UserListDataType extends IUser {
  key: React.Key;
}

const getColumns = (): ColumnsType<UserListDataType> => [
  {
    title: "First Name",
    dataIndex: "firstName",
  },
  {
    title: "Last Name",
    dataIndex: "lastName",
  },
  {
    title: "Email",
    dataIndex: "email",
  },
  {
    title: "Approve",
    dataIndex: "approve",
    render: (value, record) => (
      <Button
        className="outline"
        onClick={() => {
          store.dispatch(openModal(MODAL_TYPE.APPROVE_CONFIRMATION));
          store.dispatch(setUserForApproval(record));
        }}
      >
        Approve
      </Button>
    ),
  },
];

interface Props {
  isLoading: boolean;
  users: IUser[];
}

export const ToBeApprovedUsers = (props: Props) => {
  const { isLoading, users } = props;

  const usersData: UserListDataType[] = users.map((user) => ({
    key: `not-approved-user-${user.id}`,
    ...user,
  }));

  return (
    <>
      <Table
        className="basic-detail-content m-t user-table-mh"
        columns={getColumns()}
        dataSource={usersData}
        loading={isLoading}
        pagination={false}
      />
    </>
  );
};
