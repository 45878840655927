import React, { useState } from "react";
import { ChatQuesAns } from "./ChatQuesAns";
import { useDispatch } from "react-redux";
import { ISources } from "../../../api";

interface Props {
  setChatSource: React.Dispatch<React.SetStateAction<ISources>>;
  isQuesAnsLoading: boolean;
  setIsQuesAnsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setHideChatHistory: React.Dispatch<React.SetStateAction<boolean>>;
  expanded: boolean;
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  hideChatHistory: boolean;
  selectedProjectId: number;
  projectName: string;
  file: File | null;
  documentUuid: string;
  chatSource: ISources;
}

export const ChatContainer = (props: Props) => {
  const {
    file,
    setChatSource,
    isQuesAnsLoading,
    setIsQuesAnsLoading,
    selectedProjectId,
    documentUuid,
    setExpanded,
    setHideChatHistory,
  } = props;

  const [selectedFile, setSelectedFile] = useState("All");
  const [showHelp, setShowHelp] = useState(false);
  //   const dispatch = useDispatch();

  return (
    <div>
      <div className="left-container">
        <div style={{ display: "flex" }}>
          <h2
            style={{
              color: "#1e293b",
              fontFamily: '"Font-Semibold", Arial, Sans-Serif',
              fontSize: "18px",
              marginLeft: "1em",
            }}
          >
            {file?.name}
          </h2>
          {/* {hideChatHistoryPanel && (
            <div
              className={`back-icon`}
              onClick={() => {
                setHideChatHistoryPanel(false);
              }}
              style={{ marginTop: "auto", marginBottom: "auto", marginLeft: "auto" }}
            >
              <a>
                <LeftOutlined />
                Show Chat History
              </a>
            </div>
          )} */}
        </div>
        <div style={{ display: "flex" }}>
          <ChatQuesAns
            projectId={selectedProjectId}
            {...{
              isQuesAnsLoading,
              setChatSource,
              setIsQuesAnsLoading,
              selectedFile,
              showHelp,
              setShowHelp,
              documentUuid,
              setHideChatHistory,
              setExpanded,
            }}
          />
        </div>
      </div>
    </div>
  );
};
