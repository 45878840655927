import React from "react";
import { IconsProps } from "./Icons";

export const BMIIcon = ({ className, fill, width, height, viewBox, onClick }: IconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox={viewBox ? viewBox : "0 0 42 44"}
      width={width ? width : "42"}
      height={height ? height : "44"}
      fill={fill ? fill : "none"}
      onClick={onClick}
    >
      <path d="M19.9163 41.5H4.74967C4.17504 41.5 3.62394 41.2717 3.21761 40.8654C2.81128 40.4591 2.58301 39.908 2.58301 39.3333V4.66667C2.58301 4.09203 2.81128 3.54093 3.21761 3.1346C3.62394 2.72827 4.17504 2.5 4.74967 2.5H35.083C35.6576 2.5 36.2087 2.72827 36.6151 3.1346C37.0214 3.54093 37.2497 4.09203 37.2497 4.66667V17.125" stroke="url(#paint0_linear_7241_5217)" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M34.6887 25.2501C37.2995 25.2501 39.4163 27.2868 39.4163 29.8001C39.4163 33.0718 36.2638 35.8668 34.6887 37.3834C33.6378 38.3942 32.3259 39.406 30.7497 40.4168C29.1745 39.406 27.8604 38.3942 26.8107 37.3834C25.2344 35.8668 22.083 33.0718 22.083 29.8001C22.083 27.2868 24.1998 25.2501 26.8107 25.2501C28.4541 25.2501 29.9025 26.0583 30.7497 27.2846C31.1927 26.6521 31.7826 26.1366 32.4686 25.7823C33.1547 25.4279 33.9165 25.2453 34.6887 25.2501Z" fill="#8129BF" stroke="#8129BF" strokeWidth="4" strokeLinejoin="round"/>
      <path d="M11.25 11.1667H28.5833" stroke="#4131AB" strokeWidth="4" strokeLinecap="round"/>
      <linearGradient id="paint0_linear_7241_5217" x1="19.9163" y1="2.5" x2="19.9163" y2="41.5" gradientUnits="userSpaceOnUse">
      <stop stopColor="#3232A6"/>
      <stop offset="1" stopColor="#8128BF"/>
      </linearGradient>
    </svg>
  );
};
