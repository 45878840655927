import React from "react";
import { IconsProps } from "./Icons";

export const HighRiskIcon = ({ className, fill, width, height, viewBox, onClick }: IconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox={viewBox ? viewBox : "0 0 52 52"}
      width={width ? width : "52"}
      height={height ? height : "52"}
      fill={fill ? fill : "fill"}
      onClick={onClick}
    >
      <path d="M24.2628 0C20.1432 0.276402 16.1485 1.52984 12.6095 3.65659C9.07044 5.78334 6.08869 8.72226 3.91098 12.2302C1.73326 15.7381 0.422184 19.7142 0.0862272 23.8294C-0.24973 27.9446 0.399091 32.0807 1.97901 35.8954C3.55892 39.7101 6.02451 43.0937 9.17175 45.7663C12.319 48.4389 16.0574 50.3237 20.0777 51.2646C24.098 52.2055 28.2845 52.1756 32.2909 51.1773C36.2973 50.1789 40.0084 48.2409 43.1171 45.5236L24.7829 27.1859C24.6183 27.0245 24.4875 26.8319 24.3982 26.6194C24.3089 26.4068 24.2629 26.1786 24.2628 25.9481V0Z" fill="url(#paint0_linear_7241_5212)"/>
      <path d="M45.5684 43.0689C49.7229 38.334 52.0095 32.2472 52 25.9481C52.0048 22.4958 51.3199 19.0774 49.9856 15.8934L28.9227 26.4231L45.5684 43.0689ZM48.4288 12.7938C46.2839 9.13469 43.2763 6.05592 39.6685 3.82594C36.0606 1.59595 31.962 0.282456 27.73 0V23.1432L48.4288 12.7938Z" fill="url(#paint1_linear_7241_5212)"/>
      <linearGradient id="paint0_linear_7241_5212" x1="26" y1="0" x2="26" y2="51.9488" gradientUnits="userSpaceOnUse">
      <stop stopColor="#3232A6"/>
      <stop offset="1" stopColor="#8028BF"/>
      </linearGradient>
      <linearGradient id="paint1_linear_7241_5212" x1="26" y1="0" x2="26" y2="51.9488" gradientUnits="userSpaceOnUse">
      <stop stopColor="#3232A6"/>
      <stop offset="1" stopColor="#8028BF"/>
      </linearGradient>
    </svg>
  );
};
