import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Button, Form, Input } from "antd";
import { useForm } from "antd/es/form/Form";
import { authService } from "../../api";
import { MODAL_TYPE, PAGE_URL } from "../../utils";
import { loginUser, openModal } from "../../store";
import "./login.scss";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [disableSave, setDisableSave] = useState(true);
  const [error, setError] = useState("");

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    setLoading(true);
    setError("");
    const email = (e.currentTarget[0] as HTMLInputElement).value;
    const password = (e.currentTarget[1] as HTMLInputElement).value;
    try {
      const { data } = await authService.login({ email, password });
      if (data) {
        dispatch(loginUser(data.data));
        navigate(PAGE_URL.TASKS);
      }
    } catch (err: any) {
      setError(err.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const handleFormChange = () => {
    const hasErrors =
      !form.isFieldsTouched(true) || form.getFieldsError().some(({ errors }) => errors.length);
    setDisableSave(hasErrors);
  };

  return (
    <>
      <div className="user-form">
        <Form
          onSubmitCapture={(e) => handleFormSubmit(e)}
          name="normal_login"
          onFieldsChange={handleFormChange}
          form={form}
        >
          {/* <p className="login-title">
            AI Powered Clinical Trial <br></br>Platform
          </p> */}
          <Form.Item
            name="username"
            rules={[
              { required: true, message: "Please enter your email" },
              { type: "email", message: "Please enter a valid email" },
            ]}
          >
            <Input placeholder="Username / Email" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: "Please enter your password" }]}
          >
            <Input type="password" placeholder="Password" />
          </Form.Item>
          {error && <h3 className="error">{error}</h3>}
          <Form.Item>
            <div className="flex jcsb aic m-t">
              <a onClick={() => dispatch(openModal(MODAL_TYPE.FORGOT_PASSWORD))}>
                Forgot password ?
              </a>
              <Button
                className="login-button"
                htmlType="submit"
                loading={loading}
                disabled={disableSave}
              >
                Log in
              </Button>
            </div>
          </Form.Item>
          <div className="tc">
            <h3 className="bd-tp">
              Not a registered user ?{" "}
              <a onClick={() => dispatch(openModal(MODAL_TYPE.ADD_AND_SIGN_UP_USER))}>Sign up</a>
            </h3>
          </div>
        </Form>
      </div>
    </>
  );
};

export default Login;
