import { PAGE_URL } from "./routes";

export const CLINICAL_DATA_TASKS: ITask[] = [
  {
    title: "PHI Redaction",
    description:
      "Accepts an image (*.tiff, *.png), detects and removes PHI",
    link: `${PAGE_URL.TASKS_CLINICAL_DATA}${PAGE_URL.PHI_REDACTION}`,
  },
  {
    title: "Clinical Protocol Patient Summary",
    description:
      "Accepts a clinical protocol summary as application/json and returns back an application/json response with an empathetic patient friendly summary of the trial. the protocol summary uses relatable language and frames for patients what committing to a clinical trial means for their schedule, costs and care.",
    link: `${PAGE_URL.TASKS_CLINICAL_DATA}${PAGE_URL.CLINICAL_PROTOCOL_SUMMARY}`,
  },
  {
    title: "Clinical Protocol IE Criteria Simplification",
    description:
      "Accepts inclusion or exclusion criteria from a clinical protocol as application/json and returns back an application/json response with a matter of fact patient friendly summary of the criteria in relatable language.",
    link: `${PAGE_URL.TASKS_CLINICAL_DATA}${PAGE_URL.CLINICAL_PROTOCOL_ICEC_SUMMARY}`,
  },
  {
    title: "Clinical Note Classification",
    description:
      "Accepts a clinical text as application/json and returns back classification of clinical text as application/json into one of five (5) broad categories: disease characteristics, mediations, medical history, not enough information and patient characteristics.",
    link: `${PAGE_URL.TASKS_CLINICAL_DATA}${PAGE_URL.CLINICAL_NOTE_CLASSIFICATION}`,
  },
  {
    title: "Protected Health Information (PHI) Redaction",
    description: " Accepts an image (*.png, *.tiff) , detects and removes PHI.",
    link: `${PAGE_URL.TASKS_CLINICAL_DATA}${PAGE_URL.PHI_REDACTION}`,
  },
  {
    title: "Laboratory Results Extraction",
    description: "Accepts an laboratory result (*.pdf), detects and extracts a lab result (creatine).",
    link : `${PAGE_URL.TASKS_CLINICAL_DATA}${PAGE_URL.LABORATORY_RESULT}`,
  },
  {
    title: "CMS 2728 Form Extraction",
    description: "Accepts a CMS 2728 form pdf and extracts question 17d (and the cascading questions underneath) that tell us if a patient has started dialysis and if it was optimal",
    link : `${PAGE_URL.TASKS_CLINICAL_DATA}${PAGE_URL.FORM_2728_PROCESSOR}`,
  }
];
