import { Button, Input } from "antd";
import React from "react";
import { AppSearch } from "../../components";
import { TEXT } from "../../utils";
import { DatasetsTable } from "./dataset-container";

const { Search } = Input;

interface Props {
  datasets: IDataset[];
}

export const DatasetsContainer = (props: Props) => {
  const { datasets } = props;
  return (
    <div>
      <div className="flex jcsb aic">
        <AppSearch
          style={{ width: 300 }}
          inputProps={{
            placeholder: TEXT.datasets.searchPlaceholder,
          }}
          searchProps={{ onSearch: () => {}, onValueChange: () => {} }}
        />
        <div className="flex gp-10">
          {/* <Button className="fill">Tasks</Button> */}
          <Button className="fill">Connect to New Data Source</Button>
        </div>
      </div>
      <DatasetsTable datasets={datasets} />
    </div>
  );
};
