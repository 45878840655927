import React, { useEffect, useMemo, useState } from "react";
import { Button } from "antd";
import { tasksService } from "../../../api";
import { Loading } from "../../../components";
import { openNotificationWithIcon } from "../../../utils";
import { PageWrapper } from '../../component-wrappers';
import TasksApi from "../../../api/tasks.api";

interface Props {
  modelData: IModelDetail;
  selectedModel: string;
  query: string;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const EntityNormalizationOutput = ({
  query,
  isLoading,
  setIsLoading,
  selectedModel,
  modelData,
}: Props) => {


  const [queryResponse, setQueryResponse] = useState("");
  const selectedModelCode = useMemo(
    () => modelData.models.find((model) => model.name === selectedModel)?.model_code,
    [modelData.models, selectedModel]
  );
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [currPage, setCurrPage] = useState(1);
  //const [receivedLambdaResponse, setReceivedLambdaResponse] = useState<EvidencePageResponseInfo[] | null>(null);
  const [evidenceContentLoading, setEvidenceContentLoading] = useState(false);
  const [pdfUrl, setPdfUrl] = useState<any | ''>('');


  useEffect(() => {
    const getRelation = async () => {
      try {
        if (query) {
          const { data } = await new TasksApi().entityNormalization(query);
          setQueryResponse(data['results']);
        }
        // if (query) {
        //   const { data } = await tasksService.extractRelationship(selectedModelCode as string, {
        //     q: query.trim(),
        //   });
        //   setQueryResponse(data.data.output);
        // }
      } catch (e: any) {
        openNotificationWithIcon("", e.response.data.message, "error");
        setQueryResponse("");
      } finally {
        setIsLoading(false);
      }
    };
    getRelation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, setIsLoading]);
  let nextRowData: JSX.Element[] = [];
  if (queryResponse && Array.isArray(queryResponse)) {
    nextRowData = queryResponse.map((row: any, index: number) => (
      <tr key={index}>
        <td style={{ border: '1px solid #ddd', borderBottom: '2px solid #ddd', padding: '8px', textAlign: 'left'}}>{row['condition']}</td>
        <td style={{ border: '1px solid #ddd', borderBottom: '2px solid #ddd', padding: '8px', textAlign: 'left'}}><h5>{row['umls_code']}</h5></td>
        <td style={{ border: '1px solid #ddd', borderBottom: '2px solid #ddd', padding: '8px', textAlign: 'left'}}>{row['code']}</td>
        <td style={{ border: '1px solid #ddd', borderBottom: '2px solid #ddd', padding: '8px', textAlign: 'left'}}>
          {(Math.floor(row['confidence_score'] * 10000) / 100).toFixed(2)}%
        </td>
      </tr>
    ));
  }

  return (
    <>
      <div>
      <h1 className="m-b">Result</h1>
      <br />

      <div className="query-response">
            <h4 style={{color:'#3232A6', fontSize:'14px', fontWeight: 'bold'}}>CONDITIONS/PAST TREATMENT PROCEDURES</h4><br/>
            <table className="table-new"style={{ borderCollapse: 'collapse', width: '100%',overflow: 'hidden' }}>
              <thead>
                <tr>
                  <th style={{ border: '1px solid #ddd', fontSize:'14px', borderBottom: '2px solid #ddd', padding: '8px', textAlign: 'left', fontWeight: 'bolder'}}><h5>Condition</h5></th>
                  <th style={{ border: '1px solid #ddd', fontSize:'14px', borderBottom: '2px solid #ddd', padding: '8px', textAlign: 'left', fontWeight: 'bolder'}}><h5>UMLS Code Type</h5></th>
                  <th style={{ border: '1px solid #ddd', fontSize:'14px', borderBottom: '2px solid #ddd', padding: '8px', textAlign: 'left', fontWeight: 'bolder'}}><h5>Code</h5></th>
                  <th style={{ border: '1px solid #ddd', fontSize:'14px', borderBottom: '2px solid #ddd', padding: '8px', textAlign: 'left', fontWeight: 'bolder'}}><h5>Confidence Score</h5></th>
                  
                </tr>
              </thead>
              <tbody>
                {nextRowData}

              </tbody>
              
            </table>
          </div>
          </div>
      {/* <div className="tr m-t flex jce gp">
        <Button className="fill">Create API Endpoint</Button>
        <Button className="fill">Generate API Key</Button>
      </div> */}
    </>
  );
};
