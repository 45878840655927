import React from "react";
import { IconsProps } from "./Icons";

export const ApplicationIcon = ({ className, fill, width, height, viewBox, onClick }: IconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox={viewBox ? viewBox : "0 0 18 18"}
      width={width ? width : "18"}
      height={height ? height : "18"}
      fill={fill ? fill : "none"}
      onClick={onClick}
    >
<path d="M15.4286 0H18V2.57143H15.4286V0ZM15.4286 3.85714H18V6.42857H15.4286V3.85714ZM11.5714 0H14.1429V2.57143H11.5714V0ZM11.5714 3.85714H14.1429V6.42857H11.5714V3.85714Z" fill="currentColor"/>
<path d="M16.7143 9V12.8571H1.28571V2.57143H9V1.28571H1.28571C0.944722 1.28571 0.617695 1.42117 0.376577 1.66229C0.135459 1.90341 0 2.23044 0 2.57143V12.8571C0 13.1981 0.135459 13.5252 0.376577 13.7663C0.617695 14.0074 0.944722 14.1429 1.28571 14.1429H6.42857V16.7143H3.85714V18H14.1429V16.7143H11.5714V14.1429H16.7143C17.0553 14.1429 17.3823 14.0074 17.6234 13.7663C17.8645 13.5252 18 13.1981 18 12.8571V9H16.7143ZM10.2857 16.7143H7.71429V14.1429H10.2857V16.7143Z" fill="currentColor"/></svg>
  );
};
