import { useCallback, useState } from "react";
import { userService } from "../api";
import {
  getOffsetFromPageAndPageSize,
  getQueryStringFromPaginationObj,
  mergeQueryStrings,
} from "../utils";
import { usePagination } from "./usePagination";
import { IUser } from "../interfaces/user";

export const useFetchUsers = () => {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([] as IUser[]);
  const [total, setTotal] = useState(0);
  const { page, pageSize, pageSizeOptions, onPaginationChange } = usePagination();

  const fetchUsers = useCallback(
    async (getApprovedUser: boolean) => {
      setLoading(true);
      try {
        const paginationObj: IPagination = {
          offset: getOffsetFromPageAndPageSize(page, pageSize),
          limit: pageSize,
        };
        const paginationQs = getQueryStringFromPaginationObj(paginationObj);
        const qs = "?" + mergeQueryStrings([`approved=${getApprovedUser}`, paginationQs]);
        const { data } = await userService.getUsers(qs);
        setUsers(data.data.users);
        setTotal(data.data.paging.total || data.data.users.length);
      } finally {
        setLoading(false);
      }
    },
    [page, pageSize]
  );

  return {
    isLoading: loading,
    fetchUsers,
    users,
    total,
    pageSizeOptions,
    onPaginationChange,
    page,
    pageSize,
  };
};
