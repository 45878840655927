import { Button, Form, Input } from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { useState } from "react";
import { authService } from "../../../api";
import { MandatoryFieldsText } from "../../../components";
import { openNotificationWithIcon, USER_ROLES_CODE } from "../../../utils";

interface Props {
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  roleCode: string;
  setIsUserApproved: () => void;
  handleClose: () => void;
}
export const AddAndSignUpUserForm = ({
  setEmail,
  roleCode,
  handleClose,
  setIsUserApproved,
}: Props) => {
  const [disableSave, setDisableSave] = useState(true);
  const [loading, setLoading] = useState(false);
  const [form] = useForm();

  const handleFormChange = () => {
    const hasErrors =
      !form.isFieldsTouched(true) || form.getFieldsError().some(({ errors }) => errors.length);
    setDisableSave(hasErrors);
  };
  const handleSubmit = async (e: any) => {
    setLoading(true);
    //api call
    const firstName = e.target[0].value;
    const lastName = e.target[1].value;
    const email = e.target[2].value;

    try {
      setLoading(true);
      const { data } = await authService.signUp({ firstName, lastName, email });
      if (roleCode === USER_ROLES_CODE.ADMIN) {
        handleClose();
        setIsUserApproved();
        openNotificationWithIcon("", "Client Added Successfully", "success");
      } else {
        setEmail(data.data.email);
      }
    } catch (error: any) {
      openNotificationWithIcon("", error.response.data.message || "Something Went Wrong", "error");
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <div className="addClient-title">
        <h1>{roleCode === USER_ROLES_CODE.ADMIN ? "Add User" : "Sign Up"}</h1>
      </div>

      <div className="login-content-modal">
        <Form
          form={form}
          onSubmitCapture={handleSubmit}
          onFieldsChange={handleFormChange}
          colon={false}
          requiredMark={false}
        >
          <div className="flex jcsb">
            <Form.Item
              className="w-45"
              name="firstName"
              label="First Name"
              rules={[{ required: true, message: "Please enter first name" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              style={{ paddingTop: 0 }}
              className="w-45"
              name="lastName"
              label="Last Name"
              rules={[{ required: true, message: "Please enter last name" }]}
            >
              <Input />
            </Form.Item>
          </div>
          <Form.Item
            name="email"
            label="Email"
            rules={[
              { required: true, message: "Please enter email" },
              { type: "email", message: "Please enter valid email" },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item className="mandatory-text">
            <MandatoryFieldsText />
            <Button className="outline" htmlType="submit" disabled={disableSave} loading={loading}>
              {roleCode === USER_ROLES_CODE.ADMIN ? "Add User" : "Submit"}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};
