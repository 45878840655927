import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EditUserResponse, IUser } from "../../interfaces/user";
import { UserRole } from "../../interfaces/roles";

interface UserSlice {
  user: IUser;
  impersonate: { roleCode: string; isActive: boolean; roleName: string };
  roles: {
    list: UserRole[];
    id: number;
  };
  previousEmail: string;
  previousURL: string;
}

export const userInitialState: UserSlice = {
  user: { roles: {} } as IUser,
  impersonate: { roleCode: "", isActive: false, roleName: "" },
  roles: {
    list: [] as UserRole[],
    id: -1,
  },
  previousEmail: "",
  previousURL: "",
};

export const userSlice = createSlice({
  name: "user",
  initialState: userInitialState,
  reducers: {
    loginUser: (state: UserSlice, { payload }: PayloadAction<IUser>) => {
      state.user = { ...payload, roles: { ...payload.roles } };
    },
    setImpersonation: (
      state: UserSlice,
      { payload }: PayloadAction<{ roleCode: string; roleName: string }>
    ) => {
      const { roleCode, roleName } = payload;
      state.impersonate = { isActive: true, roleCode, roleName };
    },
    removeImpersonation: (state: UserSlice) => {
      state.impersonate = { isActive: false, roleCode: "", roleName: "" };
    },
    logoutUser: (state: UserSlice) => {
      state.user = { roles: {} } as IUser;
      state.impersonate = { isActive: false, roleCode: "", roleName: "" };
    },
    updateCurrentUser: (state: UserSlice, { payload }: PayloadAction<EditUserResponse>) => {
      state.user = { ...state.user, ...payload };
    },
    setUserRoles: (state: UserSlice, { payload }: PayloadAction<UserRole[]>) => {
      state.roles = { list: [...payload], id: -1 };
    },
    addUserRole: (state: UserSlice, { payload }: PayloadAction<UserRole>) => {
      state.roles.list = [...state.roles.list, payload];
    },
    deleteUserRole: (state: UserSlice, { payload }: PayloadAction<number>) => {
      const index = state.roles.list.findIndex((item) => item.Id === payload);
      if (index !== -1)
        state.roles.list = [
          ...state.roles.list.slice(0, index),
          ...state.roles.list.slice(index + 1),
        ];
    },
    updateUserRole: (state: UserSlice, { payload }: PayloadAction<UserRole>) => {
      state.roles = {
        list: state.roles.list.map((item) =>
          item.Id === payload.Id ? { ...payload } : { ...item }
        ),
        id: -1,
      };
    },
    setUserRoleEdit: (state: UserSlice, { payload }: PayloadAction<number>) => {
      state.roles.id = payload;
    },
    setPreviousEmailAndUrl: (state: UserSlice, { payload }: PayloadAction<string>) => {
      state.previousEmail = state.user.email;
      state.previousURL = payload;
    },
    resetPreviousEmailAndUrl: (state: UserSlice) => {
      state.previousEmail = "";
      state.previousURL = "";
    },
  },
});

export const {
  loginUser,
  logoutUser,
  updateCurrentUser,
  setUserRoles,
  setUserRoleEdit,
  updateUserRole,
  addUserRole,
  deleteUserRole,
  setImpersonation,
  removeImpersonation,
  setPreviousEmailAndUrl,
  resetPreviousEmailAndUrl,
} = userSlice.actions;

export default userSlice.reducer;
