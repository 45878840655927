import React, { useEffect, useState } from "react";
import { EvidencePageResponseInfo } from "../../../api";
import { isEmptyObject } from "../../../utils/objectHelper";
import {
  EvidenceViewerDefault,
  EvidenceViewerOCR,
  EvidenceContentMedicalEntities,
  EvidenceContentNormalEntities,
  EvidenceContentJSON,
  KeyValueMap,
} from ".";
import { ENHANCED_VIEW_TABS } from "../EvidenceViewerContainer";
import { Loading } from "../../../components";
import "./evidence-content.scss";

interface Props {
  pageInfo: EvidencePageResponseInfo;
  loading: boolean;
  selectedTab: string;
  searchKeywords: string;
  selectedLegends: string[];
  categoriesMapping?: KeyValueMap
}

export const EvidenceContent = ({
  pageInfo,
  loading,
  selectedTab,
  searchKeywords,
  selectedLegends,
  categoriesMapping
}: Props) => {
  const [component, setComponent] = useState(<></>);

  useEffect(() => {
    if (!isEmptyObject(pageInfo)) {
      let res = <></>;
      switch (selectedTab) {
       
        case ENHANCED_VIEW_TABS.PAGE:
          res = <EvidenceViewerDefault {...{ pageInfo, searchKeywords, selectedLegends, categoriesMapping }} />;
          break;
        case ENHANCED_VIEW_TABS.OCR:
          res = <EvidenceViewerOCR pageInfo={pageInfo} />;
          break;
        case ENHANCED_VIEW_TABS.NORMAL_ENTITIES:
          res = <EvidenceContentNormalEntities pageInfo={pageInfo} />;
          break;
        case ENHANCED_VIEW_TABS.MEDICAL_ENTITIES:
          res = <EvidenceContentMedicalEntities pageInfo={pageInfo} categoriesMapping={categoriesMapping}/>;
          break;
        case ENHANCED_VIEW_TABS.JSON:
          res = <EvidenceContentJSON pageInfo={pageInfo} />;
          break;
      }
      setComponent(res);
    }
  }, [pageInfo, searchKeywords, selectedLegends, selectedTab]);

  return (
    <>
    {/* uncomment this when api call will success */}
      {/* {isEmptyObject(pageInfo) || loading ? (
        <Loading />
      ) : (
        <div className="e-content">{component}</div>
      )} */}
      <div className="e-content">{component}</div>
    </>
  );
};
