import React from "react";
import { IconsProps } from "./Icons";

export const ECIcon = ({ className, fill, width, height, viewBox, onClick }: IconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox={viewBox ? viewBox : "0 0 52 52"}
      width={width ? width : "52"}
      height={height ? height : "52"}
      fill={fill ? fill : "none"}
      onClick={onClick}
    >
      <path d="M26 0C11.648 0 0 11.648 0 26C0 29.458 0.676 32.786 1.924 35.802L15.6 22.1L24.18 29.328L32.708 20.8H28.6V15.6H41.6V28.6H36.4V24.492L24.466 36.4L15.912 29.146L4.472 40.586C6.85433 44.103 10.0624 46.9824 13.8155 48.9723C17.5685 50.9621 21.7521 52.0017 26 52H46.8C49.66 52 52 49.66 52 46.8V26C52 11.648 40.352 0 26 0ZM45.5 48.1C44.07 48.1 42.9 46.93 42.9 45.5C42.9 44.07 44.07 42.9 45.5 42.9C46.93 42.9 48.1 44.07 48.1 45.5C48.1 46.93 46.93 48.1 45.5 48.1Z" fill="url(#paint0_linear_7241_5230)"/>
      <linearGradient id="paint0_linear_7241_5230" x1="26" y1="0" x2="26" y2="52" gradientUnits="userSpaceOnUse">
      <stop stopColor="#3232A6"/>
      <stop offset="1" stopColor="#8028BF"/>
      </linearGradient>
    </svg>
  );
};
