import React from "react";
import { Tabs } from "antd";
import { TASK_TABS } from "../../utils";

const { TabPane } = Tabs;

interface Props {
  onTabChange: (key: string) => void;
  selectedTab: string;
}

export const TaskInputTabs = ({ onTabChange, selectedTab }: Props) => {
  return (
    <div>
      <Tabs className="tabs" onChange={onTabChange} activeKey={selectedTab}>
        {Object.values(TASK_TABS).map((key) => (
          <TabPane tab={key} key={key} />
        ))}
      </Tabs>
    </div>
  );
};
