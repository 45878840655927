import React, { useEffect, useState } from "react";
import { PROJECT_DETAILS_TABS } from ".";
import ProjectAnalyze from "./project-details-container/ProjectAnalyze";
import ProjectDataSource from "./project-details-container/ProjectDataSource";
import ProjectDetailsTab from "./project-details-container/ProjectDetailsTab";
import ProjectSearch from "./project-details-container/ProjectSearch";
import ProjectSummary from "./project-details-container/ProjectSummary";
import "./project-details-container/project-details.scss";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

const ProjectDetailsContainer = () => {
  const [selectedTab, setSelectedTab] = useState(PROJECT_DETAILS_TABS.SUMMARY);
  const [component, setComponent] = useState(<></>);
  const { projectId } = useParams();
  const [project, setProject] = useState({} as IProject);
  const projects = useSelector((state: RootState) => state.project.list);

  const onChange = (key: string) => {
    setSelectedTab(key);
  };
  useEffect(() => {
    const projectIndex = projects.findIndex((item) => item.id === Number(projectId));
    if (projectIndex !== -1) setProject(projects[projectIndex]);
  }, [projectId, projects]);

  useEffect(() => {
    let res = <></>;
    switch (selectedTab) {
      case PROJECT_DETAILS_TABS.SUMMARY:
        res = <ProjectSummary {...{ project }} />;
        break;
      case PROJECT_DETAILS_TABS.DATA_SOURCES:
        res = <ProjectDataSource />;
        break;
      case PROJECT_DETAILS_TABS.SEARCH:
        res = <ProjectSearch />;
        break;
      case PROJECT_DETAILS_TABS.ANALYZE:
        res = <ProjectAnalyze />;
        break;
    }
    setComponent(res);
  }, [selectedTab, project]);
  return (
    <>
      <div className="project-heading">
          <p className="project-heading-text">{project.name}</p>
          <ProjectDetailsTab {...{ onChange, selectedTab }} />
      </div>
      <div className="project-tab-content">{component}</div>
    </>
  );
};

export default ProjectDetailsContainer;
