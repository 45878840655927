import React from "react";
import { Button, ButtonProps, Input } from "antd";
import { SearchIcon } from "../../assets/icons";
import { InputProps } from "antd/lib/input";
import "./app-search.scss";

type MySearchProps = { onValueChange: (str: string) => void; onSearch: () => void };
type MyInputProps = InputProps;
type MyButtonProps = { title: string } & ButtonProps;
interface Props {
  inputProps: MyInputProps;
  buttonProps?: MyButtonProps;
  searchProps: MySearchProps;
  style?: React.CSSProperties | undefined;
}

export const AppSearch = (props: Props) => {
  const { buttonProps, inputProps, searchProps, style } = props;
  const { onSearch, onValueChange } = searchProps;
  return (
    <div className="app-search flex no-wrap gp aic">
      <Input
        style={style}
        onChange={(e) => onValueChange(e.target.value)}
        onPressEnter={() => onSearch()}
        {...inputProps}
        suffix={<SearchIcon />}
      />
      {buttonProps && <Button {...buttonProps}>{buttonProps.title}</Button>}
    </div>
  );
};
