import React, { useCallback, useState } from 'react';
import TasksApi from "../../api/tasks.api";
import { Button, Upload, message } from "antd";
import { PageWrapper, EvideceViewerAside, EvidenceViewerContainer } from '../';
import AWS from 'aws-sdk';
import { EvidencePageResponseInfo } from '../../api';
import { KeyValueMap } from '../evidence-viewer/evidence-viewer-container';
import { CohortCollapseIcon } from '../../assets/icons';

import { REACT_APP_AWS_REGION, REACT_APP_AWS_ACCESS_KEY_ID, REACT_APP_AWS_SECRET_ACCESS_KEY, REACT_APP_S3_BUCKET_NAME } from '../../utils/constants/constants';


AWS.config.update({
    region: REACT_APP_AWS_REGION,
    accessKeyId: REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: REACT_APP_AWS_SECRET_ACCESS_KEY,
});
const s3 = new AWS.S3();

const categoriesMapping: KeyValueMap = {
    CMS_2728_FORM: "#E52337"
};

interface Props {
    isContainerCollapsed: boolean;
    setIsContainerCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Form2728ProcessorContainer = (
    {
        isContainerCollapsed,
        setIsContainerCollapsed
    }: Props) => {
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [currPage, setCurrPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [receivedLambdaResponse, setReceivedLambdaResponse] = useState<EvidencePageResponseInfo[] | null>(null);
    const [evidenceContentLoading, setEvidenceContentLoading] = useState(false);
    const [pdfUrl, setPdfUrl] = useState<any | ''>('');

    const handleFileChange = useCallback((info: any) => {
        try {
            if (info.fileList.length > 1) {
                info.fileList.shift();
            }
            const file = info.fileList[0];
            if (file) {
                setSelectedFile(file.originFileObj);
                setPdfUrl("");
                setReceivedLambdaResponse(null);
                setCurrPage(1);
            }
        } catch (e: any) {
            message.error(e.message);
        }
    }, []);

    const uploadFileToS3 = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const params: AWS.S3.PutObjectRequest = {
                Bucket: process.env.REACT_APP_S3_BUCKET_NAME ? process.env.REACT_APP_S3_BUCKET_NAME : 'autonomize-processing-sprint',
                Key: file.name,
                Body: file,
                ContentType: "application/pdf",
                ContentDisposition: `form-data; name="file"; filename="${file.name}"`
            };

            s3.upload(params, (err: any, data: AWS.S3.ManagedUpload.SendData) => {
                if (err) {
                    console.error('Error uploading file:', err);
                    reject(err);
                } else {
                    resolve(data.Location);
                }
            });
        });
    };

    const generateS3PreSignedUrl = async (objectKey: string): Promise<string> => {
        const params = {
            Bucket: process.env.REACT_APP_S3_BUCKET_NAME ? process.env.REACT_APP_S3_BUCKET_NAME : 'autonomize-processing-sprint',
            Key: objectKey,
            Expires: 30 * 60
        };
        const url = s3.getSignedUrlPromise('getObject', params);
        return url
    }


    const handleUpload = async () => {
        if (selectedFile) {
            setIsLoading(true);
            // setReceivedLambdaResponse(csmFormData as unknown as EvidencePageResponseInfo[]);
            try {
                // Use a Promise to wait for the upload to finish
                await new Promise<void>((resolve, reject) => {
                    uploadFileToS3(selectedFile)
                        .then((dataLocation) => {
                            resolve(); // Resolve the promise when the upload is successful
                        })
                        .catch((err) => {
                            console.error('Error uploading file:', err);
                            reject(err); // Reject the promise in case of an error
                        });
                });
                await new Promise<void>((resolve, reject) => {
                    generateS3PreSignedUrl(selectedFile.name)
                        .then((pdfS3Url) => {
                            setPdfUrl(pdfS3Url);
                            resolve(); // Resolve the promise when the upload is successful
                        })
                        .catch((err) => {
                            console.error('Error uploading file:', err);
                            reject(err); // Reject the promise in case of an error
                        });
                });
                setEvidenceContentLoading(true);
                const cmsResponse: any = await new TasksApi().cms2728Form(selectedFile.name);
                if (cmsResponse) {
                    setReceivedLambdaResponse(cmsResponse.data.results as unknown as EvidencePageResponseInfo[]);
                }
            } catch (error) {
                console.error('Error processing file:', error);
            } finally {
                setIsLoading(false);
                setEvidenceContentLoading(false);
            }
        }
    };

    const handleRemoveFile = () => {
        setSelectedFile(null);
        setPdfUrl('');
        setReceivedLambdaResponse(null);
        setCurrPage(1);
    };

    return (
        <div style={{ display: 'grid' }}>
            <div style={{display: 'flex', cursor: 'pointer'}}  onClick={() => { setIsContainerCollapsed(!isContainerCollapsed) }} >
                <CohortCollapseIcon className={`${isContainerCollapsed ? "rotate-icon" : ""} collapse-pointer-icon`} fill='#3232A6'/>
                <p style={{marginLeft: "1em", color: '#3232A6'}}>{isContainerCollapsed ? "Show Left Panel": "Hide Left Panel"}</p>
            </div>
            <div>
                <div className="browse-file m-b">
                    <br />
                    <Upload accept='application/pdf' onChange={handleFileChange} onRemove={handleRemoveFile}>
                        <Button className="outline">
                            Browse File
                        </Button>
                    </Upload>
                </div>
                <div>
                    <Button className="outline" loading={isLoading} onClick={handleUpload} disabled={selectedFile ? false : true}>
                        Submit
                    </Button><br /><br />
                </div>
            </div>
            <div>
                <h4>Response</h4> <br />
                {receivedLambdaResponse && pdfUrl ? (<PageWrapper
                    pageContainerChildren={
                        <EvidenceViewerContainer
                            {...{
                                currPage,
                                feedBackEnabled: false,
                                pdfViewerEnabled: true,
                                evidenceContentLoading: isLoading || evidenceContentLoading,
                                pageData: receivedLambdaResponse[currPage - 1],
                                pdfUrl,
                                setCurrPage,
                                categoriesMapping
                            }}
                        />
                    }
                />) :
                    (<div>Upload CMS 2728 Form PDF to get a response.</div>)}
            </div>
        </div>
    );
};