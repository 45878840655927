import React from "react";
import { IconsProps } from "./Icons";

export const ActiveCheckIcon = ({ className, fill, width, height, viewBox }: IconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox={viewBox ? viewBox : "0 0 15 15"}
      width={width ? width : "15"}
      height={height ? height : "15"}
      fill={fill ? fill : "currentColor"}
    >
      <path xmlns="http://www.w3.org/2000/svg" d="M6.18675 10.3867L11.1217 5.45169L10.1417 4.47169L6.18675 8.42669L4.19175 6.43169L3.21175 7.41169L6.18675 10.3867ZM7.16675 14.1667C6.19841 14.1667 5.28841 13.9828 4.43675 13.6151C3.58508 13.2478 2.84425 12.7492 2.21425 12.1192C1.58425 11.4892 1.08561 10.7484 0.718348 9.89669C0.350615 9.04502 0.166748 8.13502 0.166748 7.16669C0.166748 6.19835 0.350615 5.28835 0.718348 4.43669C1.08561 3.58502 1.58425 2.84419 2.21425 2.21419C2.84425 1.58419 3.58508 1.08532 4.43675 0.717587C5.28841 0.35032 6.19841 0.166687 7.16675 0.166687C8.13508 0.166687 9.04508 0.35032 9.89675 0.717587C10.7484 1.08532 11.4892 1.58419 12.1192 2.21419C12.7492 2.84419 13.2479 3.58502 13.6151 4.43669C13.9829 5.28835 14.1667 6.19835 14.1667 7.16669C14.1667 8.13502 13.9829 9.04502 13.6151 9.89669C13.2479 10.7484 12.7492 11.4892 12.1192 12.1192C11.4892 12.7492 10.7484 13.2478 9.89675 13.6151C9.04508 13.9828 8.13508 14.1667 7.16675 14.1667Z" fill="currentColor"/>
    </svg>
  );
};
