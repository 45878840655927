import React, { SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { openNotificationWithIcon } from "../../../utils";
import { tasksService } from "../../../api";
import { MenuIcon, ThumbsDown, ThumbsUp } from "../../../assets/icons";
import { autonomizeLogo } from "../../../assets/images";
import { Loading } from "../../../components";
import { Button, Input } from "antd";
import { SendOutlined } from "@ant-design/icons";

const { Search } = Input;

interface Props {
  quesAns: IQuesAns[];
  setQuesAns: React.Dispatch<SetStateAction<IQuesAns[]>>;
  selectedModel: string;
  modelData: IModelDetail;
}

export const HemophiliaQuestionAnsweringChat = ({
  quesAns,
  setQuesAns,
  selectedModel,
  modelData,
}: Props) => {
  const [inputValue, setInputValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const questionAnswerListRef = useRef<HTMLDivElement>(null);

  const selectedModelCode = useMemo(
    () => modelData.models.find((model) => model.name === selectedModel)?.model_code,
    [modelData.models, selectedModel]
  );

  const handleSearchSubmit = useCallback(
    async (e: string) => {
      if (e.trim().length > 0) {
        setIsLoading(true);
        try {
          const { data } = await tasksService.hemophiliaQA(selectedModelCode as string, {
            q: e.trim(),
          });
          setQuesAns((prev) => [...prev, data.data]);
          setInputValue("");
        } catch (error: any) {
          openNotificationWithIcon("", error.response.data.message, "error");
        } finally {
          setIsLoading(false);
        }
      }
    },
    [setQuesAns, selectedModelCode]
  );

  useEffect(() => {
    if (questionAnswerListRef.current) {
      questionAnswerListRef.current.scrollTo({
        top: questionAnswerListRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [quesAns]);
  return (
    <div className={`ask-question hemophilia`}>
      <div className="ask-question-left-conatiner">
        <div ref={questionAnswerListRef} style={{  overflowY: "scroll" }}>
          {quesAns.map((item, index) => (
            <div className="ask-question-result" key={index}>
              <div className="ask-question-result-ques">
                <MenuIcon className="question-icon" />
                <h4 className="mb-0">{item.query}</h4>
              </div>
              <div className="ask-question-result-answer">
                {/* <div className="flex aic gp"> */}
                <img className="ask-question-result-answer-img" src={autonomizeLogo} />
                <div>
                  <p>{item.answer}</p>
                </div>
                <div className="flex gp aic">
                  <div className="cursor-pointer flex">
                    <ThumbsUp />
                  </div>
                  <div className="cursor-pointer flex">
                    <ThumbsDown />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="ask-question-search-bar">
          <Search
            className="record-search m-b"
            placeholder="Enter your question"
            enterButton={isLoading ? <Loading tip="" /> : <Button icon={<SendOutlined />} />}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onSearch={(e) => handleSearchSubmit(e)}
            disabled={isLoading}
          />
          <p>This is an interactive way to ask any question regarding the datasets in question.</p>
        </div>
      </div>
    </div>
  );
};
