import React, { SetStateAction, useCallback, useEffect, useRef, useState } from "react";
import { Button, Input } from "antd";
import { SendOutlined } from "@ant-design/icons";
import { MODAL_TYPE, openNotificationWithIcon } from "../../../utils";
import { MenuIcon, ThumbsDown, ThumbsUp } from "../../../assets/icons";
import { useDispatch, useSelector } from "react-redux";
import { RootState, openModal } from "../../../store";
import { autonomizeLogo } from "../../../assets/images";
import { Loading } from "../../../components";
import "./askQuestion.scss";
import { useNavigate } from "react-router-dom";
import { openaiModelService } from "../../../api";

const { Search } = Input;

interface Props {
  contextId: string;
  quesAns: IQuesAns[];
  setQuesAns: React.Dispatch<SetStateAction<IQuesAns[]>>;
}

export const AskAQuestion = ({ contextId, quesAns, setQuesAns }: Props) => {
  const [inputValue, setInputValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const questionAnswerListRef = useRef<HTMLDivElement>(null);
  const [isQAHistoryCollapsed, setIsQAHistoryCollapsed] = useState(true);

  const handleSearchSubmit = useCallback(
    async (e: string) => {
      if (e.trim().length > 0) {
        setIsLoading(true);
        try {
          const { data } = await openaiModelService.query(contextId, e.trim());
          setQuesAns((prev) => [...prev, data]);
          setInputValue("");
        } catch (error: any) {
          openNotificationWithIcon("", error.response.data.message, "error");
        } finally {
          setIsLoading(false);
        }
      }
    },
    [contextId, setQuesAns]
  );

  useEffect(() => {
    if (questionAnswerListRef.current) {
      questionAnswerListRef.current.scrollTo({
        top: questionAnswerListRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [quesAns]);

  return (
    <div className={`ask-question ${isQAHistoryCollapsed ? "collapse" : ""}`}>
      <div className="ask-question-left-conatiner">
        <div ref={questionAnswerListRef} style={{ maxHeight: "450px", overflowY: "scroll" }}>
          {quesAns.map((item, index) => (
            <div className="ask-question-result" key={index}>
              <div className="ask-question-result-ques">
                <MenuIcon className="question-icon" />
                <h4 className="mb-0">{item.query}</h4>
              </div>
              <div className="ask-question-result-answer">
                {/* <div className="flex aic gp"> */}
                <img className="ask-question-result-answer-img" src={autonomizeLogo} />
                <div dangerouslySetInnerHTML={{ __html: item.answer }}>
                  {/* <p>{item.answer}</p> */}
                </div>
                <div className="flex gp aic">
                  <div className="cursor-pointer flex">
                    <ThumbsUp />
                  </div>
                  <div className="cursor-pointer flex">
                    <ThumbsDown />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="ask-question-search-bar">
          <Search
            className="record-search m-b"
            placeholder="Enter your question"
            enterButton={isLoading ? <Loading tip="" /> : <Button icon={<SendOutlined />} />}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onSearch={(e) => handleSearchSubmit(e)}
            disabled={isLoading}
          />
          <p>This is an interactive way to ask any question regarding the datasets in question.</p>
        </div>
      </div>
    </div>
  );
};
