import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { PageWrapper, EvideceViewerAside, EvidenceViewerContainer } from "../containers";
import { useFetchEvidenceViewer, useFetchSearchResult } from "../hooks";
import "../containers/evidence-viewer/evidence-viewer-container/evidence-viewer-container.scss";

const EvidenceViewer = () => {
  const [searchParams] = useSearchParams();
  const { fetchSearchResults, searchLoading, searchResult } = useFetchSearchResult();
  const [pageSearchKey, setPageSearchKey] = useState<string | null>(null);
  const [searchKeywords, setSearchKeywords] = useState<string | null>(null);

  const { fileId, initialSearchKeywords, multipleFiles, tileType } = useMemo(() => {
    setSearchKeywords(searchParams.get("search"));
    return {
      fileId: searchParams.get("fileId") || "",
      initialSearchKeywords: searchParams.get("search"),
      multipleFiles: searchParams.get("multipleFiles"),
      tileType: searchParams.get("tile_type"),
    };
  }, [searchParams]);

  const {
    fetchPage,
    currPage,
    fileData,
    occurencesList,
    pageLoading,
    searchOccurenceLoading,
    pageData,
    setCurrPage,
    fetchSearchOccurences,
  } = useFetchEvidenceViewer({ fileId, searchKeywords });

  useLayoutEffect(() => {
    if (tileType) {
      const find = occurencesList.findIndex((ele) => ele.pageNumber === currPage);
      if (find !== -1) {
        setPageSearchKey(occurencesList[find].content.slice(0, tileType === "lifestyle" ? 14 : 20));
      }
    }
  }, [currPage, occurencesList, tileType]);

  useEffect(() => {
    if (multipleFiles === "true" && !searchResult.data) {
      fetchSearchResults();
    }
  }, [fetchSearchResults, multipleFiles, searchResult.data]);

  // useEffect(() => {
  //   if (fileId) {
  //     fetchFile();
  //   }
  // }, [fetchFile, fileId]);

  useEffect(() => {
    if (!fileId) {
      fetchPage();
    }
  }, [fetchPage, fileId]);

  useEffect(() => {
    if (((searchKeywords && searchKeywords.length > 0) || tileType) && fileId)
      fetchSearchOccurences(tileType);
  }, [fetchSearchOccurences, fileId, searchKeywords, tileType]);

  return (
    <>
      <PageWrapper
        // pageLeftAsideChildren={
        //   <EvideceViewerAside
        //     {...{
        //       currPage,
        //       setCurrPage,
        //       fileId,
        //       pageData,
        //       searchLoading,
        //       searchResult,
        //       searchKeywords,
        //       setSearchKeywords,
        //       tileType,
        //       searchResultOccurrenceLoading: searchOccurenceLoading || searchLoading,
        //       occurencesList,
        //       initialSearchKeywords,
        //     }}
        //   />
        // }
        pageContainerChildren={
          <EvidenceViewerContainer
            {...{
              currPage,
              evidenceContentLoading: pageLoading || searchLoading,
              fileId,
              // initialSearchKeywords,
              // occurencesList,
              feedBackEnabled:true,
              pdfViewerEnabled: false,
              pageData,
              pageSearchKey,
              pdfUrl: `https://autonomize-demo-documents.s3.us-east-1.amazonaws.com/customerX/123456_PAFax.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=ASIAW2MSZN7VFM5O7JY4%2F20231004%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20231004T080803Z&X-Amz-Expires=180&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEDgaCXVzLWVhc3QtMSJHMEUCIB4mxNWiUnoWF2LWgZKf%2Fyz2eTeVpraEIc0uWv%2FztcO8AiEAq8BOxf8c%2BOmM%2FIBq9grW%2Ffjz0dZH31RFtYTGcfuZ11gquwUIQBAAGgw0Njg5OTYxNTc0MTgiDJL%2FX%2F51kynJ%2F%2BBBzSqYBVoXYK8AxqJTl4Uq5Eb2CuBOjVt%2BJt7kkCAIDuvYOa%2By%2FGfTLuli94X9eKVFFDxP8tb8QTWH9bIlyUmKPnhA%2FePx8OQiq4UV9UF2XHVKS0d7CRGdTag9Vki2W3QagBSaQINlpwNo55XiSo4eYcjD%2BCzkYx%2BEDhBfP3LqvnIf10N9%2B3eG%2FLKXVk%2FpI1wBNFcGjy%2FkC8tJMn5Wgi2cbWSQ5mppowmDYmIa5VxdVtV0jQG7J8LNP8I6F3h2UJG1kPeU0byiL8%2B%2FruZb316cYVK9bzQkUeWMXYnuBcwlDHYcjh8yBG2I1dfrw1SzBwIiaJYXX7ghVtPEclYac2yPXnKp3jU56P8rwkD68MH%2BFnnMWzjcTB%2BP4Vo4dFdMV%2FSO9YLPyzP93gN4LUTvITy3%2BNL6z%2Bg%2BhBb8EUgmYxKoBxW3EP8EMD1kl8vTGlcxway%2BtX7BhAQORtOE0uYjXdjsFJvfEA1ln1qt8mhV9HOmEXBjk74Kg8Ky5Cqq6cduWSVrabG0OfTPztpeb50QHA3goMPiEVolQPBKt79AkuMPU6%2BRkPaXPytGeQ0ErwT7u%2FJIo%2BDHVadkHN0v5gO4%2B3brAgh%2FRYhg6r3UamM4fblylOxpzvGdBRqOmiEDKT1CrMFqOv%2B8Ea1UeDDbbqMDumrAGqxcaPBGC72WsNj9dupdfYKMXSPddt9o5t9h3nKYcsSsaIecSSeGtLaj8iyxUs12kcfUDhsj64kVbsoNdTe7MLc6ZRUN7j9zCRMsaIDn1HPNe8i1%2Bvd0fdhGjw%2B8uq%2FQGooiCjJpp%2BR8cMTQYH1vj0Q8kL18HiJbGvTWjxcb8lSwqlTLFx%2Fws659NDdrKD2Ie2iyRUGn3%2BeJ0aUxDGWxmAoZt3yp1vRGVS4y81gwyKT0qAY6sQE3zxkNxDAClxpOKBEeG4T8qf227lBqRAduoT5Tkl%2BWaykI5GOpw5vZp6gsZ8gxiRHUIwe0ugDSikTYlSgApodoSZPNFFDn3cMK8xH7bvSnrsAgPGHomcwCizKpT3NSvLHDhkdJxc7MNHGPX9vC6YFzIUsnGYS7Z7z%2FcljExwnyH%2BCDjaBILnhTIgyuBHrnLtetdIN2Sbhq%2FyZgF9if7%2B3tiKFTxeg2v%2FrCcYekK0upPQU%3D&X-Amz-Signature=526290bffa4fc9cc06c7e0ed757fdb1fe0758efe6ed0f30b9591353785047bf3&X-Amz-SignedHeaders=host&x-id=GetObject`,
              searchKeywords,
              // searchResultOccurrenceLoading: searchOccurenceLoading || searchLoading,
              setCurrPage,
              // setSearchKeywords,
              // tileType,
            }}
          />
        }
      />
    </>
  );
};

export default EvidenceViewer;
