import React, { useCallback, useEffect, useState } from "react";
import { Button } from "antd";
import { FileUpload, InputText } from "../../../components";
import { Tabs } from "antd";
import { OpenAIFileUpload, OpenAiFileUploadAntd } from "./OpenAIFileUpload";
import { openaiModelService } from "../../../api";
import { TABS } from "../GenerativeQaContainer";

const { TabPane } = Tabs;
// import { TaskInputTabs } from "./TaskInputTabs";
// import { InputText } from "./InputText";
// import { tasksService } from "../../api";



const allowedTypes = [".txt", ".pdf", ".doc"];

const validateFile = (file: any) => {
  if (!file) {
    throw new Error("No file selected.");
  } else {
    const extension = file.name.substring(file.name.lastIndexOf("."));
    if (!allowedTypes.includes(extension)) {
      throw new Error("Invalid file type.");
    }
  }
};

export interface ErrorState {
  textInput: string;
}

interface Props {
  isLoading: boolean;
  query: string;
  selectedTab: string;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setContextId: React.Dispatch<React.SetStateAction<string>>;
  setQuery: React.Dispatch<React.SetStateAction<string>>;
  setSelectedTab: React.Dispatch<React.SetStateAction<string>>
}

export const OpenAIInput = ({ setIsLoading, isLoading, setContextId, query, setQuery, selectedTab, setSelectedTab }: Props) => {
  const [error, setError] = useState<ErrorState>({
    textInput: "",
  });

  const [inputText, setInputText] = useState("");
  // const [selectedTab, setSelectedTab] = useState(TABS.UPLOAD_FILE);

  const onInputTextChange = useCallback(
    (e: any) => {
      setError({ ...error, textInput: "" });
      setInputText(e.target.value);
    },
    [error, setInputText]
  );

  const onTabChange = (key: string) => {
    setSelectedTab(key);
  };

  const validateForm = () => {
    const err: ErrorState = {
      textInput: "",
    };
    let isValidForm = false;
    if (!inputText) err.textInput = "This field is required";
    if (!err.textInput) {
      isValidForm = true;
    }

    setError(err);
    return isValidForm;
  };

  const handleSubmit = async () => {
    setError({ textInput: "" });
    const isValid = validateForm();
    if (isValid) {
      if (query === inputText) {
        setQuery(inputText + " ");
      } else {
        setQuery(inputText);
      }
      setIsLoading(true);
      const { data } = await openaiModelService.contextUpload(inputText);
      setContextId(data);
      setIsLoading(false);
    }
  };
  return (
    <>
      <div className="m-b">
        <div>
          <Tabs className="tabs" onChange={onTabChange} activeKey={selectedTab}>
            {Object.values(TABS).map((key) => (
              <TabPane tab={key} key={key} />
            ))}
          </Tabs>
        </div>
        {selectedTab === TABS.ENTER_TEXT ? (
          <>
            <p className="m-b">Enter/Copy paste text</p>
            <InputText {...{ onInputTextChange, error, inputText, rows: 3 }} />
          </>
        ) : selectedTab === TABS.UPLOAD_FILE ? (
          <>
            <OpenAiFileUploadAntd setContextId={setContextId} />
          </>
        ) : null}
      </div>
      {selectedTab === TABS.ENTER_TEXT && (
        <div className="tr">
          <Button className="outline" loading={isLoading} onClick={handleSubmit}>
            Upload Context
          </Button>
        </div>
      )}
    </>
  );
};
