import React, { useEffect, useMemo, useState } from "react";
import TasksApi from "../../../api/tasks.api";
import { Loading } from "../../../components";
import { openNotificationWithIcon } from "../../../utils";

interface Props {
  modelData: IModelDetail;
  selectedModel: string;
  query: string;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ClinicalNoteClassificationOutput = ({
  query,
  isLoading,
  setIsLoading,
  selectedModel,
  modelData,
}: Props) => {
  const [queryResponse, setQueryResponse] = useState("");
  const selectedModelCode = useMemo(
    () => modelData.models.find((model) => model.name === selectedModel)?.model_code,
    [modelData.models, selectedModel]
  );

  useEffect(() => {
    const getTask = async () => {
      try {
        if (query) {
          const { data } = await new TasksApi().classifyClinicalNote(query);
          setQueryResponse(data['results']);
        }
      } catch (e: any) {
        openNotificationWithIcon("", e.response.data.message, "error");
        setQueryResponse("");
      } finally {
        setIsLoading(false);
      }
    };
    getTask();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, setIsLoading]);

  return (
    <>
      <h1 className="m-b">Result</h1>
      <br />
      {isLoading ? (
        <Loading />
      ) : queryResponse ? (
        <>
          <div className="query-response">{queryResponse}</div>
          <div className="tr m-t">
          </div>
        </>
      ) : (
        <div>Please enter text to get response from the model.</div>
      )}
    </>
  );
};
