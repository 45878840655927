import { useCallback, useState } from "react";
import {
  EvidenceFileData,
  EvidencePageResponseInfo,
  evidenceViewerService,
  SearchOccurrence,
} from "../api";
import { labData } from "../utils/constants/LabData";

interface Props {
  fileId: string;
  searchKeywords: string | null;
}

export const useFetchEvidenceViewer = ({ fileId, searchKeywords }: Props) => {
  const [currPage, setCurrPage] = useState(1);
  const [pageLoading, setPageLoading] = useState(true);
  const [searchOccurenceLoading, setSearchOccurenceLoading] = useState(true);
  const [pageData, setPageData] = useState({} as any);
  const [fileData, setFileData] = useState({} as EvidenceFileData);
  const [occurencesList, setOccurencesList] = useState([] as SearchOccurrence[]);
  
  // const fetchFile = useCallback(async () => {
  //   setFileData({ fileName: "", status: false, url: "" });
  //   const { data } = await evidenceViewerService.getFile(fileId);
  //   if (data.fileData.status) setFileData(data.fileData);
  // }, [fileId]);

  const fetchPage = useCallback(async () => {
    // setPageLoading(true);
    // const { data } = await evidenceViewerService.getPageData(currPage, fileId);
    setPageData(labData);
    // setPageLoading(false);
  }, [currPage, fileId]);

  const fetchSearchOccurences = useCallback(
    async (tileType: string | null) => {
      setCurrPage(1);
      setSearchOccurenceLoading(true);
      const { data } = await evidenceViewerService.getOccurrencesPages(
        fileId,
        searchKeywords,
        tileType
      );
      const { occurencesList: list } = data;
      setOccurencesList(list.sort((a, b) => a.pageNumber - b.pageNumber));
      setSearchOccurenceLoading(false);
    },
    [fileId, searchKeywords]
  );

  return {
    // fetchFile,
    fetchPage,
    currPage,
    pageLoading,
    searchOccurenceLoading,
    pageData,
    fileData,
    setCurrPage,
    fetchSearchOccurences,
    occurencesList,
  };
};
