import { useCallback, useState } from "react";
import { STATUS_CODE, openNotificationWithIcon } from "../utils";
import { projectService } from "../api/project";
import { useAuthorization } from "./useAuthorization";

export const usePrompts = () => {
    const [loading, setLoading] = useState(false);
    const [prompts, setPrompts] = useState([] as PromptValue[]);
    const { setIsAuthorized } = useAuthorization();

    const fetchPrompts = useCallback(async (id: number) => {
        setLoading(true);
        try {
            const { data } = await projectService.getProjectPrompts(id);
            const promptsData: PromptValue[] = [];
            data.data.prompts.forEach((prompt) => {
                promptsData.push({
                    id: prompt.prompts.id,
                    value: prompt.prompts.value
                })

            })
            setPrompts(promptsData);
        } catch (err: any) {
            if (err.response.status === STATUS_CODE.FORBIDDEN) {
                setIsAuthorized(false);
            } else {
                openNotificationWithIcon("", err.response.data.message, "error");
            }
        } finally {
            setLoading(false);
        }
    }, [setIsAuthorized]);


    return { fetchPrompts, loading, prompts };
};
