import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const authUserInitialState: IAuthUserSlice = {
  user: { role: {} } as IAuthUser,
};

export const authUserSlice = createSlice({
  name: "user",
  initialState: authUserInitialState,
  reducers: {
    loginUser: (state: IAuthUserSlice, { payload }: PayloadAction<IAuthUser>) => {
      state.user = { ...payload, role: { ...payload.role } };
    },
    logoutUser: (state: IAuthUserSlice) => {
      state.user = { role: {} } as IAuthUser;
    },
    // updateCurrentUser: (state: UserSlice, { payload }: PayloadAction<IEditUserResponseData>) => {
    //   state.user = { ...state.user, ...payload };
    // },
  },
});

export const { loginUser, logoutUser } = authUserSlice.actions;

export default authUserSlice.reducer;
