import { IRepsonseWrapper, apiClient } from ".";

interface IGetAllProjectsResponse {
  data: { projects: IProject[] };
  status: string;
}

interface ICreateProjectResponse {
  data: IProject;
  status: string;
}

const getAll = async () => {
  const res = await apiClient.get<IGetAllProjectsResponse>("/project");
  return res;
};

const create = async (obj: IProjectCreate) => {
  const res = await apiClient.post<ICreateProjectResponse>("/project/create", obj);
  return res;
};

const update = async (obj: IProjectUpdate, projectId: number) => {
  const res = await apiClient.put(`/project/${projectId}`, obj);
  return res;
};

const getProjectPrompts = async (id: number) => {
  const res = await apiClient.get<IRepsonseWrapper<IPrompt>>(`project/prompts/${id}`);    
  return res;
};

export const projectService = {
  getAll,
  create,
  update,
  getProjectPrompts
};
