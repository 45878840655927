import { Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { EvidencePageResponseInfo, SearchOccurrence } from "../../api";
import { Loading, PdfViewer } from "../../components";
import { useUser } from "../../hooks";
import { DATA_ADMIN_PERMISSIONS } from "../../utils";
import {
  SearchResultOccurrences,
  EvidenceContent,
  FeedbackForm,
  Legends,
  CATEGORY_COLOR_MAPPING,
  KeyValueMap,
} from "./evidence-viewer-container";
import { CohortCollapseIcon } from "../../assets/icons";

const { TabPane } = Tabs;

type EnhancedViewerTabType = {
  [key: string]: string;
};

export const ENHANCED_VIEW_TABS: EnhancedViewerTabType = {
  PAGE: "AI Assisted Review",
  // OCR: "OCR Output",
  // MEDICAL_ENTITIES: "Medical Entities",
  // NORMAL_ENTITIES: "Normal Entities",
  JSON: "JSON Output",
};


interface Props {
  searchKeywords?: string | null;
  currPage: number;
  setCurrPage: React.Dispatch<React.SetStateAction<number>>;
  // setSearchKeywords: React.Dispatch<React.SetStateAction<string | null>>;
  // tileType: string | null;
  // searchResultOccurrenceLoading: boolean;
  // occurencesList: SearchOccurrence[];
  // initialSearchKeywords: string | null;
  feedBackEnabled: boolean,
  pdfViewerEnabled: boolean,
  pdfUrl: any;
  evidenceContentLoading: boolean;
  pageData: EvidencePageResponseInfo;
  pageSearchKey?: string | null;
  fileId?: string | null;
  categoriesMapping?: KeyValueMap
}

export const EvidenceViewerContainer = (props: Props) => {
  const {
    searchKeywords,
    currPage,
    setCurrPage,
    // setSearchKeywords,
    // tileType,
    // searchResultOccurrenceLoading,
    // initialSearchKeywords,
    // occurencesList,
    feedBackEnabled,
    pdfViewerEnabled,
    evidenceContentLoading,
    pdfUrl,
    pageData,
    fileId,
    pageSearchKey,
    categoriesMapping
  } = props;

  const [selectedTab, setSelectedTab] = useState(ENHANCED_VIEW_TABS.PAGE);
  const [selectedLegends, setSelectedLegends] = useState([] as string[]);
  const { roleCode } = useUser();
  const location = useLocation();
  const [isContentCollapsed, setIsContentCollapsed] = useState(true);
  const [isPdfViewerCollapsed, setIsPdfViewerCollapsed] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const insightType = queryParams.get("insight_type");
    if (insightType) {
      setSelectedLegends((prevSelectedLegends) => [...prevSelectedLegends, insightType]);
    }
  }, [location.search]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const searchTerm = queryParams.get("search");
    const categories = [] as string[];
    if (searchTerm && Object.keys(pageData).length !== 0) {
      const terms = searchTerm.toLowerCase().split(",");
      pageData.medicalEntities.forEach((item) => {
        item.Entities.forEach((entity) => {
          terms.forEach((term) => {
            if (entity.Text.toLowerCase().includes(term)) {
              categories.push(entity.Category);
            }
          });
        });
      });
    }
    const filteredCategories = [...new Set(categories)].filter((item) =>
     { 
      Object.keys(categoriesMapping?categoriesMapping:CATEGORY_COLOR_MAPPING).includes(item)}
    );
    setSelectedLegends((prevSelectedLegends) => [...prevSelectedLegends, ...filteredCategories]);
  }, [location.search, pageData.medicalEntities, pageData]);

  const onChange = (key: string) => {
    setSelectedTab(key);
  };
  return (
    <div>
      {/* {((searchKeywords && searchKeywords.length > 0) || tileType) && (
        <SearchResultOccurrences
          {...{
            currPage,
            setCurrPage,
            loading: searchResultOccurrenceLoading,
            searchKeywords,
            setSearchKeywords,
            occurencesList,
            initialSearchKeywords,
          }}
        />
      )} */}
      {!DATA_ADMIN_PERMISSIONS.includes(roleCode) && (
        <div className="tabs">
          <Tabs onChange={onChange} defaultActiveKey={selectedTab}>
            {Object.keys(ENHANCED_VIEW_TABS).map((key) => (
              <TabPane tab={ENHANCED_VIEW_TABS[key]} key={ENHANCED_VIEW_TABS[key]} />
            ))}
          </Tabs>
        </div>
      )}
      {selectedTab === ENHANCED_VIEW_TABS.PAGE  && (
        <Legends {...{ selectedLegends, setSelectedLegends, categoriesMapping }} />
      )}
      <div className={`evidence-page`} style={{ display : pdfViewerEnabled === false ? 'block' : 'grid' }}>
        <EvidenceContent
          pageInfo={pageData}
          loading={evidenceContentLoading}
          selectedTab={selectedTab}
          searchKeywords={pageSearchKey || searchKeywords || ""}
          selectedLegends={selectedLegends}
          categoriesMapping={categoriesMapping}
        />
        {pdfViewerEnabled ? (
          <PdfViewer
            pdf={pdfUrl}
            pageInfo={{ height: 700, width: 400 }}
            displayPagination
            setCurrPage={setCurrPage}
            currPage={currPage}
          />
        ) : (
          // <Loading />
          <></>
        )}
      </div>
      { feedBackEnabled ?
        (<FeedbackForm pageNo={pageData.pageNumber} fileUUID={fileId} />) : <></>
        // ({fileId ? (<FeedbackForm pageNo={pageData.pageNumber} fileUUID={fileId} />) )
      }
    </div>
  );
};
