import React from "react";
import { splitData } from "../constants";

interface Props {
  splitSummary: any;
}

export const DocumentInsightsView = (props: Props) => {
  const { splitSummary } = props;
  const consolidatedInfo = {
    meta_info: {
      buyer_name: "",
      seller_name: "",
      property_address: {
        address: "",
        city: "",
        state: "",
        zip: "",
      },
    },
  };

  const filterEmptyValues = (obj: Record<string, string | number>) => {
    return Object.fromEntries(Object.entries(obj).filter(([_, value]) => value !== ""));
  };

  splitSummary?.forEach((page: any) => {
    if (page.meta_info.buyer_name && !consolidatedInfo.meta_info.buyer_name) {
      consolidatedInfo.meta_info.buyer_name = page.meta_info.buyer_name;
    }
    if (page.meta_info.seller_name && !consolidatedInfo.meta_info.seller_name) {
      consolidatedInfo.meta_info.seller_name = page.meta_info.seller_name;
    }
    if (page.meta_info.property_address) {
      Object.entries(page.meta_info.property_address).forEach(([key, value]) => {
        if (
          value &&
          !consolidatedInfo.meta_info.property_address[
            key as keyof typeof consolidatedInfo.meta_info.property_address
          ]
        ) {
          consolidatedInfo.meta_info.property_address[
            key as keyof typeof consolidatedInfo.meta_info.property_address
          ] = value as string;
        }
      });
    }
  });

  const propertyAddressValues = filterEmptyValues(consolidatedInfo.meta_info.property_address);

  return (
    <div>
      {consolidatedInfo.meta_info.buyer_name && (
        <p>Buyer Name: {consolidatedInfo.meta_info.buyer_name}</p>
      )}
      {consolidatedInfo.meta_info.seller_name && (
        <p>Seller Name: {consolidatedInfo.meta_info.seller_name}</p>
      )}
      {Object.keys(propertyAddressValues).length > 0 && (
        <div>
          <p className="boldUnderline">Property Address:</p>
          {Object.entries(propertyAddressValues).map(([key, value]) => (
            <p key={key}>
              {key.charAt(0).toUpperCase() + key.slice(1)}: {value}
            </p>
          ))}
        </div>
      )}
    </div>
  );
};
