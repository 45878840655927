import React from "react";
import {
  ArrowIcon,
  BMIIcon,
  ChatIcon,
  CohortIcon,
  ECIcon,
  HighRiskIcon,
  HomeIcon,
  MailIcon,
  PAIcon,
  PLOAIcon,
  PPAIcon,
  PriorAuthoIcon,
} from "../../assets/icons";
import { ChartReviewIcon } from "../../assets/icons/ChartReviewIcon";

export interface IApplication {
  id: number;
  link: string;
  icon: JSX.Element;
  title: string;
  description: string;
  isActive: boolean;
}

export const COPILOTS_NAME = {
  CAREGPT_CHATBOT: "Payment Integrity Chat BOT",
  HEDIS_INSIGHTS: "HEDIS Insights",
  PEDA_PRIOR_AUTH: "PEGA Prior Auth",
  DOCUMENT_INSIGHTS: "Document Insights"
}

export const APPLICATIONS: IApplication[] = [
  {
    id: 1,
    title: "Document Insights",
    link: "/document-insights",
    icon: <CohortIcon width={50} height={50} fill="#3232A6" />,
    description:
      "AI-driven document analysis to streamline decision-making and enhance productivity",
    isActive: true,
  },
  {
    id: 1,
    title: "Wizard - AI Assisted Chart Review",
    link: "",
    icon: <ChartReviewIcon />,
    description:
      "Summarize and accelerate chart reviews of patient and members and contextualize to task",
    isActive: true,
  },
  {
    id: 2,
    title: "Patient Level Outcome Analysis",
    link: "",
    icon: <PLOAIcon />,
    description: "Analyze and predict outcomes from historic data, and use",
    isActive: true,
  },
  {
    id: 3,
    title: "Pixel  - AI Powered Patient Matching",
    link: "",
    icon: <PriorAuthoIcon />,
    description:
      "Match patients to Clinical Trials Eligibility Criteria using powerful Clinical LLMs",
    isActive: true,
  },
  {
    id: 4,
    title: "Analysis of High Risk population",
    link: "",
    icon: <HighRiskIcon />,
    description: "Identify and Stratify risk from clinical, financial and other data sources",
    isActive: true,
  },
  {
    id: 5,
    title: "Clinical Trial Protocol Insights Digitization",
    link: "",
    icon: <ECIcon />,
    description:
      "Analyze & simplify IE criteria and understand burden to patient, physician and study timelines",
    isActive: false,
  },
  {
    id: 6,
    title: "Protocol Analysis",
    link: "",
    icon: <PAIcon />,
    description: "Therapeutic area insights from publicly available and private data sources",
    isActive: false,
  },
  {
    id: 7,
    title: "Clinical Trial Patient Engagement Co-pilot",
    link: "",
    icon: <PPAIcon />,
    description:
      "Contextual Conversational bot for patient engagement to accelerate pre-screening and enrollment",
    isActive: false,
  },
  {
    id: 8,
    title: "BioMedical Insights",
    link: "",
    icon: <BMIIcon />,
    description: "Therapeutic area insights from publicly available and private data sources",
    isActive: false,
  },
];

export const Copilots: IApplication[] = [
  {
    id: 1,
    title: COPILOTS_NAME.CAREGPT_CHATBOT,
    link: "",
    icon: <ChatIcon width={60} height={60} fill="#270067"/>,
    description:
      "Multi document Q & A BOT for Payment Integrity team",
    isActive: true,
  },
  {
    id: 2,
    title: COPILOTS_NAME.HEDIS_INSIGHTS,
    link: "",
    icon: <ECIcon />,
    description: "Deriving HEDIS insights from clinical data",
    isActive: true,
  },
  {
    id: 3,
    title: COPILOTS_NAME.PEDA_PRIOR_AUTH,
    link: "",
    icon: <PriorAuthoIcon />,
    description:
      "Automating Prior Auth case creation from incoming faxes",
    isActive: true,
  },
  {
    id: 4,
    title: COPILOTS_NAME.DOCUMENT_INSIGHTS,
    link: "",
    icon: <CohortIcon width={60} height={60} fill="#3232A6"/>,
    description:
      "",
    isActive: true,
  }
];