import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUser } from "../../interfaces/user";

interface IUserApprovalSlice {
  user: IUser;
  isUserApproved: boolean;
}

export const userApprovalInitialState: IUserApprovalSlice = {
  user: {} as IUser,
  isUserApproved: false,
};

export const ApprovalSlice = createSlice({
  name: "userApproval",
  initialState: userApprovalInitialState,
  reducers: {
    setUserForApproval: (state: IUserApprovalSlice, { payload }: PayloadAction<IUser>) => {
      state.user = payload;
    },
    setIsUserApproved: (state: IUserApprovalSlice, { payload }: PayloadAction<boolean>) => {
      state.isUserApproved = payload;
    },
    resetUserApproval: (state: IUserApprovalSlice) => {
      state.user = userApprovalInitialState.user;
    },
  },
});

export const { resetUserApproval, setIsUserApproved, setUserForApproval } = ApprovalSlice.actions;
export default ApprovalSlice.reducer;
