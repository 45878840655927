import { combineReducers } from "redux";

import uiReducer, { uiInitialState } from "./ui/slice";
import authUserReducer, { authUserInitialState } from "./auth-user/slice";
import projectReducer, { projectSliceInitialState } from "./project/slice";
import userApprovalReducer, { userApprovalInitialState } from "./user-approval/slice";
import userReducer, { userInitialState } from "./user/slice";
import feedbacKReducer, { feedbackInitialState } from "./feedback/slice";
import QuesAnsReducer, { quesAnsInitialState } from "./question-answer/slice";

export const initialState = {
  ui: uiInitialState,
  authUser: authUserInitialState,
  project: projectSliceInitialState,
  quesAns: quesAnsInitialState,
  feedback: feedbackInitialState,
  userApproval: userApprovalInitialState,
  user: userInitialState,
};

export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    ui: uiReducer,
    user: userReducer,
    authUser: authUserReducer,
    project: projectReducer,
    quesAns: QuesAnsReducer,
    feedback: feedbacKReducer,
    userApproval: userApprovalReducer,
    ...injectedReducers,
  });

  return rootReducer;
}
