import React, { useEffect, useState } from "react";
import { Loading } from "../../../components";
import { Button } from "antd";
import { HemophiliaQuestionAnsweringChat } from "./HemophiliaQuestionAnsweringChat";

interface Props {
  modelData: IModelDetail;
  selectedModel: string;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const HemophiliaQuestionAnsweringOutput = ({
  isLoading,
  setIsLoading,
  selectedModel,
  modelData,
}: Props) => {
  const [quesAns, setQuesAns] = useState([] as IQuesAns[]);

  useEffect(() => {
    setIsLoading(true);
    setQuesAns([]);
    setIsLoading(false);
  }, [selectedModel, setIsLoading]);

  return (
    <>
      <h1 className="m-b">Results</h1>
      {isLoading ? (
        <Loading />
      ) : (
        <HemophiliaQuestionAnsweringChat {...{ quesAns, setQuesAns, selectedModel, modelData }} />
      )}
      <div className="tr m-t flex jce gp">
        <Button className="fill">Create API Endpoint</Button>
        <Button className="fill">Generate API Key</Button>
      </div>
    </>
  );
};
