import React from "react";
import { Button, Input } from "antd";
import { ProjectTile } from "./projects-list-container";
import { SearchIcon } from "../../assets/icons";
import { useDispatch, useSelector } from "react-redux";
import { openModal, RootState } from "../../store";
import { MODAL_TYPE, PAGE_URL } from "../../utils";
import { useNavigate } from "react-router-dom";

const { Search } = Input;

export const ProjectsListContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { list: projectsList } = useSelector((state: RootState) => state.project);

  const onCreateProjectClick = () => {
    dispatch(openModal(MODAL_TYPE.ADD_EDIT_PROJECT));
  };

  return (
    <>
      <div className="flex jcsb aic ">
        <Search
          className="record-search"
          placeholder="Search projects"
          style={{ width: "300px" }}
        />
        <Button className="fill" onClick={onCreateProjectClick}>
          Create Project
        </Button>
      </div>
      <div className="grid-View m-t">
        {projectsList.map((project, index) => (
          <ProjectTile
            key={`project-tile-${index}`}
            project={project}
            onClickHandler={() => {
              navigate(`${PAGE_URL.PROJECT_DETAIL}/${project.id}`);
            }}
          />
        ))}
      </div>
    </>
  );
};
