import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { tasksService } from "../../../api";
import { openNotificationWithIcon } from "../../../utils";
import { Dropdown } from "../../../components";

interface Props {
  subjectError: string;
  selectedSubjectID: string;
  setSubjectError: React.Dispatch<React.SetStateAction<string>>;
  setSelectedSubjectId: React.Dispatch<React.SetStateAction<string>>;
}

export const DocumentQuestionAnsweringDropdown = ({
  setSelectedSubjectId,
  subjectError,
  selectedSubjectID,
  setSubjectError,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [subjects, setSubjects] = useState([] as string[]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchExistingSubjects = async () => {
      setIsLoading(true);
      try {
        const { data } = await tasksService.getDocumentQuestionAnsweringSubjects();
        setSubjects(data.data.existing_subjects);
      } catch (e: any) {
        openNotificationWithIcon("", e.response.data.message, "error");
        navigate(-1);
      } finally {
        setIsLoading(false);
      }
    };
    fetchExistingSubjects();
  }, [navigate]);

  const handleSubjectSelect = (option: IDropdownOption) => {
    setSelectedSubjectId(option.value);
    setSubjectError("");
  };

  return (
    <div className="flex gp aic m-b">
      <h3>Please select a Subject ID</h3>
      <Dropdown
        options={subjects?.map((subject, index) => ({ value: subject, id: index }))}
        loading={isLoading}
        onSelect={(e, option) => handleSubjectSelect(option)}
        value={selectedSubjectID}
        style={{ width: 210 }}
      />
      {subjectError && <p className="error">{subjectError}</p>}
    </div>
  );
};
