import { apiClient } from "./apiClient";

import clinicalnoteclassificationmodel from './clinicalnoteclassificationmodel.json';
import clinicalnormalizationmodel from './clinicalentitynormalizationmodel.json';
import clinicalextractionmodel from './clinicalentityextractionmodel.json';
import cmsformextractionmodel from './cmsformextractionmodel.json';
import laberesultsextractionmodel from './labresultsextractionmodel.json';
import phiredactionmodel from './phiredactionmodel.json';
import protocolsummarymodel from './protocolsummarymodel.json';
import protocolicecsimplificationmodel from './protocolicecsimplificationmodel.json';

interface IModelDetailResponse {
  data: IModelDetail;
  status: string;
}

const getRelationExtractionModelsInfo = async () => {
  const res = await apiClient.get<IModelDetailResponse>(`/models/re/info`);
  return res;
};

const getAutocompleteModelsInfo = async () => {
  const res = await apiClient.get<IModelDetailResponse>(`/models/auto-complete/info`);
  return res;
};

const getDocumentQuestionAnsweringModelsInfo = async () => {
  const res = await apiClient.get<IModelDetailResponse>(`/models/document-qa/info`);
  return res;
};

const getGenerativeQaModelsInfo = async () => {
  const res = await apiClient.get<IModelDetailResponse>(`/models/generative-qa/info`);
  return res;
};

const getHemophiliaQAModelsInfo = async () => {
  const res = await apiClient.get<IModelDetailResponse>(`/models/hemophilia-qa/info`);
  return res;
};

const getLabResultsModelsInfo = async () => {
  const response:IModelDetailResponse = laberesultsextractionmodel;
  return response;
};

const getCMSFormExtractionModelsInfo = async () => {
  const response:IModelDetailResponse = cmsformextractionmodel;
  return response;
};

const getClinicalNoteClassificationModelsInfo = async () => {
  const response:IModelDetailResponse = clinicalnoteclassificationmodel;
  return response;
};

const getClinicalExtractionModelsInfo = async () => {
  const response:IModelDetailResponse = clinicalextractionmodel;
  return response;
};

const getClinicalNormalizationModelsInfo = async () => {
  const response:IModelDetailResponse = clinicalnormalizationmodel;
  return response;
};

const getPHIRedactionModelsInfo = async () => {
  const response:IModelDetailResponse = phiredactionmodel;
  return response;
};

const getProtocolSummaryModelsInfo = async () => {
  const response:IModelDetailResponse = protocolsummarymodel;
  return response;
};

const getProtocolICECSimplificationModelsInfo = async () => {
  const response:IModelDetailResponse = protocolicecsimplificationmodel;
  return response;
};

export const modelDetailService = {
  getRelationExtractionModelsInfo,
  getAutocompleteModelsInfo,
  getDocumentQuestionAnsweringModelsInfo,
  getGenerativeQaModelsInfo,
  getClinicalNoteClassificationModelsInfo,
  getClinicalExtractionModelsInfo,
  getClinicalNormalizationModelsInfo,
  getCMSFormExtractionModelsInfo,
  getHemophiliaQAModelsInfo,
  getLabResultsModelsInfo,
  getPHIRedactionModelsInfo,
  getProtocolSummaryModelsInfo,
  getProtocolICECSimplificationModelsInfo
};
