export const KNOWLEDGE_MANAGEMENT_TASKS: ITask[] = [
  {
    title: "Create knowledge graph triplets from text",
    description: "Turn entities and relations into knowledge triplets (transformation)",
    link: "",
  },
  {
    title: "Create dynamic knowledge graph",
    description: "Create dynamic knowledge graph of entities of interest as specified",
    link: "",
  },
  {
    title: "Knowledge Chat-LLM",
    description:
      "Turn organization data stores and documents into conversable agents for knowledge mining",
    link: "",
  },
  {
    title: "Voice/ASR Search",
    description: "Allows to search thorugh voice input.",
    link: "",
  },
];
