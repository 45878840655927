import React from "react";
import { Avatar, Card, Tooltip } from "antd";
import { ProjectActions } from "./ProjectActions";
import "../../../assets/icons/ActiveCheckIcon";
import { ActiveCheckIcon } from "../../../assets/icons/ActiveCheckIcon";
import {UserPlaceholder} from "../../../assets/images";

interface Props {
  project: IProject;
  onClickHandler: () => void;
}

export const ProjectTile = ({ project, onClickHandler }: Props) => {
  const projectDescription =
    project.description.slice(0, -4) + (project.description.length > 300 ? "..." : "");
  return (
    <Card className="project-card-view p-20">
      <div className="flex jcsb">
        <p className="project-name">{project.name}</p>
        <ProjectActions record={project} displayEdit/>
      </div>
      <div className="cursor-pointer" onClick={onClickHandler}>
        <h5>Project Description</h5>
        <p className="m-b" dangerouslySetInnerHTML={{ __html: projectDescription }} />
      </div>
      <div className="flex jcsb aic margin-top-auto">
            <Avatar.Group>
              {project.collabarators.map((collabarator, index) => (
                <Tooltip key={index} title={collabarator.name} placement="top">
                  <Avatar src={ UserPlaceholder }/>
                </Tooltip>
              ))}
            </Avatar.Group>
          <div className="tr">
            <span className="highlight">Last Accessed </span>
            <p className="flex aic jce gp-10 active-check">
              <ActiveCheckIcon />
              {project.isActive ? "Active" : "Archived"}</p>
          </div>
        </div>
    </Card>
  );
};
