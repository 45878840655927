import React from "react";
import { AppSearch } from "../../components";
import { TEXT } from "../../utils";

const SearchContainer = () => {
  return (
    <>
      <div className="flex jcc p-20">
        <div>
          <AppSearch
            inputProps={{
              placeholder: TEXT.search.placeholder,
            }}
            searchProps={{ onSearch: () => {}, onValueChange: () => {} }}
          />
          <p className="p-block">
            Try: <a>Hemophillia</a> / <a>Creatine</a> / <a>Breast Cancer</a>
          </p>
        </div>
      </div>
    </>
  );
};

export default SearchContainer;
