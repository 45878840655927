import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { projects } from "./projectList";

export const projectSliceInitialState: IProjectSlice = {
  id: -1,
  isEdit: false,
  list: projects,
};

export const projectSlice = createSlice({
  name: "projectSlice",
  initialState: projectSliceInitialState,
  reducers: {
    setProjects: (state: IProjectSlice, { payload }: PayloadAction<IProject[]>) => {
      state.list = [...payload];
    },
    addProject: (state: IProjectSlice, { payload }: PayloadAction<IProject>) => {
      state.list = [...state.list, { ...payload }];
    },
    updateProject: (
      state: IProjectSlice,
      { payload }: PayloadAction<{ obj: Partial<IProject>; id: number }>
    ) => {
      state.list = state.list.map((item) => {
        if (item.id === payload.id) return { ...item, ...payload.obj };
        else return { ...item };
      });
    },
    deleteProject: (state: IProjectSlice, { payload }: PayloadAction<number>) => {
      const index = state.list.findIndex((item) => item.id === payload);
      if (index !== -1)
        state.list = [...state.list.slice(0, index), ...state.list.slice(index + 1)];
    },
    setProjectEditDelete: (
      state: IProjectSlice,
      { payload }: PayloadAction<{ id: number; isEdit: boolean }>
    ) => {
      state.isEdit = payload.isEdit;
      state.id = payload.id;
    },
  },
});

export const { setProjects, addProject, updateProject, deleteProject, setProjectEditDelete } =
  projectSlice.actions;

export default projectSlice.reducer;
