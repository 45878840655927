import { Input } from "antd";
import React from "react";
import { TaskErrorState } from './TaskErrorState';

const { TextArea } = Input;


interface Props {
  onInputTextChange: (e: any) => void;
  error: TaskErrorState;
  inputText: string;
  rows?: number;
}

export const TaskRunningText = ({ onInputTextChange, error, inputText, rows }: Props) => {
  return (
    <>
      <TextArea
        rows={rows ? rows : 8}
        placeholder="Enter text..."
        onChange={(e) => onInputTextChange(e)}
        value={inputText}
      />
      {error.textInput && <p className="error">{error.textInput}</p>}
    </>
  );
};
