import React from "react";
import { IconsProps } from "./Icons";

export const ArrowIcon = ({ className, fill, width, height, viewBox }: IconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox={viewBox ? viewBox : "0 0 10 14"}
      width={width ? width : "10"}
      height={height ? height : "14"}
      fill={fill ? fill : "currentColor"}
    >
      <path d="M8.35294 1L2 7.26866L7.8084 13" fill="none" stroke="white" strokeWidth="2"/>

    </svg>
  );
};
