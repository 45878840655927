import React from "react";
import { Disclaimer, ModelDropdownAndDescription, TaskDescription } from "../../components";
import { isEmptyObject } from "../../utils";

interface Props {
  selectedModel: string;
  setSelectedModel: React.Dispatch<React.SetStateAction<string>>;
  modelData: IModelDetail;
}

export const ClinicalProtocolICECSummaryAside = ({ selectedModel, setSelectedModel, modelData }: Props) => {
  return !isEmptyObject(modelData) ? (
    <div>
      <ModelDropdownAndDescription {...{ modelData, selectedModel, setSelectedModel }} />
      <TaskDescription {...{ modelData, selectedModel }} />
      {/* <Disclaimer /> */}
    </div>
  ) : (
    <></>
  );
};
