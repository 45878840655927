import React from "react";
import { isEmptyObject } from "../../utils";
import { ModelDropdownAndDescription, TaskDescription } from "../../components";

interface Props {
  selectedModel: string;
  setSelectedModel: React.Dispatch<React.SetStateAction<string>>;
  modelData: IModelDetail;
}

export const DocumentQuestionAnsweringAside = ({
  selectedModel,
  setSelectedModel,
  modelData,
}: Props) => {
  return !isEmptyObject(modelData) ? (
    <div>
      <ModelDropdownAndDescription {...{ modelData, selectedModel, setSelectedModel }} />
      <TaskDescription {...{ modelData, selectedModel }} />
    </div>
  ) : (
    <></>
  );
};
