import { Tabs } from "antd";
import React from "react";
import { PROJECT_DETAILS_TABS } from "..";
import "../../../assets/sass/_tabs.scss";
const { TabPane } = Tabs;

interface Props {
  onChange: (key: string) => void;
  selectedTab: string;
}

const ProjectDetailsTab = ({ onChange, selectedTab }: Props) => {
  return (
      <div className="tabs">
        <Tabs onChange={onChange} activeKey={selectedTab}>
          {Object.values(PROJECT_DETAILS_TABS).map((key) => (
            <TabPane tab={key} key={key} />
          ))}
        </Tabs>
    </div>
  );
};

export default ProjectDetailsTab;
