import React, { memo } from "react";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";

interface Props {
  internalFile: File;
  internalNumPages: number | null;
  containerRef: React.MutableRefObject<HTMLDivElement | null>;
  startPage: number;
  endPage: number;
  onDocumentLoadSuccess: ({ numPages }: { numPages: number }) => void;
  setPageNumber: React.Dispatch<React.SetStateAction<number>>;
  pageNumber: number;
}

export const PdfViewer = memo((props: Props) => {
  const {
    internalNumPages,
    containerRef,
    startPage,
    endPage,
    onDocumentLoadSuccess,
    internalFile,
    setPageNumber,
    pageNumber,
  } = props;

  const handlePreviousPage = () => {
    setPageNumber((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setPageNumber((prevPage) => Math.min(prevPage + 1, internalNumPages || 1));
  };

  return (
    <div className="pdf-viewer" ref={containerRef}>
      <div style={{ marginBottom: "10px", display: "flex", justifyContent: "center" }}>
        <Button
          icon={<LeftOutlined />}
          onClick={handlePreviousPage}
          disabled={pageNumber === 1 || pageNumber === startPage}
        >
          Previous Page
        </Button>
        <span style={{ margin: "7px 10px" }}>
          {startPage !== 0 && endPage !== 0
            ? `Page ${pageNumber} of ${endPage}`
            : `Page ${pageNumber} of ${internalNumPages}`}
          {}
        </span>
        <Button
          icon={<RightOutlined />}
          onClick={handleNextPage}
          disabled={pageNumber === (internalNumPages || 1) || pageNumber === endPage}
        >
          Next Page
        </Button>
      </div>
      <div style={{ maxHeight: "calc(100vh - 290px)", overflow: "auto" }}>
        <Document file={URL.createObjectURL(internalFile)} onLoadSuccess={onDocumentLoadSuccess}>
          <Page
            pageNumber={pageNumber}
            height={100}
            width={containerRef.current ? containerRef.current.clientWidth : undefined}
          />
        </Document>
      </div>
    </div>
  );
});

PdfViewer.displayName = "PdfViewer";
