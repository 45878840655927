import React from "react";
import { IGenerativeQaResponse } from "../../../api";

interface Props {
  generativeQAResponse: IGenerativeQaResponse;
}

export const GenerativeQaModelResponse = ({ generativeQAResponse }: Props) => {
  return (
    <div>
      {Object.keys(generativeQAResponse).map((key, index) => {
        if (key === "query") {
          return null;
        } else {
          return (
            <div key={key}>
              <h4 className="m-b">Results for Model {index + 1}:</h4>
              {Array.isArray(generativeQAResponse[key]) ? (
                generativeQAResponse[key].length !== 0 && (
                  <ul className="list-style">
                    {generativeQAResponse[key].map((item, i) => (
                      <li key={i}>{item}</li>
                    ))}
                  </ul>
                )
              ) : (
                <p>{generativeQAResponse[key]}</p>
              )}
              <br />
            </div>
          );
        }
      })}
    </div>
  );
};
