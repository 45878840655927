import React, { useCallback, useMemo, useState } from "react";
import { isEmptyObject, openNotificationWithIcon } from "../../utils";
import { AskAQuestion } from "../../components";
import { tasksService } from "../../api";
import { Button } from "antd";

export const DOCUMENT_QA_PAYMENT_INTEGRITY_TABS = {
  UPLOAD_FILE: "Upload File",
  ENTER_TEXT: "Enter Text",
};

interface Props {
  modelData: IModelDetail;
  selectedModel: string;
}

export const DocumentQaPaymentIntegrityContainer = ({ modelData, selectedModel }: Props) => {
  const [inputValue, setInputValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [quesAns, setQuesAns] = useState([] as IQuesAns[]);
  const selectedModelCode = useMemo(
    () => modelData.models.find((model) => model.name === selectedModel)?.model_code,
    [modelData.models, selectedModel]
  );

  const handleSearchSubmit = useCallback(
    async (e: string) => {
      if (e.trim().length > 0) {
        setIsLoading(true);
        try {
          const { data } = await tasksService.getDocumentQaPaymentIntegrity(
            selectedModelCode as string,
            {
              q: e.trim(),
            }
          );
          setQuesAns((prev) => [...prev, data.data]);
          setInputValue("");
        } catch (error: any) {
          openNotificationWithIcon("", error.response.data.message, "error");
        } finally {
          setIsLoading(false);
        }
      }
    },
    [selectedModelCode]
  );

  return !isEmptyObject(modelData) ? (
    <>
      <AskAQuestion {...{ handleSearchSubmit, quesAns, inputValue, setInputValue, isLoading }} />
      <div className="tr m-t flex jce gp">
        <Button className="fill">Create API Endpoint</Button>
        <Button className="fill">Generate API Key</Button>
      </div>
    </>
  ) : (
    <></>
  );
};
