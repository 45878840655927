import React from "react";
import { IconsProps } from "./Icons";

export const PAIcon = ({ className, fill, width, height, viewBox, onClick }: IconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox={viewBox ? viewBox : "0 0 42 42"}
      width={width ? width : "42"}
      height={height ? height : "42"}
      fill={fill ? fill : "none"}
      onClick={onClick}
    >
      <path d="M20.8 0C28.1242 0 32.279 4.9998 32.8822 11.0396H33.0642C37.0552 11.0396 40.4066 13.8554 41.34 17.6618C40.8978 17.3917 40.4364 17.1544 39.9594 16.952C37.9184 16.0784 35.2794 15.6 32.5 15.6C29.7206 15.6 27.0816 16.0784 25.0406 16.952C24.0266 17.3888 23.0386 17.9738 22.2638 18.7564C21.4838 19.5494 20.8 20.6804 20.8 22.1V28.6H8.5332C3.8194 28.6 0 24.6688 0 19.8198C0 14.9708 3.822 11.0396 8.5332 11.0396H8.7178C9.3262 4.9608 13.4758 0 20.8 0ZM40.2246 29.3358C38.1186 30.537 35.3808 31.2 32.5 31.2C29.6192 31.2 26.884 30.537 24.7754 29.3358C24.2917 29.0597 23.8316 28.744 23.4 28.392V36.4C23.4 39.273 27.4742 41.6 32.5 41.6C37.5258 41.6 41.6 39.273 41.6 36.4V28.392C41.1684 28.744 40.7083 29.0597 40.2246 29.3358ZM32.5 18.2C27.4742 18.2 23.4 20.527 23.4 23.4C23.4 26.273 27.4742 28.6 32.5 28.6C37.5258 28.6 41.6 26.273 41.6 23.4C41.6 20.527 37.5258 18.2 32.5 18.2Z" fill="url(#paint0_linear_7241_5228)"/>
      <linearGradient id="paint0_linear_7241_5228" x1="20.8" y1="0" x2="20.8" y2="41.6" gradientUnits="userSpaceOnUse">
      <stop stopColor="#3232A6"/>
      <stop offset="1" stopColor="#8028BF"/>
      </linearGradient>
</svg>
  );
};
