import React, { useEffect, useMemo, useState } from "react";
import { Button } from "antd";
import { tasksService } from "../../../api";
import { Loading } from "../../../components";
import { openNotificationWithIcon } from "../../../utils";
import { PageWrapper } from '../../component-wrappers';
import { EvideceViewerAside, EvidenceViewerContainer } from '../../evidence-viewer';
import { EvidencePageResponseInfo } from "../../../api/evidenceViewer"
import TasksApi from "../../../api/tasks.api";

const data = {
  "statusCode": 200,
  "body": {
      "results": [{
          "patient": {},
          "pageNumber": 1,
          "heading": "",
          "content": "The patient presented with a persistent cough, fever, and shortness of breath. After a thorough examination, it was diagnosed as pneumonia. Treatment included antibiotics such as amoxicillin and ibuprofen for symptom relief.",
          "medicalEntities": [
              {
                  "UnmappedAttributes": [],
                  "ModelVersion": [],
                  "Entities": [
                      {
                          "Category": "MEDICAL_CONDITION",
                          "Type": "DX_NAME",
                          "Text": "cough",
                          "BeginOffset": 40,
                          "EndOffset": 45,
                          "Score": 0.9994519352912903,
                          "Traits": [
                              {
                                  "Name": "SYMPTOM",
                                  "Score": 0.9996867179870605
                              }
                          ],
                          "Id": 1
                      },
                      {
                          "Category": "MEDICAL_CONDITION",
                          "Type": "DX_NAME",
                          "Text": "fever",
                          "BeginOffset": 47,
                          "EndOffset": 52,
                          "Score": 0.999636173248291,
                          "Traits": [
                              {
                                  "Name": "SYMPTOM",
                                  "Score": 0.999738872051239
                              }
                          ],
                          "Id": 2
                      },
                      {
                          "Category": "MEDICAL_CONDITION",
                          "Type": "DX_NAME",
                          "Text": "shortness of breath",
                          "BeginOffset": 58,
                          "EndOffset": 77,
                          "Score": 0.9997338652610779,
                          "Traits": [
                              {
                                  "Name": "SYMPTOM",
                                  "Score": 0.9997981190681458
                              }
                          ],
                          "Id": 3
                      },
                      {
                          "Category": "MEDICAL_CONDITION",
                          "Type": "DX_NAME",
                          "Text": "pneumonia",
                          "BeginOffset": 129,
                          "EndOffset": 138,
                          "Score": 0.9935005307197571,
                          "Traits": [
                              {
                                  "Name": "DIAGNOSIS",
                                  "Score": 0.9988248944282532
                              }
                          ],
                          "Id": 4
                      },
                      {
                          "Category": "MEDICATION",
                          "Type": "GENERIC_NAME",
                          "Text": "amoxicillin",
                          "BeginOffset": 179,
                          "EndOffset": 190,
                          "Score": 0.9842441082000732,
                          "Traits": [],
                          "Id": 5
                      },
                      {
                          "Category": "MEDICATION",
                          "Type": "GENERIC_NAME",
                          "Text": "ibuprofen",
                          "BeginOffset": 195,
                          "EndOffset": 204,
                          "Score": 0.9026532769203186,
                          "Traits": [],
                          "Id": 6
                      },
                      {
                          "Category": "MEDICAL_CONDITION",
                          "Type": "DX_NAME",
                          "Text": "symptom relief",
                          "BeginOffset": 209,
                          "EndOffset": 223,
                          "Score": 0.9936623573303223,
                          "Traits": [],
                          "Id": 7
                      },
                      {
                          "Category": "TEST_TREATMENT_PROCEDURE",
                          "Type": "TREATMENT_NAME",
                          "Text": "antibiotics",
                          "BeginOffset": 159,
                          "EndOffset": 170,
                          "Score": 0.9998651742935181,
                          "Traits": [],
                          "Id": 8
                      }
                  ]
              }
          ],
          "form": [],
          "normalEntities": {
              "ResultList": [
                  {
                      "Entities": []
                  },
                  {
                      "Index": 0,
                      "Entities": []
                  }
              ]
          },
          "threshold_confidence_score": [],
          "ResponseMetadata": {
              "HTTPHeaders": {
                  "content-length": "5637",
                  "content-type": "application/x-amz-json-1.1",
                  "date": "Wed, 12 Apr 2023 16:35:28 GMT",
                  "x-amzn-requestid": "33fbc574-31ef-4a0b-9215-d092276a005a"
              },
              "HTTPStatusCode": 200,
              "RequestId": "33fbc574-31ef-4a0b-9215-d092276a005a",
              "RetryAttempts": 0
          }
      }]
  }
}
interface Props {
  modelData: IModelDetail;
  selectedModel: string;
  query: string;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const EntityExtractionOutput = ({
  query,
  isLoading,
  setIsLoading,
  selectedModel,
  modelData,
}: Props) => {


  const [queryResponse, setQueryResponse] = useState("");
  const selectedModelCode = useMemo(
    () => modelData.models.find((model) => model.name === selectedModel)?.model_code,
    [modelData.models, selectedModel]
  );
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [currPage, setCurrPage] = useState(1);
  //const [receivedLambdaResponse, setReceivedLambdaResponse] = useState<EvidencePageResponseInfo[] | null>(null);
  const [evidenceContentLoading, setEvidenceContentLoading] = useState(false);
  const [pdfUrl, setPdfUrl] = useState<any | ''>('');
  const [receivedLambdaResponse, setReceivedLambdaResponse] = useState<EvidencePageResponseInfo[] | null>(null);



  useEffect(() => {
    const getRelation = async () => {
      try {
        if (query) {
          const response = await new TasksApi().entityExtraction(query);
          setReceivedLambdaResponse(response.data.results as unknown as EvidencePageResponseInfo[]);
        }
      } catch (e: any) {
        openNotificationWithIcon("", e.response.data.message, "error");
        setQueryResponse("");
      } finally {
        setIsLoading(false);
      }
    };
    getRelation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, setIsLoading]);

  console.log("Lambda",data.body.results)
  console.log("receivedLambdaResponse", receivedLambdaResponse)
  return (
    <>
      <h1 className="m-b">Result</h1>
      <br />
{
  console.log("Recieved", receivedLambdaResponse)
}
      <div className="tr m-t flex jce gp">
      {receivedLambdaResponse ? (<PageWrapper
                pageContainerChildren={
                    <EvidenceViewerContainer
                        {...{
                            currPage,
                            feedBackEnabled: false,
                            pdfViewerEnabled: false,
                            evidenceContentLoading: isLoading || evidenceContentLoading,
                            pageData: receivedLambdaResponse[currPage - 1],
                            pdfUrl,
                            setCurrPage,
                        }}
                    />
                }
            />) :
                (<div></div>)}
      </div>
    </>
  );
};
