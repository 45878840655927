import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { modelDetailService } from "../api/model";
import { PageWrapper } from "../containers";
import { openNotificationWithIcon } from "../utils";
import { ClinicalNoteClassificationAside, ClinicalNoteClassificationContainer } from "../containers/clinical-data";

const ClinicalNoteClassification = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [modelData, setModelData] = useState({} as IModelDetail);
  const [selectedModel, setSelectedModel] = useState("");

  useEffect(() => {
    const fetchRelationExtractionModelsInfo = async () => {
      setIsLoading(true);
      try {
        const { data } = await modelDetailService.getClinicalNoteClassificationModelsInfo();
        setModelData(data);
        setSelectedModel(data.models[0].name);
      } catch (e: any) {
        openNotificationWithIcon("", e.response.data.message, "error");
        navigate(-1);
      } finally {
        setIsLoading(false);
      }
    };
    fetchRelationExtractionModelsInfo();
  }, [navigate]);

  return (
    <PageWrapper
      loading={isLoading}
      pageLeftAsideChildren={
        <ClinicalNoteClassificationAside {...{ selectedModel, setSelectedModel, modelData }} />
      }
      pageContainerChildren={<ClinicalNoteClassificationContainer {...{ modelData, selectedModel }} />}
    />
  );
};

export default ClinicalNoteClassification;
