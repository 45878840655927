import { datasets } from "../store/dummyData";
import { apiClient } from "./apiClient";

interface IGetAllDatasetsResponse {
  data: IDataset[];
  status: string;
}

const getOne = async (fileName: string) => {
  const res = await apiClient.post<ISignedUrlReadResponse>("/signedUrl/read", { fileName });
  return res;
};

const getAll = async () => {
  //   const res = await eligibilityApiClient.get<IGetAllDatasetsResponse>("/project");
  const data: IDataset[] = await Promise.all(
    datasets.map(async (item) => {
      const res = await getOne(item.name);
      return { ...item, url: res.data.data.signedUrl };
    })
  );
  datasets.forEach(async (item) => ({
    ...item,
    url: await getOne(item.name),
  }));
  const res: IGetAllDatasetsResponse = { data, status: "OK" };
  return res;
};

export const datasetsService = {
  getAll,
  getOne,
};
