import React, { useEffect, useState } from "react";
import { Button, Input } from "antd";
import { MandatoryFieldsText, TextEditor } from "../../../components";
import { useProject } from "../../../hooks";

export const PATIENT_DATASET_OPTIONS: IDropdownOption[] = [
  { id: -1, value: "Select Dataset for Project" },
  { id: 1, value: "Site 1 Dataset" },
];

interface ErrorState {
  name: string;
  description: string;
  patientDataset: string;
}

interface Props {
  isEdit: boolean;
  editObj: IProject;
  onClose?: () => void;
}

export const AddEditProjectForm = (props: Props) => {
  const { editObj, isEdit, onClose } = props;
  const { createProject, editProject, isProjectCreateLoading, isProjectEditLoading } = useProject();
  const [projectName, setProjectName] = useState("");
  const [projectDescription, setProjectDesscription] = useState("");
  const [error, setError] = useState<ErrorState>({ name: "", patientDataset: "", description: "" });

  const onProjectNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError({ ...error, name: "" });
    setProjectName(e.target.value);
  };

  const onProjectDescriptionChange = (str: string) => {
    setError({ ...error, description: "" });
    setProjectDesscription(str);
  };

  useEffect(() => {
    if (isEdit) {
      const { name, description } = editObj;
      setProjectName(name);
      setProjectDesscription(description);
    }
  }, [editObj, isEdit]);

  const handleEditSubmit = async () => {
    await editProject({ name: projectName, description: projectDescription }, editObj.id);
  };

  const handleAddSubmit = async () => {
    const obj: IProjectCreate = {
      projectName,
      projectDesc: projectDescription,
      s3FolderName: "",
    };
    await createProject(obj);
  };

  const validateForm = () => {
    const err: ErrorState = { name: "", description: "", patientDataset: "" };
    let isValidForm = false;
    if (!projectName) err.name = "This field is required";
    if (!projectDescription) err.description = "This field is required";
    // if (selectedPatientDataset.id < 0) err.patientDataset = "This field is required";
    if (!err.description && !err.name && !err.patientDataset) isValidForm = true;
    setError(err);
    return isValidForm;
  };

  const handleSubmit = async () => {
    setError({ description: "", name: "", patientDataset: "" });
    const isValidForm = validateForm();
    if (isValidForm) {
      if (isEdit) await handleEditSubmit();
      else await handleAddSubmit();
      onClose && onClose();
    }
  };

  return (
    <div className="autonomizeModal">
      <div className="first-project-form">
        <div>
          <h3>Project Name</h3>
          <Input value={projectName} onChange={onProjectNameChange} />
          {error.name && <p className="error">{error.name}</p>}
        </div>
      </div>
      <div className="text-editor m-t">
        <h3>Project Description</h3>
        <TextEditor
          displayToolbar
          text={projectDescription}
          setText={onProjectDescriptionChange}
          placeholder="Please enter and style project description here"
        />
        {error.description && <p className="error">{error.description}</p>}
      </div>
      <div className="tr m-t mandatory-text flex jcsb aic">
        <MandatoryFieldsText />
        <Button
          className="outline"
          loading={isProjectCreateLoading || isProjectEditLoading}
          onClick={handleSubmit}
        >
          {isEdit ? "Save" : "Add Project"}
        </Button>
      </div>
    </div>
  );
};
