import React, { useEffect, useState } from "react";
import { TaskInput } from "../../components";
import { isEmptyObject } from "../../utils";
import { GenerativeQaOutput, OpenAIInput, OpenAIOutput } from "./generative-qa-container";

interface Props {
  modelData: IModelDetail;
  selectedModel: string;
}

const OPENAI_MODEL_NAME = "Generative QA using GPT models";

export const TABS = {
  UPLOAD_FILE: "Upload File",
  ENTER_TEXT: "Enter Context",
};

export const GenerativeQaContainer = ({ modelData, selectedModel }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [contextId, setContextId] = useState("");
  const [selectedTab, setSelectedTab] = useState(TABS.UPLOAD_FILE);

  useEffect(() => {
    setQuery("");
    setContextId("");
  }, [selectedModel]);

  return (
    <>
      {!isEmptyObject(modelData) && (
        <>
          {selectedModel === OPENAI_MODEL_NAME ? (
            <div>
              <OpenAIInput
                {...{
                  isLoading,
                  setIsLoading,
                  setContextId,
                  query,
                  setQuery,
                  selectedTab,
                  setSelectedTab,
                }}
              />
              <OpenAIOutput {...{ isLoading, contextId, selectedTab }} />
            </div>
          ) : (
            <div>
              <TaskInput
                {...{
                  isLoading,
                  setIsLoading,
                  setQuery,
                  query,
                }}
              />
              <GenerativeQaOutput
                {...{ isLoading, setIsLoading, query, modelData, selectedModel }}
              />
            </div>
          )}
        </>
      )}
    </>
  );

  return !isEmptyObject(modelData) ? (
    <div>
      <TaskInput
        {...{
          isLoading,
          setIsLoading,
          setQuery,
          query,
        }}
      />
      <GenerativeQaOutput {...{ isLoading, setIsLoading, query, modelData, selectedModel }} />
    </div>
  ) : (
    <></>
  );
};
