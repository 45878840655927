export interface ITask {
  title: string;
  description: string;
  link: string;
}

export const DETECT_RELATIONSHIP_MODEL_OPTIONS: IDropdownOption[] = [
  {
    id: 1,
    value: "BioCreative V Chemical Disease Relation (BC5CDR) Model",
  },
  {
    id: 2,
    value: "Drug-Drug Interaction (DDI) Model",
  },
  {
    id: 3,
    value: "Drug-Target Interaction (DTI) Model",
  },
];

export const MODEL_MAPPER = [
  {
    modelName: "BioGPT-RE-BC5CDR",
    modelTitle: "BioCreative V Chemical Disease Relation (BC5CDR) Model",
  },
  {
    modelName: "BioGPT-RE-DDI",
    modelTitle: "Drug-Drug Interaction (DDI) Model",
  },
  {
    modelName: "BioGPT-RE-DTI",
    modelTitle: "Drug-Target Interaction (DTI) Model",
  },
];

export interface TabStructure {
  title: string;
  description: string;
  link: string;
}

export const SINGLE_TAB = {
  ENTER_TEXT: "Enter Text",
};

export const TASK_TABS = {
  LABELING: "Label it",
  RUNNING: "Run it",
  TRAINING: "Train it",
};
