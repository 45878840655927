import React from "react";
import { IconsProps } from "./Icons";

export const ChartReviewIcon = ({ className, fill, width, height, viewBox, onClick }: IconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox={viewBox ? viewBox : "0 0 51 52"}
      width={width ? width : "51"}
      height={height ? height : "52"}
      fill={fill ? fill : "currentColor"}
      onClick={onClick}
    >
      <path d="M50.7 39L37.7 52L28.6 42.9L32.5 39L37.7 44.2L46.8 35.1L50.7 39ZM5.2 0C2.34 0 0 2.34 0 5.2V46.8C0 49.66 2.34 52 5.2 52H25.48C24.18 49.66 23.4 47.06 23.4 44.2V46.8H18.2V26H23.4V44.2C23.4 35.62 30.42 28.6 39 28.6C39.78 28.6 40.82 28.6 41.6 28.86V15.6L26 0H5.2ZM23.4 3.9L37.7 18.2H23.4V3.9ZM7.8 31.2H13V46.8H7.8V31.2Z" fill="url(#paint0_linear_7241_5202)"/>
      <linearGradient id="paint0_linear_7241_5202" x1="25.35" y1="0" x2="25.35" y2="52" gradientUnits="userSpaceOnUse">
      <stop stopColor="#3232A6"/>
      <stop offset="1" stopColor="#8028BF"/>
      </linearGradient>
    </svg>
  );
};
