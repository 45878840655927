import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { Loading } from "../../../components";
import { AskAQuestion } from "./AskAQuestion";
import { TABS } from "../GenerativeQaContainer";

interface Props {
  contextId: string;
  isLoading: boolean;
  selectedTab: string;
}

export const OpenAIOutput = ({ contextId, isLoading, selectedTab }: Props) => {
  const [quesAns, setQuesAns] = useState([] as IQuesAns[]);

  useEffect(() => {
    setQuesAns([]);
  }, [contextId]);

  return (
    <>
      <h1 className="m-b">Results</h1>
      {isLoading ? (
        <Loading />
      ) : contextId !== "" ? (<div className={selectedTab === TABS.UPLOAD_FILE ? "ask-question-upload" : ""} >
        <AskAQuestion contextId={contextId} quesAns={quesAns} setQuesAns={setQuesAns} /></div>
      ) : (
        <div>Please enter context or upload a file to start question & answer.</div>
      )}
      <div className="tr m-t flex jce gp">
        <Button className="fill">Create API Endpoint</Button>
        <Button className="fill">Generate API Key</Button>
      </div>
    </>
  );
};
