export const USERS_DROPDOWN_OPTIONS: IDropdownOption[] = [
  {
    id: 1,
    value: "To be Approved",
  },
  {
    id: 2,
    value: "Approved",
  },
];
