import React, { useState } from "react";
import { isEmptyObject } from "../../utils";
import {
  HemophiliaQuestionAnsweringInput,
  HemophiliaQuestionAnsweringOutput,
} from "./hemophilia-ques-ans-container";
import { Loading } from "../../components";

interface Props {
  modelData: IModelDetail;
  selectedModel: string;
}

export const HemophiliaQuestionAnsweringContainer = ({ modelData, selectedModel }: Props) => {
  const [isLoading, setIsLoading] = useState(false);

  return !isEmptyObject(modelData) ? (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <HemophiliaQuestionAnsweringInput />
          <HemophiliaQuestionAnsweringOutput
            {...{ isLoading, modelData, selectedModel, setIsLoading }}
          />
        </>
      )}
    </div>
  ) : (
    <></>
  );
};
