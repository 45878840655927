import React from "react";
import { PAGE_URL, USER_ROLES_CODE } from "../../../utils";
import { ApplicationIcon, DataIcon, HomeIcon, TaskDetailsIcon } from "../../../assets/icons";

const ADMIN_LEFT_MENU_ITEMS: ILeftMenuItem[] = [
  {
    link: PAGE_URL.DASHBOARD,
    icon: <HomeIcon />,
    title: "Dashboard",
  },
];

const USER_LEFT_MENU_ITEMS: ILeftMenuItem[] = [
  {
    link: PAGE_URL.DASHBOARD,
    icon: <HomeIcon />,
    title: "Dashboard",
  },
  {
    link: PAGE_URL.DATASETS,
    icon: <DataIcon />,
    title: "Datasets",
  },
  {
    link: PAGE_URL.TASKS,
    icon: <TaskDetailsIcon />,
    title: "Agents",
  },
  {
    link: PAGE_URL.APPLICATIONS,
    icon: <ApplicationIcon />,
    title: "Copilots",
  },
];

export const getAppMenuItems = (roleCode: string) => {
  const menu = [] as ILeftMenuItem[];
  switch (roleCode) {
    case USER_ROLES_CODE.ADMIN:
      menu.push(...ADMIN_LEFT_MENU_ITEMS);
      break;
    case USER_ROLES_CODE.BUSINESS_USER:
      menu.push(...USER_LEFT_MENU_ITEMS);
      break;
    case USER_ROLES_CODE.DATA_SCIENTIST:
      menu.push(...USER_LEFT_MENU_ITEMS);
      break;
  }
  return menu;
};
