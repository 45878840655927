import { Button } from "antd";
import React from "react";
import { APPLICATIONS } from "../../utils";
import { ApplicationCard } from "./applications-container";
import "./applications-container.scss";

export const ApplicationsContainer = () => {
  return (
    <>
      <div className="flex jcsb aic">
        <div>
          {/* <h4 className="application-heading">We are building a healthcare optimized AI platform</h4>
      <p>
        The essential AI infrastructure and tools to solve some of the most challenging problems{" "}
      </p> */}
        </div>
        <Button className="fill">Create New Copilot</Button>
      </div>
      <div className="m-t application-card-grid">
        {APPLICATIONS.map((application, index) => (
          <ApplicationCard {...application} key={index} />
        ))}
      </div>
    </>
  );
};
