import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { modelDetailService } from "../api/model";
import { PageWrapper } from "../containers";
import { openNotificationWithIcon } from "../utils";
import { LabResultContainer } from "../containers/clinical-data";
import { LabreResultsAside } from "../containers/clinical-data";

const LabResult = () => {

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [modelData, setModelData] = useState({} as IModelDetail);
  const [selectedModel, setSelectedModel] = useState("");

  const [isContainerCollapsed, setIsContainerCollapsed] = useState(false);

  useEffect(() => {
    const fetchModelsInfo = async () => {
      setIsLoading(true);
      try {
        const { data } = await modelDetailService.getLabResultsModelsInfo();
        setModelData(data);
        setSelectedModel(data.models[0].name);
      } catch (e: any) {
        openNotificationWithIcon("", e.response.data.message, "error");
        navigate(-1);
      } finally {
        setIsLoading(false);
      }
    };
    fetchModelsInfo();
  }, [navigate]);

  return (
    <PageWrapper
      pageLeftAsideChildren={
        <LabreResultsAside {...{ selectedModel, setSelectedModel, modelData }} />
      }
      pageContainerChildren={<LabResultContainer isContainerCollapsed={isContainerCollapsed} setIsContainerCollapsed={setIsContainerCollapsed}/>}
      isContainerCollapsed={isContainerCollapsed}
    />
  );
};

export default LabResult;
