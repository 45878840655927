import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { MODAL_TYPE } from "../../utils";
import DeleteConfirmation from "./delete-confirmation";
import { EditProfile } from "./edit-profile";
import ForgotPassword from "./forgot-password";
import AddEditProject from "./add-edit-project";
import AddAndSignUpUser from "./add-and-sign-up-user";
import ApproveConfirmation from "./approve-confirmation";
import ViewCompleteSource from "./view-complete-source";

export const Modal = () => {
  const { modal } = useSelector((state: RootState) => state.ui);

  return (
    <>
      {modal[MODAL_TYPE.EDIT_PROFILE] && <EditProfile />}
      {modal[MODAL_TYPE.ADD_EDIT_PROJECT] && <AddEditProject />}
      {modal[MODAL_TYPE.DELETE_CONFIRMATION] && <DeleteConfirmation />}
      {modal[MODAL_TYPE.FORGOT_PASSWORD] && <ForgotPassword />}
      {modal[MODAL_TYPE.ADD_AND_SIGN_UP_USER] && <AddAndSignUpUser />}
      {modal[MODAL_TYPE.APPROVE_CONFIRMATION] && <ApproveConfirmation />}
      {modal[MODAL_TYPE.VIEW_FULL_SOURCE] && <ViewCompleteSource />}
    </>
  );
};
