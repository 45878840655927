import axios, { AxiosRequestConfig } from "axios";
import { store } from "../App";
import { logoutUser } from "../store";
import { PAGE_URL } from "../utils";

declare global {
  interface Window {
    _env_: {
      REACT_APP_GENESIS_API_BASE_URL: string;
      REACT_APP_OPENAI_MODEL_BASE_URL: string;
      [key: string]: any; // for any other properties you might have on _env_
    };
  }
}

export const authHeaders = (headers: any, token: string): any => {
  const namespace = "x-access-token";
  headers[namespace] = token;
  return headers;
};

export const withAuthHeaders = (headers: any): any => {
  const token = store.getState().authUser.user.accessToken;
  return authHeaders(headers, token || "");
};

const defaultOptions = {
  baseURL: process.env.REACT_APP_GENESIS_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
};

const openaiModelOptions = {
  baseURL: process.env.REACT_APP_OPENAI_MODEL_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
};
const apiClient = axios.create(defaultOptions);
const openaiModelApiClient = axios.create(openaiModelOptions);

apiClient.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    //   set user token here
    config.headers = withAuthHeaders({});
    return config;
  },
  (error: any) => {
    Promise.reject(error);
  }
);

apiClient.interceptors.response.use(
  (res) => res,
  (error: any) => {
    // handle user session refresh here
    const pathname = window.location.pathname;
    if (
      (pathname !== PAGE_URL.LOGIN && error.response.status === 401) ||
      error.response.status === 403
    ) {
      store.dispatch(logoutUser());
      window.location.replace(PAGE_URL.LOGIN);
    }
    if (error.response.status >= 500) {
      error.response.message = "Something went wrong!";
    }
    return Promise.reject(error);
  }
);

export { apiClient, openaiModelApiClient };
