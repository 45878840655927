import { PAGE_URL } from "./routes";

export const GENERATIVE_AI_TASKS: ITask[] = [
  {
    title: "Summarize",
    description: "Create summaries of body of text with flexible response length",
    link: "",
  },
  {
    title: "Generative QA",
    description: "Contextual Question answering with response generation on private data sources",
    link: `${PAGE_URL.GENERATIVE_AI_TASKS}${PAGE_URL.GENERATIVE_QA}`,
  },
  {
    title: "Conversational Chatbot on your data",
    description: "Build a conversational Chat agent on private data sources",
    link: "",
  },
  {
    title: "Brainstorm & Hypothesis generation",
    description: "Brainstorm highly specialized biomedical and clinical topics",
    link: "",
  },
  {
    title: "Public domain search",
    description: "Search biomedical knowledge in public domain - ClinicalTrials.Gov, PubMed",
    link: "",
  },
];

export const AUTOCOMPLETE_MODEL_OPTIONS: IDropdownOption[] = [
  {
    id: 1,
    value: "Model 1",
  },
];

export const AUTOCOMPLETE_MODEL_MAPPER = [
  {
    modelName: "MDLEX1",
    modelTitle: "Model 1",
  },
];
