export const projects = [
  {
    id: 1,
    name: "Test Project for Demo",
    description: "<p>Test Project for Demo</p>",
    s3FolderName: "",
    isActive: true,
    createdAt: "2022-12-15T00:00:00.000Z",
    createdBy: 1,
    updatedBy: 1,
    updatedAt: "19 October 2022",
    collabarators: [
      {
        image:
          "https://img.freepik.com/premium-vector/portrait-young-man-with-beard-hair-style-male-avatar-vector-illustration_266660-423.jpg?w=826",
        name: "Audrey Smith",
      },
      {
        image:
          "https://img.freepik.com/premium-vector/portrait-young-man-with-beard-hair-style-male-avatar-vector-illustration_266660-423.jpg?w=826",
        name: "Brad Dand",
      },
      {
        image:
          "https://img.freepik.com/premium-vector/portrait-young-man-with-beard-hair-style-male-avatar-vector-illustration_266660-423.jpg?w=826",
        name: "SShah2",
      },
      {
        image:
          "https://img.freepik.com/premium-vector/portrait-young-man-with-beard-hair-style-male-avatar-vector-illustration_266660-423.jpg?w=826",
        name: "Kris Nair",
      },
      {
        image:
          "https://img.freepik.com/premium-vector/portrait-young-man-with-beard-hair-style-male-avatar-vector-illustration_266660-423.jpg?w=826",
        name: "Andy Murray",
      },
    ],
  },
  {
    id: 2,
    name: "Patients With Metastatic NSCLC",
    description:
      "<p>A Randomized Pilot Study of Evolocumab Plus Nivolumab/Ipilimumab in Treatment-Naïve Patients With Metastatic NSCLC (TOP2101) ClinicalTrials.gov Identifier: NCT05144529</p>",
    s3FolderName: "",
    isActive: true,
    createdAt: "2022-12-15T00:00:00.000Z",
    createdBy: 1,
    updatedBy: 1,
    updatedAt: "19 October 2022",
    collabarators: [
      {
        image:
          "https://img.freepik.com/premium-vector/portrait-young-man-with-beard-hair-style-male-avatar-vector-illustration_266660-423.jpg?w=826",
        name: "Audrey Smith",
      },
      {
        image:
          "https://img.freepik.com/premium-vector/portrait-young-man-with-beard-hair-style-male-avatar-vector-illustration_266660-423.jpg?w=826",
        name: "Brad Dand",
      },
      {
        image:
          "https://img.freepik.com/premium-vector/portrait-young-man-with-beard-hair-style-male-avatar-vector-illustration_266660-423.jpg?w=826",
        name: "SShah2",
      },
    ],
  },
  {
    id: 3,
    name: "Patients with known or suspected primary or metastatic breast cancer ",
    description:
      '<p><span style="color: rgb(0, 0, 0);">Patients with known or suspected primary or metastatic breast cancer&nbsp;</span></p><p><span style="color: rgb(0, 0, 0);">NCTID : NCT03863457</span></p>',
    s3FolderName: "",
    isActive: true,
    createdAt: "2022-12-15T00:00:00.000Z",
    createdBy: 1,
    updatedBy: 1,
    updatedAt: "19 October 2022",
    collabarators: [
      {
        image:
          "https://img.freepik.com/premium-vector/portrait-young-man-with-beard-hair-style-male-avatar-vector-illustration_266660-423.jpg?w=826",
        name: "Audrey Smith",
      },
    ],
  },
];
