import { Input } from "antd";
import React from "react";

const { TextArea } = Input;

export interface ErrorState {
  textInput: string;
}

interface Props {
  onInputTextChange: (e: any) => void;
  error: ErrorState;
  inputText: string;
  rows?: number;
}

export const InputText = ({ onInputTextChange, error, inputText, rows }: Props) => {
  return (
    <>
      <TextArea
        rows={rows ? rows : 8}
        placeholder="Enter text..."
        onChange={(e) => onInputTextChange(e)}
        value={inputText}
      />
      {error.textInput && <p className="error">{error.textInput}</p>}
    </>
  );
};
