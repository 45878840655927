import { Card } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ITask } from "../../../utils";
import "../task-container.scss";

export const TaskCard = ({ title, description, link }: ITask) => {
  const navigate = useNavigate();
  return (
    <Card
      className="cursor-pointer p-20"
      onClick={() => {
        navigate(link);
      }}
    >
      <p className="task-card-title m-b">{title}</p>
      <p>{description}</p>
    </Card>
  );
};
