import React from "react";
import { Routes, useLocation, Route } from "react-router-dom";
import { GuestLayout } from "./GuestLayout";
import { PrivateLayout } from "./PrivateLayout";
import { PAGE_URL } from "../utils";
import * as Pages from "../pages";

export const AppRoutes = () => {
  const location = useLocation();

  return (
    <Routes location={location}>
      <Route element={<PrivateLayout />}>
      <Route path={`${PAGE_URL.CLINICAL_RESEARCH_TASKS}${PAGE_URL.CLINICAL_PROTOCOL_SUMMARY}`} element={<Pages.ClinicalProtocolSummary />} />
      <Route path={`${PAGE_URL.CLINICAL_RESEARCH_TASKS}${PAGE_URL.CLINICAL_PROTOCOL_ICEC_SUMMARY}`} element={<Pages.ClinicalProtocolICECSummary />} />
      <Route path={`${PAGE_URL.DATA_EXTRACTION_AND_NORMALIZATIONTASKS}${PAGE_URL.PHI_REDACTION}`} element={<Pages.PHIRedaction />} />
      <Route path={`${PAGE_URL.DATA_EXTRACTION_AND_NORMALIZATIONTASKS}${PAGE_URL.CLINICAL_ENTITY_EXTRACTION}`} element={<Pages.ClinicalEntityExtraction />} />
      <Route path={`${PAGE_URL.DATA_EXTRACTION_AND_NORMALIZATIONTASKS}${PAGE_URL.CLINICAL_ENTITY_NORMALIZATION}`} element={<Pages.ClinicalEntityNormalization />} />
      <Route path={`${PAGE_URL.CLINICAL_RESEARCH_TASKS}${PAGE_URL.CLINICAL_NOTE_CLASSIFICATION}`} element={<Pages.ClinicalNoteClassification />} />
      <Route path={`${PAGE_URL.DATA_EXTRACTION_AND_NORMALIZATIONTASKS}${PAGE_URL.LABORATORY_RESULT}`} element={<Pages.LabResult />} />
      <Route path={`${PAGE_URL.DATA_EXTRACTION_AND_NORMALIZATIONTASKS}${PAGE_URL.FORM_2728_PROCESSOR}`} element={<Pages.Form2728Processor />} />
      <Route path={PAGE_URL.APPLICATIONS} element={<Pages.Applications />} />
      <Route path={PAGE_URL.TASKS} element={<Pages.Tasks />} />
      <Route path={PAGE_URL.DASHBOARD} element={<Pages.Dashboard />} />
      <Route path={`${PAGE_URL.PROJECT_DETAIL}/:projectId`} element={<Pages.ProjectDetail />} />
      <Route path={PAGE_URL.SEARCH} element={<Pages.Search />} />
      <Route path={PAGE_URL.DATASETS} element={<Pages.Datasets />} />
      <Route path={PAGE_URL.EVIDENCE_VIEWER} element={<Pages.EvidenceViewer />} />
      <Route path={PAGE_URL.DOCUMENT_INSIGHTS} element={ <Pages.DocumentInsights />} />
      <Route
          path={`${PAGE_URL.SEARCH_AND_DATA_MINING_TASKS}${PAGE_URL.DOCUMENT_QUESTION_ANSWERING}`}
          element={<Pages.DocumentQuestionAnswering />}
        />
        <Route
          path={`${PAGE_URL.DATA_EXTRACTION_AND_NORMALIZATIONTASKS}${PAGE_URL.REALTION_EXTRACTION}`}
          element={<Pages.RealtionExtraction />}
        />
        <Route
          path={`${PAGE_URL.GENERATIVE_AI_TASKS}${PAGE_URL.GENERATIVE_QA}`}
          element={<Pages.GenerativeQa />}
        />
        <Route
          path={`${PAGE_URL.DISEASE_SPECIFIC_INSIGHTS}${PAGE_URL.HEMOPHILIA_QUESTION_ANSWERING}`}
          element={<Pages.HemophiliaQuestionAnswering />}
        />
      </Route>
      <Route element={<GuestLayout />}>
        <Route path={PAGE_URL.LOGIN} element={<Pages.Login />} />
        <Route path={PAGE_URL.RESET_PASSWORD} element={<Pages.ResetPassword.Form />} />
        <Route path={PAGE_URL.RESET_PASSWORD_SUCCESS} element={<Pages.ResetPassword.Success />} />
        <Route path={PAGE_URL.CREATE_PASSWORD} element={<Pages.CreatePassword.Form />} />
        <Route path={PAGE_URL.CREATE_PASSWORD_SUCCESS} element={<Pages.CreatePassword.Success />} />
      </Route>
    </Routes>
  );
};
