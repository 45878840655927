import React, { useMemo } from "react";
import { Dropdown } from "../Dropdown";

interface Props {
  modelData: IModelDetail;
  selectedModel: string;
  setSelectedModel: React.Dispatch<React.SetStateAction<string>>;
}

export const ModelDropdownAndDescription = ({
  modelData,
  selectedModel,
  setSelectedModel,
}: Props) => {
  const handleModelChange = (value: string) => {
    setSelectedModel(value);
  };

  const model = useMemo(() => {
    return modelData.models.find((item) => item.name === selectedModel);
  }, [modelData.models, selectedModel]);

  return (
    <div className="ant-card p-20">
      <h4 className="m-b">Select Model :</h4>
      <Dropdown
        defaultValue={selectedModel}
        options={modelData.models.map((item, index) => ({
          id: index,
          value: item.name,
        }))}
        onSelect={handleModelChange}
      />
      <div className="task-info">
        <h4>{modelData.task.name}</h4>
        <p className="task-info-title">
          Version: <span className="task-info-description">{modelData.task.version}</span>
        </p>
        <p className="task-info-title">
          Description: <span className="task-info-description">{modelData.task.description}</span>
        </p>
        <p className="task-info-title">
          Author: <span className="task-info-description">{model?.author}</span>
        </p>
        <p className="task-info-title">
          Last Updated: <span className="task-info-description">{model?.last_updated}</span>
        </p>
        <div className="task-info-title">
          Model Card Link:{" "}
          <span className="task-info-description">
            <ul>
              {model?.model_card_link.map((item, index) => (
                <li key={`model-card-link-${index}`}>
                  <a href={item} target="_blank" rel="noreferrer">
                    {item}
                  </a>
                </li>
              ))}
            </ul>
          </span>
        </div>
      </div>
    </div>
  );
};
