import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  RootState,
  openModal,
  setFeedbackType,
  setQuestionAnswerLogId,
  setQuestionsAnswers,
} from "../../../store";
import { ChatService, IGetAnswerRequest, ISources, uploadFileFolderService } from "../../../api";
import { MODAL_TYPE, openNotificationWithIcon } from "../../../utils";
import { IQuestAns } from "../../../interfaces/chat";
import { MenuIcon, ThumbsDown, ThumbsUp, UploadIcon } from "../../../assets/icons";
import { askAuto, autonomizeLogo } from "../../../assets/images";
import { Loading } from "../../../components";
import { Button, Input, Upload, Card } from "antd";
import { DeleteOutlined, DownloadOutlined, SendOutlined } from "@ant-design/icons";
import "./chat-ques-ans.scss";
import { exportToCsv } from "./ChatExportUtil";
import { UploadChangeParam, UploadFile } from "antd/lib/upload";
// import { questionPrompts } from "./suggestedQuestions";
import { usePrompts } from "../../../hooks";

const { Search } = Input;

interface Props {
  setChatSource: React.Dispatch<React.SetStateAction<ISources>>;
  isQuesAnsLoading: boolean;
  setIsQuesAnsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  fileterOnMetadata?: boolean;
  selectedFile: string;
  setHideChatHistory: React.Dispatch<React.SetStateAction<boolean>>;
  projectId: number;
  showHelp: boolean;
  setShowHelp: any;
  isPriorAuthProject?: boolean;
  documentUuid: string;
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ChatQuesAns = ({
  isQuesAnsLoading,
  setChatSource,
  setIsQuesAnsLoading,
  fileterOnMetadata,
  selectedFile,
  setHideChatHistory,
  projectId,
  setShowHelp,
  showHelp,
  isPriorAuthProject,
  documentUuid,
  setExpanded
}: Props) => {
  const [inputValue, setInputValue] = useState("");
  const dispatch = useDispatch();
  const { quesAns } = useSelector((state: RootState) => state.quesAns);
  const questionAnswerListRef = useRef<HTMLDivElement>(null);
  const inputElement = useRef<HTMLDivElement>(null);
  const [fileList, setFileList] = useState([]);
  const { fetchPrompts, loading, prompts } = usePrompts();

  useEffect(() => {
    if (isPriorAuthProject) {
      fetchPrompts(projectId);
    }
  }, [dispatch, fetchPrompts]);

  const handleSearchSubmit = async (e: string) => {
    if (e.trim().length > 0) {
      setIsQuesAnsLoading(true);
      try {
        const obj: IGetAnswerRequest = {
          q: e.trim(),
          metadataFilter: fileterOnMetadata ? 1 : 0,
          containerId: projectId,
          qaId: crypto.randomUUID(),
          documentUuid: documentUuid,
          docVariant: "prior-auth"
        };
        if (selectedFile !== "All") {
          obj.category = selectedFile;
        }
        const { data } = await ChatService.getAnswer(obj);
        dispatch(
          setQuestionsAnswers([
            ...quesAns,
            {
              question: inputValue == "" ? e.trim() : inputValue.trim(),
              answer: data.data.data.answer,
              logId: data.data.data.logId,
              sources: data.data.data.sources,
              qaId: data.data.data.qaId,
            },
          ])
        );
        setInputValue("");
      } catch (error: any) {
        // openNotificationWithIcon("", error.response.data.message, "error");
      } finally {
        setIsQuesAnsLoading(false);
      }
    }
  };

  const handleThumbsClick = (item: IQuestAns, type: "up" | "down") => {
    dispatch(
      setQuestionAnswerLogId({
        logId: item.logId,
        qaId: item.qaId,
      })
    );
    dispatch(setFeedbackType(type));
    dispatch(openModal(MODAL_TYPE.FEEDBACK));
  };

  useEffect(() => {
    if (questionAnswerListRef.current) {
      questionAnswerListRef.current.scrollTo({
        top: questionAnswerListRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [quesAns]);

  const handleSourceClick = (source: ISources) => {
    setChatSource(source);
    setHideChatHistory(true)
    setExpanded(true)
  };

  const handleDownloadClick = () => {
    exportToCsv(quesAns, ",");
  };

  async function handleFileChange(file: UploadChangeParam<UploadFile<any>>): Promise<void> {
    const filePath = `${file.file.name}`;
    const { data } = await uploadFileFolderService.getUploadSignedUrl(
      projectId,
      filePath
    );
    await uploadFileFolderService.uploadFiles(data.data.signedUrl, file);
    openNotificationWithIcon("", `${file.file.name} uploaded successfully!`, "success");
    await uploadFileFolderService.startFileProcessing(projectId, filePath);
  }

  function handleRemoveFile(file: UploadFile<any>): boolean | void | Promise<boolean | void> {
    setFileList([]);
  }

  return (
    <div className={`ask-question`}>
      {loading ? (
        <div style={{display: 'flex', margin: 'auto' }}>
          <Loading style={{ margin: 'auto' }} />
        </div>
      ) : (
        <div className="ask-question-left-conatiner">
          <div style={{display: 'flex', marginLeft: 'auto', marginRight: '1em'}}>
          <a
              title="Clear Chat History"
              onClick={() => dispatch(setQuestionsAnswers([]))}
            >
              <DeleteOutlined /> Clear
            </a>
          </div>
          <div
            ref={questionAnswerListRef}
            style={{ maxHeight: "calc(100% - 90px)", overflowY: "scroll" }}
          >
            {quesAns.map((item, index) => (
              <div className="ask-question-result" key={index}>
                <div className="ask-question-result-ques">
                  <MenuIcon className="question-icon" />
                  <h4 className="mb-0">{item.question}</h4>
                </div>
                <div className="ask-question-result-answer">
                  <img className="ask-question-result-answer-img" src={autonomizeLogo} />
                  <div>
                    <p className="m-b" style={{ whiteSpace: "pre-line" }}>{item.answer}</p>
                    {item?.sources?.length > 0 && (
                      <>
                        <h5>Sources :</h5>
                        <div className="source-list flex gp-10">
                          {item.sources.map((source: ISources, i: number) => (
                            <li
                              className="cursor-pointer"
                              key={i}
                              onClick={() => handleSourceClick(source)}
                            >
                              <a>{source.fileName}</a>
                            </li>
                          ))}
                        </div>
                      </>
                    )}
                  </div>
                  <div className="flex gp">
                    <div
                      onClick={() => handleThumbsClick(item, "up")}
                      className="cursor-pointer flex"
                    >
                      <ThumbsUp />
                    </div>
                    <div
                      onClick={() => handleThumbsClick(item, "down")}
                      className="cursor-pointer flex"
                    >
                      <ThumbsDown />
                    </div>
                  </div>
                </div>
              </div>
            ))}

          </div>
          <div className="ask-question-search-bar" style={{ display: 'grid' }}>
            {isPriorAuthProject &&
              <div className=".grid-cols-2 " style={{ display: 'grid', marginBottom: '2em', gridTemplateColumns: 'repeat(2, 1fr)', gap: '1em', marginLeft: 'auto', marginRight: 'auto' }}>
                {
                  prompts.map((item, index) => {
                    return (
                      <Card key={`prompt-${index}`} style={{ width: '25vw', height: '7vh', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}
                        onClick={() => {
                          setInputValue(item.value);
                          handleSearchSubmit(item.value)
                        }}>
                        <p style={{ marginLeft: 'auto' }}>{item.value}</p>
                      </Card>
                    )
                  })
                }

              </div>}
            <div className="flex" style={{ flexWrap: 'nowrap', marginBottom: '1em' }}>
              {
                isPriorAuthProject &&
                <Upload
                  onChange={handleFileChange}
                  fileList={fileList}
                  onRemove={handleRemoveFile}
                  accept=".txt,.pdf,.doc"
                  multiple={false}
                  style={{ marginTop: 'auto', marginBottom: 'auto' }}
                >
                  <Button className="outline" style={{ width: '7vw', border: 'none', margin: 'auto' }}>
                    <UploadIcon width={"100%"} height={"100%"} />
                  </Button>
                </Upload>
              }
              {/* <UploadIcon /> */}
              <Search
                className="record-search"
                placeholder="Ask your question"
                enterButton={
                  isQuesAnsLoading ? <Loading tip="" /> : <Button icon={<SendOutlined />} />
                }
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onSearch={(e) => handleSearchSubmit(e)}
                disabled={isQuesAnsLoading}
                style={{ width: '80%', marginLeft: '1em', marginRight: '1em' }}
              />
              <Button
                className="fill"
                title="Download Chats"
                icon={<DownloadOutlined />}
                size={"large"}
                onClick={() => {
                  handleDownloadClick();
                }}
              />
            </div>
            {/* <p>
              This is an interactive way to ask questions regarding documents in the dataset. Answers
              might not be specific to the question asked but would provide additional relevant
              information.
            </p>
            <p>
              <b>DO NOT</b> mention any sensitive information or PHI/PII information as part of the
              question to CareGPT
            </p> */}
            <p style={{ textAlign: "center", width: "80%" }}>
              Ask Auto <img src={askAuto} style={{ height: "20px", marginBottom: "7px" }}></img> can
              make mistakes. Consider checking important information
            </p>
          </div>
        </div>)
      }
    </div>
  );
};
