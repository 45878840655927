import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const runInStrictMode = process.env.REACT_APP_RUN_IN_STRICT_MODE === "true";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  runInStrictMode ? (
    <React.StrictMode>
      <App />
    </React.StrictMode>
  ) : (
    <App />
  )
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals())
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
