import React from "react";
import { IApplication } from "../utils";
import { PageWrapper, DocumentInsightsContainer } from "../containers";
import { useLocation } from "react-router-dom";

export interface MyData {
  key: string;
  application: IApplication;
}
const DocumentInsights = () => {
  const location = useLocation();
  const copilotInfo = location?.state?.data as MyData | undefined;

  return <PageWrapper pageContainerChildren={<DocumentInsightsContainer copilotInfo={copilotInfo} />} />;
};

export default DocumentInsights;
