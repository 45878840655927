import React, { SetStateAction, useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { Button, Upload } from "antd";
import type { UploadFile, UploadProps } from "antd/es/upload/interface";
import { openNotificationWithIcon } from "../../../utils";
import "./file-upload.scss";
import { openaiModelService } from "../../../api";

interface Props {
  handleFileChange: (info: any) => void;
  handleRemoveFile: () => void;
  fileList: {
    uid: string;
    name: string;
  }[];
}

export const OpenAIFileUpload = ({ handleFileChange, handleRemoveFile, fileList }: Props) => {
  return (
    <>
      <div className="browse-file m-b">
        <Upload
          onChange={handleFileChange}
          fileList={fileList}
          onRemove={handleRemoveFile}
          accept=".txt,.pdf,.doc"
          multiple={false}
        >
          <Button className="outline">Browse File</Button>
        </Upload>
      </div>
    </>
  );
};

interface PropsAntd {
  setContextId: React.Dispatch<SetStateAction<string>>;
}

export const OpenAiFileUploadAntd = ({ setContextId }: PropsAntd) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [uploading, setUploading] = useState(false);

  const handleUpload = async () => {
    setUploading(true);
    try {
      const { data } = await openaiModelService.fileUpload(fileList[0]);
      setContextId(data);
      openNotificationWithIcon("File uploaded successfully.", "", "success");
    } catch {
      openNotificationWithIcon("File upload failed.", "", "error");
    } finally {
      setUploading(false);
    }
  };

  const props: UploadProps = {
    multiple: false,
    onRemove: (file) => {
      setFileList([]);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      return false;
    },
    onChange: (info) => {
      setFileList([info.file]);
    },
    accept: ".pdf",
    fileList,
  };

  return (
    <div className="browse-file m-b flex aic no-wrap">
      <Upload {...props}>
        <Button icon={<UploadOutlined />}>Select File</Button>
      </Upload>
      <Button
        className="fill"
        onClick={handleUpload}
        disabled={fileList.length === 0}
        loading={uploading}
      >
        {uploading ? "Uploading" : "Upload File"}
      </Button>
    </div>
  );
};
