import { datasetsService } from "../api";
import { getLocalDateTimeStringFromISO } from "../utils";

const datasetData: Pick<IDataset, "name" | "description" | "status" | "date">[] = [
  {
    name: "01088989_patient1.pdf",
    description: "Medical Notes",
    status: "Processed",
    date: "2023-04-05T18:07:17.033Z",
  },
  {
    name: "0987654231_01.pdf",
    description: "Medical Notes",
    status: "Processed",
    date: "2023-04-03T14:00:17.033Z",
  },
  {
    name: "123456789_John_Doe_1.pdf",
    description: "Medical Notes",
    status: "Processed",
    date: "2023-03-31T07:19:01.033Z",
  },
  {
    name: "e81b1e84-5f56-4d2f-a2ef-8830dd7660c6_21page.pdf",
    description: "Medical Notes",
    status: "Processed",
    date: "2023-04-01T00:15:35.033Z",
  },
];

export const datasets: Omit<IDataset, "url">[] = Array.from(Array(datasetData.length).keys()).map(
  (index) => ({
    id: index,
    // date: getLocalDateTimeStringFromISO(new Date().toISOString()),
    date: getLocalDateTimeStringFromISO(datasetData[index % datasetData.length].date),
    description: datasetData[index % datasetData.length].description,
    name: datasetData[index % datasetData.length].name,
    status: datasetData[index % datasetData.length].status,
  })
);
