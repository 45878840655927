import React, { useCallback, useEffect, useMemo, useState } from "react";
import { isEmptyObject, openNotificationWithIcon } from "../../utils";
import { DocumentQuestionAnsweringDropdown } from "./document-question-answering-container";
import { tasksService } from "../../api";
import { AskAQuestion } from "../../components";
import { Button } from "antd";

export const AUTOCOMPLETE_TABS = {
  UPLOAD_FILE: "Upload File",
  ENTER_TEXT: "Enter Text",
};

interface Props {
  modelData: IModelDetail;
  selectedModel: string;
}

export const DocumentQuestionAnsweringContainer = ({ modelData, selectedModel }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSubjectID, setSelectedSubjectId] = useState("Select a Subject ID");
  const [subjectError, setSubjectError] = useState("");
  const [quesAns, setQuesAns] = useState([] as IQuesAns[]);
  const [inputValue, setInputValue] = useState("");
  const selectedModelCode = useMemo(
    () => modelData.models.find((model) => model.name === selectedModel)?.model_code,
    [modelData.models, selectedModel]
  );

  useEffect(() => {
    setQuesAns([]);
  }, [selectedSubjectID]);

  const handleSearchSubmit = useCallback(
    async (e: string) => {
      if (selectedSubjectID === "Select a Subject ID") {
        setSubjectError("This field is required");
        return;
      }
      if (e.trim().length > 0) {
        setIsLoading(true);
        try {
          const { data } = await tasksService.documentQuestionAnswering(
            selectedModelCode as string,
            {
              q: e.trim(),
              subject_id: selectedSubjectID,
            }
          );
          setQuesAns((prev) => [...prev, { answer: data.data.answer, query: data.data.query }]);
          setInputValue("");
        } catch (error: any) {
          openNotificationWithIcon("", error.response.data.message, "error");
        } finally {
          setIsLoading(false);
        }
      }
    },
    [selectedSubjectID, selectedModelCode]
  );

  return !isEmptyObject(modelData) ? (
    <>
      <DocumentQuestionAnsweringDropdown
        {...{ subjectError, selectedSubjectID, setSelectedSubjectId, setSubjectError }}
      />
      <AskAQuestion {...{ handleSearchSubmit, quesAns, inputValue, setInputValue, isLoading }} classname="ask-question-subject-dropdown" />
      <div className="tr m-t flex jce gp">
        <Button className="fill">Create API Endpoint</Button>
        <Button className="fill">Generate API Key</Button>
      </div>
      {/* <DocumentQuestionAnsweringInput
        {...{
          isLoading,
          setIsLoading,
          setQuery,
          query,
          selectedSubjectID,
          setSubjectError,
        }}
      />
      <DocumentQuestionAnsweringOutput
        {...{ isLoading, setIsLoading, query, modelData, selectedModel, selectedSubjectID }}
      /> */}
    </>
  ) : (
    <></>
  );
};
