import React from "react";
import { AddEditProjectForm } from "../modals/add-edit-project/AddEditProjectForm";
import "./project.scss";

export const CreateFirstProjectContainer = () => {
  return (
    <div className="project-dashboard">
      <h1>Create your first project here</h1>
      <div className="first-project m-t">
        <AddEditProjectForm editObj={{} as IProject} isEdit={false} />
      </div>
    </div>
  );
};
