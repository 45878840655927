import { apiClient } from "./apiClient";

interface IExtractRelationResponse {
  data: {
    input_text: string;
    output: string;
  };
  status: string;
}

interface IDocumentQuestionAnsweringResponse {
  data: {
    answer: string;
    query: string;
    subject_id: string;
  };
  status: string;
}
interface IDocumentQaPaymentIntegrityResponse {
  data: {
    answer: string;
    query: string;
  };
  status: string;
}

interface IGetExistingSubjectsResponse {
  data: {
    existing_subjects: string[];
  };
  status: string;
}

export type IGenerativeQaResponse = {
  query: string;
  [key: string]: string;
} & { [key: string]: string[] };

interface IGenerativeQaResponseWrapper {
  data: IGenerativeQaResponse;
  status: string;
}

interface IDocumentQuestionAnsweringFileUploadResponse {
  data: { status: string };
  status: string;
}

interface IHemophiliaQAResponse {
  data: {
    query: string;
    answer: string;
  };
  status: string;
}

const getRelationship = async (obj: IIdentifyRelationshipRequest) => {
  const res = await apiClient.post<IIdentifyRelationshipResponse>(`/models/re`, obj);
  return res;
};

// const clinicalNoteClassification = async (clinicalText: string) => {
//   'https://zrdc3eemqchifddwroojrkrebq0apcqg.lambda-url.us-east-1.on.aws'
//   const res = await apiClient.post<IExtractRelationResponse>(`/models/re?model=${clinicalText}`, obj);
//   return res;
// };

const extractRelationship = async (modelCode: string, obj: IExtractRelationRequest) => {
  const res = await apiClient.post<IExtractRelationResponse>(`/models/re?model=${modelCode}`, obj);
  return res;
};

const getDocumentQaPaymentIntegrity = async (
  modelCode: string,
  obj: IDocumentQaPaymentIntegrityRequest
) => {
  const res = await apiClient.post<IDocumentQaPaymentIntegrityResponse>(
    `/models/document-qa-payment-integrity?model=${modelCode}`,
    obj
  );
  return res;
};

const documentQuestionAnswering = async (
  modelCode: string,
  obj: IDocumentQuestionAnsweringRequest
) => {
  const res = await apiClient.post<IDocumentQuestionAnsweringResponse>(
    `/models/documents-qa?model=${modelCode}`,
    obj
  );
  return res;
};

const getDocumentQuestionAnsweringSubjects = async () => {
  const res = await apiClient.get<IGetExistingSubjectsResponse>(`/models/documents-qa-subjects`);
  return res;
};

const documentQuestionAnsweringFileUpload = async (
  obj: IDocumentQuestionAnsweringFileUploadRequest
) => {
  const res = await apiClient.post<IDocumentQuestionAnsweringFileUploadResponse>(
    `models/document-qa/file`,
    obj
  );
  return res;
};

const generativeQa = async (modelCode: string, obj: IGenerativeQARequest) => {
  const res = await apiClient.post<IGenerativeQaResponseWrapper>(
    `/models/generative-qa?model=${modelCode}`,
    obj
  );
  return res;
};

const hemophiliaQA = async (modelCode: string, obj: IHemophiliaQARequest) => {
  const res = await apiClient.post<IHemophiliaQAResponse>(
    `/models/hemophilia-qa?model=${modelCode}`,
    obj
  );
  return res;
};

export const tasksService = {
  getRelationship,
  extractRelationship,
  documentQuestionAnswering,
  getDocumentQuestionAnsweringSubjects,
  generativeQa,
  documentQuestionAnsweringFileUpload,
  hemophiliaQA,
  getDocumentQaPaymentIntegrity,
};
