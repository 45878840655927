import { openaiModelApiClient } from "./apiClient";

const fileUpload = async (file: any) => {
  const formData = new FormData();
  formData.append("file", file);
  const res = await openaiModelApiClient.post("/send_contextpdf", formData, {
    headers: { "Content-Type": `multipart/form-data;` },
  });
  return res;
};

const contextUpload = async (context: string) => {
  const res = await openaiModelApiClient.post(
    "/send_context",
    { context },
    {
      headers: { "Content-Type": `text/plain` },
    }
  );
  return res;
};

const query = async (contextId: string, question: string) => {
  const res = await openaiModelApiClient.post<IQuesAns>(
    "/qa_fromcontext",
    { context_id: contextId, q: question },
    {
      headers: { "Content-Type": `text/plain` },
    }
  );
  return res;
};

export const openaiModelService = {
  fileUpload,
  contextUpload,
  query,
};
