import Table, { ColumnsType } from "antd/lib/table";
import React from "react";

interface DataType extends IDataset {
  key: React.Key;
}

const getColumns = (): ColumnsType<DataType> => [
  {
    title: "Dataset",
    dataIndex: "name",
  },
  {
    title: "About",
    dataIndex: "description",
  },
  {
    title: "Date",
    dataIndex: "date",
  },
  {
    title: "Status",
    dataIndex: "status",
  },
  {
    title: "Action",
    dataIndex: "action",
    render: (value: string, record: DataType) => (
      <a href={record.url} target="_blank" rel="noreferrer">
        {"View file"}
      </a>
    ),
  },
];

interface Props {
  datasets: IDataset[];
}

export const DatasetsTable = (props: Props) => {
  const { datasets } = props;
  const dataSource: DataType[] = datasets.map((item) => ({ key: `dataset-${item.id}`, ...item }));

  return (
    <Table
      className="ant-card m-t table-max-height"
      columns={getColumns()}
      dataSource={dataSource}
      pagination={false}
    />
  );
};
