import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { projectService } from "../api";
import { PATIENT_DATASET_OPTIONS } from "../containers/modals/add-edit-project/AddEditProjectForm";
import { addProject, RootState, setProjects, updateProject } from "../store";
import { useAuthUser } from "./useAuthUser";

export const useProject = () => {
  const dispatch = useDispatch();
  const { length: projectsLength } = useSelector((state: RootState) => state.project.list);
  const [isProjectsLoading, setIsProjectsLoading] = useState(false);
  const [isProjectCreateLoading, setIsProjectCreateLoading] = useState(false);
  const [isProjectEditLoading, setIsProjectEditLoading] = useState(false);
  const { roleCode } = useAuthUser();

  const getProjects = useCallback(async () => {
    setIsProjectsLoading(true);
    const { data } = await projectService.getAll();
    dispatch(
      setProjects(
        data.data.projects.map((item) => ({
          ...item,
          patientDataset: PATIENT_DATASET_OPTIONS[1],
        }))
      )
    );
    setIsProjectsLoading(false);
  }, [dispatch]);

  const createProject = useCallback(
    async (obj: IProjectCreate) => {
      setIsProjectCreateLoading(true);
      // const { data } = await projectService.create(obj);
      // dispatch(addProject({ ...data.data, patientDataset: PATIENT_DATASET_OPTIONS[1] }));
      dispatch(addProject({
        id:projectsLength + 1,
        name: obj.projectName,
        description:obj.projectDesc,
        s3FolderName:obj.s3FolderName,
        isActive:true,
        createdAt:new Date().toString() ,
        createdBy:1,
        updatedBy:1,
        updatedAt: "null",
        collabarators: [],
      }));
      setIsProjectCreateLoading(false);
    },
    [dispatch, projectsLength]
  );

  const editProject = useCallback(
    async (obj: IProjectUpdate, id: number) => {
      setIsProjectEditLoading(true);
      // await projectService.update(obj, id);
      dispatch(updateProject({ obj, id }));
      setIsProjectEditLoading(false);
    },
    [dispatch]
  );

  return {
    getProjects,
    createProject,
    editProject,
    isProjectCreateLoading,
    isProjectEditLoading,
    isProjectsLoading,
  };
};
