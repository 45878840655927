import React, { useState } from "react";
import { TaskInput } from "../../components";
import { isEmptyObject } from "../../utils";
import { RelationExtractionOutput } from "./relation-extraction-container";

export const RELATION_EXTRACTION_TABS = {
  UPLOAD_FILE: "Upload File",
  ENTER_TEXT: "Enter Text",
};

interface Props {
  modelData: IModelDetail;
  selectedModel: string;
}

export const RelationExtractionContainer = ({ modelData, selectedModel }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [query, setQuery] = useState("");

  return !isEmptyObject(modelData) ? (
    <div>
      <TaskInput
        {...{
          isLoading,
          setIsLoading,
          setQuery,
          query,
        }}
      />
      <RelationExtractionOutput {...{ isLoading, setIsLoading, query, modelData, selectedModel }} />
    </div>
  ) : (
    <></>
  );
};
