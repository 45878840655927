export const toTitleCase = (str: string) =>
  str
    .split(" ")
    .map((s) => s[0].toUpperCase() + s.substring(1).toLocaleLowerCase())
    .join(" ");

export function snakeToPascal(str: any, delimiter = "") {
  str += "";
  str = str.split("_");

  function upper(output: any) {
    return output.slice(0, 1).toUpperCase() + output.slice(1, output.length);
  }

  for (let i = 0; i < str.length; i++) {
    const str2 = str[i].split("/");
    for (let j = 0; j < str2.length; j++) {
      str2[j] = upper(str2[j]);
    }
    str[i] = str2.join(delimiter);
  }
  return str.join(delimiter);
}
