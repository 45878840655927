import { apiClient } from "./apiClient";

export interface IGetAnswerRequest {
  documentUuid?: string;
  qaId: string;
  q: string;
  metadataFilter: 0 | 1;
  category?: string;
  containerId: number;
  docVariant: string;
}

export interface IOffset {
  begin: number;
  end: number;
}

export interface IPages {
  pageNo: number;
  offsets: IOffset[];
}

export interface ISources {
  fileName: string;
  pages: IPages[];
  documentUUID: string;
}

interface IGetAnswerResponse {
  message: string;
  data: {
    data: {
      answer: string;
      query: string;
      logId: number;
      qaId: string;
      sources: ISources[];
    };
  };
}

interface IGetQAHistoryRequest {
  limit: number;
  offset: number;
}

export interface IQA {
  Id: number;
  PatientId: number;
  Question: string;
  Answer: string;
  CreatedBy: number;
  createdAt: string;
  updatedAt: string;
}

interface IHistory {
  id: string;
  query: IGetAnswerRequest;
}

export interface ISources {
  fileName: string;
  pages: IPages[];
  documentUUID: string;
}

interface IGetQAHistoryResponse {
  message: string;
  data: IHistory[];
  pagination: IPagination;
}

interface IGetSingleQARequest {
  logId: number;
}

interface IGetSingleQAResponse {
  message: string;
  data: {
    id: string;
    query: IGetAnswerRequest;
    qaId: string;
    answer: { query: string; answer: string; sources: ISources[] };
  };
}

interface IClearHistoryResponse {
  message: string;
}

const getAnswer = async (obj: IGetAnswerRequest) => {
  const reqObj: IGetAnswerRequest = {
    ...obj,
    category:
      obj.category?.charAt(obj.category.length - 1) === "/"
        ? obj.category.slice(0, -1)
        : obj.category,
  };
  const res = await apiClient.post<IGetAnswerResponse>(`/qa/query`, reqObj);
  return res;
};

const getQAHistory = async ({ limit, offset }: IGetQAHistoryRequest) => {
  const res = await apiClient.get<IGetQAHistoryResponse>(
    `/chat/query/logs?limit=${limit}&offset=${offset}`
  );
  return res;
};

const getSingleQa = async ({ logId }: IGetSingleQARequest) => {
  const res = await apiClient.get<IGetSingleQAResponse>(`chat/query/${logId}`);
  return res;
};

const clearHistory = async () => {
  const res = await apiClient.delete<IClearHistoryResponse>(`chat/query/logs`);
  return res;
};

export const ChatService = {
  getAnswer,
  getQAHistory,
  getSingleQa,
  clearHistory,
};
