import React from "react";
import { useAuthUser } from "../../hooks";
import { USER_ROLES_CODE } from "../../utils";
import AdminDashboard from "./AdminDashboard";
import Projects from "./Projects";

const Dashboard = () => {
  const { roleCode } = useAuthUser();

  return <>{roleCode === USER_ROLES_CODE.ADMIN ? <AdminDashboard /> : <Projects />}</>;
};

export default Dashboard;
